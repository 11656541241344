import React from "react";
import { Row, Col, Form, Button } from "antd";
import { withFormik } from "formik";
import FormikPersist from "src/components/FormikPersist";
import { Field, RenderField, RenderSelect } from "src/components";
import { Select } from "antd";
import { useUserData } from "src/helpers/supertokens";

import { useRouter } from "src/helpers";
const Option = Select.Option;

const TopicForm = (props) => {
    const { onFinishFailed, values, handleSubmit, isSubmitting } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={24} md={24}>
                    <Field
                        label="Topic Title"
                        placeholder="Topic Title"
                        name={"title"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.title}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Topic Description"
                        placeholder="Topic Description"
                        name={"description"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.description}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Topic Status"
                        placeholder="Topic Status"
                        name={"status"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.status}
                        rules={[{ required: true }]}
                    >
                        <Option value="inactive">Inactive</Option>
                        <Option value="active">Active</Option>
                    </Field>
                </Col>

                <Col lg={12} md={24}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

const TopicFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { topic, chapter_id } = props;

        return {
            ...(topic?.id ? { id: topic.id } : {}),
            title: topic?.title || "",
            description: topic?.description || "",

            status: topic?.status || "inactive",
            type: "topic",
            parent_id: chapter_id || topic?.parent_id,
            other: topic?.other || { order: -1 },
            // created_by: props.user_id,
        };
    },

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish(values);
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

const FormikForm = TopicFormWithFormik(TopicForm);
const FormikFormWithUser = (props) => {
    const { query } = useRouter();
    const { chapter_id } = query;
    const { user } = useUserData();

    const user_id = user?.user_id;
    return <FormikForm {...props} user_id={user_id} chapter_id={chapter_id} />;
};

export default FormikFormWithUser;
