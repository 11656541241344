import { ApolloProvider } from "@apollo/client";
import { StyledThemeProvider } from "src/definitions/styled-components";
import { initializeApollo } from "src/services/graphql";
import "antd/dist/antd.css";
import "src/styles/katex/katex.min.css";
import "src/styles/global.css";

import "react-phone-input-2/lib/style.css";
import React, { useEffect } from "react";
import { initCASL } from "src/services/casl/initCASL";
import LoadingPage from "src/components/LoadingPage";
import {
    BrowserRouter,
    Routes,
    Route,
    Outlet,
    Navigate,
    Link,
} from "react-router-dom";
import { ALL_ROUTES } from "./modules";

import Test from "./components/HighlightWrapper/Test";
import { setUser } from "@sentry/react";

import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import * as reactRouterDom from "react-router-dom";
import { useUserData } from "./helpers/supertokens";

const PrivateRoute = () => {
    const { doesSessionExist, isLoading } = useUserData();

    if (doesSessionExist) {
        return <Outlet />;
    } else {
        if (!isLoading)
            localStorage.setItem(
                "previousUrl",
                window?.location?.pathname + (window?.location?.search || ""),
            );
        return <Navigate to="/auth" />;
    }
};

export const ComponentWithCASL = ({ children }) => {
    const { user, isLoading } = useUserData();
    useEffect(() => {
        if (!isLoading && user) {
            initCASL(user);
            setUser(user);



        }
    }, [isLoading, user]);

    if (isLoading) return <LoadingPage />;

    return <>{children}</>;
};

function MyApp({ Component, pageProps }): JSX.Element {
    const apolloClient = initializeApollo();
    return (

        <StyledThemeProvider>
            <ApolloProvider client={apolloClient}>
                <ComponentWithCASL>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/test" element={<Test />} />
                            {getSuperTokensRoutesForReactRouterDom(
                                reactRouterDom,
                                [EmailPasswordPreBuiltUI],
                            )}
                            <Route element={<PrivateRoute />}>
                                {ALL_ROUTES}
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </ComponentWithCASL>
            </ApolloProvider>
        </StyledThemeProvider>
    );
}

export default MyApp;
