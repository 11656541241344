import { AppstoreAddOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import { useUserData } from "src/helpers/supertokens";

import { USER_QUERY } from "src/modules/user/graphql";
import { Can } from "src/services/casl";
import { Button, Col, Row, Spin, Table } from "antd";
import { Link } from "src/components";
import { useRouter } from "src/helpers";
import React, { useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { getListColumns } from "./columns";
import { onSortEnd, getSortedWorksheetsData } from "./helpers";

const ListView: React.FC = (props: any) => {
    const {
        loading,
        worksheets,
        group_id,
        group_title,
        redirect_to,
        updateGroupTabMap,
        disableAddButton,
        book,
        refetchWorksheets,
        updateUserOther,
    } = props;
    const [dataSource, setDataSource] = useState([]);
    const { query, isReady } = useRouter();
    const { tabType } = query;
    const { user, isLoading } = useUserData();
    const namespace = "https://hasura.io/jwt/claims/";

    const [
        getUser,
        {
            loading: loadingUser,
            error: errorUser,
            data: { user: userData } = { user: {} },
        },
    ] = useLazyQuery(USER_QUERY);

    useEffect(() => {
        if (!isLoading && user) {
            const user_id = user?.user_id;
            getUser({ variables: { id: user_id } });
        }
    }, [isLoading]);

    // const [totalSetCount, setTotalSetCount] = useState(0)
    useEffect(() => {
        if (worksheets.length) {
            // setTotalSetCount(topics.reduce((acc, cur) => acc+cur.collections.length, 0)) ;
            const data = getSortedWorksheetsData(worksheets);
            setDataSource(data);
        } else setDataSource([]);
    }, [worksheets]);

    if (!isReady) return <div>Loading...</div>;

    const SortableItem = SortableElement((props) => <tr {...props} />);
    const SortableBody = SortableContainer((props) => <tbody {...props} />);

    const DraggableContainer: any = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helper="row-dragging"
            onSortEnd={({ oldIndex, newIndex }) =>
                onSortEnd({
                    oldIndex,
                    newIndex,
                    dataSource,
                    updateGroupTabMap,
                    setDataSource,
                })
            }
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }): any => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex(
            (x) => x.index === restProps["data-row-key"],
        );
        return <SortableItem index={index} {...restProps} />;
    };

    const columns: any = getListColumns({
        userData,
        updateUserOther,
        group_id,
        dataSource,
        refetchWorksheets,
        redirect_to,
        tabType,
    });

    return (
        <>
            <Row justify="space-between" align="middle">
                <Col>
                    <h1>
                        Worksheets for {group_title} ({dataSource.length})
                    </h1>
                </Col>
                <Col>
                    <Can I="create" a={"worksheet"}>
                        {(allowed) => (
                            <Link
                                href={{
                                    pathname: `/worksheet/create_enhanced/${group_id}`,
                                    query: {
                                        redirect_to: encodeURI(redirect_to),
                                        order: worksheets.length + 1,
                                        class: book?.other.class,
                                        subject: book?.other.subject,
                                    },
                                }}
                            >
                                <Button
                                    type="primary"
                                    icon={<AppstoreAddOutlined />}
                                    disabled={!allowed}
                                >
                                    Add Worksheet
                                </Button>
                            </Link>
                        )}
                    </Can>
                </Col>
            </Row>
            <hr />

            {/* <FilterComponent isWorksheet={true} {...props} /> */}
            {/* <hr /> */}

            <div style={{ overflow: "scroll", background: "#FFF" }}>
                <Spin spinning={loading}>
                    {Boolean(worksheets?.length) && (
                        <Table
                            pagination={false}
                            columns={columns}
                            dataSource={dataSource || []}
                            rowKey="index"
                            components={{
                                body: {
                                    wrapper: DraggableContainer,
                                    row: DraggableBodyRow,
                                },
                            }}
                        />
                    )}
                </Spin>
            </div>
        </>
    );
};

export default ListView;
