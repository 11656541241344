import React, { useEffect, useState } from "react";
import DroppedItem from "./DroppedItem";
import { getBackgroundColor } from "./helpers";

const DropAreaComponent = ({ dragDropObj, rowIndex, cellIndex }) => {
    const [droppedItem, setDroppedItem] = useState(dragDropObj?.droppedSource);
    useEffect(() => {
        if (dragDropObj?.droppedSource) {
            setDroppedItem(dragDropObj?.droppedSource);
        } else {
            setDroppedItem(null);
        }
    }, [dragDropObj?.droppedSource?.genericId]);

    const backgroundColor = dragDropObj?.fillIndex
        ? getBackgroundColor(dragDropObj.fillIndex)
        : getBackgroundColor(0);
    return (
        <div
            className="renderer-table-drag-drop-area-wrapper"
            style={{
                backgroundColor,
            }}
        >
            {droppedItem && (
                <DroppedItem
                    droppedSource={droppedItem}
                    index1={rowIndex}
                    index2={cellIndex}
                    setDroppedItem={setDroppedItem}
                />
            )}
        </div>
    );
};

export default DropAreaComponent;
