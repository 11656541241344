import React from "react";
import { getBackgroundColor, getBoxShadow } from "./helpers";

const CustomInput = ({
    style = {},
    fontStyle = {},
    initialValue,
    isTextarea = false,
    placeholder,
    inputProps = {},
    value,
}) => {
    const extraBorder = () => {
        return { border: "0.5px solid #333333" };
    };

    return (
        <div
            style={{
                display: "flex",
                width: "auto",
                border: 0,
                ...style,
                ...extraBorder(),
                boxShadow: getBoxShadow(inputProps?.highlight?.value),
            }}
            className="react-math-keyboard-input-container"
        >
            {isTextarea ? (
                <textarea
                    className="react-math-keyboard-input"
                    style={{
                        ...fontStyle,
                        border: 0,
                        resize: "none",
                        background: getBackgroundColor(
                            inputProps?.bg_index?.value,
                        ),
                        pointerEvents: "nome",
                    }}
                    inputMode="none"
                    placeholder={placeholder}
                    value={initialValue || value}
                />
            ) : (
                <input
                    className="react-math-keyboard-input"
                    style={{
                        ...fontStyle,
                        color: "black",
                        fontWeight: "500",
                        border: 0,
                        background: getBackgroundColor(
                            inputProps?.bg_index?.value,
                        ),
                        pointerEvents: "none",
                    }}
                    inputMode="none"
                    placeholder={placeholder}
                    value={initialValue || value}
                />
            )}
        </div>
    );
};

export default CustomInput;
