const checkHasEndBlock = ({ blocks, worksheet }: any) => {
    if (worksheet?.type !== "quiz_form") return true;

    const blocksLength = blocks?.length || 0;
    if (!blocksLength) return false;

    const block = blocks[blocksLength - 1];

    const { type, data } = block;
    if (type !== "content" || !data) return false;

    const { content } = data;

    return !!content?.isEnd;
};

export const publishWorksheet = async ({
    validateBlocks,
    message,
    onSave,
    worksheet,
    updateWorksheet,
    worksheet_id,
    blocks,
    setNewAudioJob = ({ job_id }: { job_id: string }) => {},
    levelReset,
}: any) => {
    const validationsArr = validateBlocks();
    const isInvalid = validationsArr.some((val: boolean) => !val);

    if (isInvalid) {
        message.error(
            `Validations failed, pls verify all blocks have required data!`,
        );
        return;
    }

    if (!checkHasEndBlock({ worksheet, blocks })) {
        message.error(
            `End block missing! Please add a content block at the end with end flag as true!`,
        );
        return;
    }
    const saveVal = await onSave({
        showMessage: false,
        publish: true,
        levelReset,
    });

    if (!saveVal) {
        message.error(`Something went wrong!`);
        return;
    }

    let inputObj: any = {};

    if (
        ["personalized_learning_v3", "personalized_learning_v4"].includes(
            worksheet.type,
        ) &&
        !worksheet?.slug
    )
        inputObj.slug = worksheet?.slug || `${worksheet_id}`;

    if (worksheet?.type !== "personalized_learning_v4") {
        const block_count = blocks?.length || 0;
        if (worksheet?.status !== "active" || Object?.keys(inputObj)?.length)
            await updateWorksheet({
                id: worksheet_id,
                object: {
                    status: "active",
                    ...inputObj,
                    other: { ...worksheet?.other, block_count },
                },
            });
        else if (block_count !== worksheet?.other?.block_count) {
            await updateWorksheet({
                id: worksheet_id,
                object: {
                    other: { ...worksheet?.other, block_count },
                },
            });
        }
    }
    message.success(`Sucessfully updated worksheet!`);
};
