import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Divider, Form, Switch, Typography } from "antd";
import { cloneDeep, set } from "lodash";
import { COMPONENT_TYPES } from "../../../defaultValue";
import TopicEventsModal from "./TopicEventsModal";

const {
    // Paragraph,
    Title,
} = Typography;

const ComponentDetails = (props: any) => {
    const { block, setBlock, disableWrite } = props;
    return (
        <>
            <Title
                level={5}
                editable={
                    disableWrite
                        ? false
                        : {
                              text: block.name,
                              onChange: (val) => {
                                  let tmpBlock = cloneDeep(block);
                                  tmpBlock = set(tmpBlock, ["name"], val);
                                  setBlock(tmpBlock);
                              },
                              triggerType: ["icon", "text"],
                              enterIcon: null,
                          }
                }
            >
                {block.name}
            </Title>
            {/* <Paragraph
                editable={
                    disableWrite
                        ? false
                        : {
                              text: block.description,
                              onChange: (val) => {
                                  let tmpBlock = cloneDeep(block);
                                  tmpBlock = set(
                                      tmpBlock,
                                      ["description"],
                                      val,
                                  );
                                  setBlock(tmpBlock);
                              },
                              triggerType: ["icon", "text"],
                              enterIcon: null,
                          }
                }
            >
                {block.description}
            </Paragraph> */}
            <div
                style={{ display: "flex", flexWrap: "wrap", columnGap: "10px" }}
            >
                {![COMPONENT_TYPES.RICH_TEXT, COMPONENT_TYPES.TIMER].includes(
                    block?.type,
                ) && (
                    <>
                        <Form.Item label={"Disable Interaction?"}>
                            <Switch
                                disabled={disableWrite}
                                size="small"
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={block.disable_interaction}
                                onChange={(value) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["disable_interaction"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label={"Skip Evaluation?"}>
                            <Switch
                                disabled={disableWrite}
                                size="small"
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={block.skip_evaluation}
                                onChange={(value) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["skip_evaluation"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    </>
                )}
                <Form.Item label={"Show in preview?"}>
                    <Switch
                        disabled={disableWrite}
                        size="small"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={block.show_in_preview}
                        onChange={(value) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["show_in_preview"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>
            </div>
            <TopicEventsModal
                disableWrite={disableWrite}
                topicEventEffectsMap={block?.data?.topicEventEffectsMap}
                setBlock={(v) => {
                    let tmpBlock = cloneDeep(block);
                    tmpBlock = set(
                        tmpBlock,
                        ["data", "topicEventEffectsMap"],
                        v,
                    );
                    setBlock(tmpBlock);
                }}
            />
            <Divider />
        </>
    );
};

export default ComponentDetails;
