import {
    Modal,
    Form,
    message,
    Input,
    Button,
    Col,
    Space,
    Row,
    Divider,
} from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import { CopyIcon, SlateEditor } from "src/components";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { captureException } from "@sentry/react";

const SCHEMA = {
    name: "",
    text: [],
    duration: 5,
};

const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            cursor: "pointer",
        }}
    />
));

const SortableContainerCustom = SortableContainer(({ children }: any) => {
    return <div>{children}</div>;
});

const SortableItemCustom = SortableElement((props: any) => {
    const { children, sid } = props;
    return (
        <div style={{ zIndex: 1000 }} key={`${sid}`}>
            {children}
        </div>
    );
});

export const FeedbackPool = ({
    isModalOpen,
    setIsModalOpen,
    name,
    feedbackPool,
    disableWrite,
    onSave,

    hasMentions,
    mentionsList = [],
    currentEditor,
    setCurrentEditor,
}: any) => {
    const schema = SCHEMA;
    const [isSubmitting, setSubmitting] = useState(false);
    const [curConfig, setCurConfig] = useState(
        (typeof feedbackPool === "string"
            ? JSON.parse(feedbackPool)
            : feedbackPool) || [],
    );
    const [loading, setLoading] = useState(false);
    const [tempConfig, setTempConfig] = useState("");

    const handleSubmit = async () => {
        setSubmitting(true);
        for (let i = 0; i < curConfig.length; i++) {
            let tempComp = curConfig[i];
            if (!tempComp?.name) {
                message.error("Name is empty!");
                setSubmitting(false);
                return;
            }
        }
        try {
            await onSave(curConfig);
            message.success("Successfully Updated!");
        } catch (e) {
            captureException(e);
            console.log(e);
            message.error("Could not complete the request!");
        }
        setSubmitting(false);
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (loading) {
            setTimeout(() => setLoading(false), 50);
        }
    }, [loading]);

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        let tmpBlock = _.cloneDeep(curConfig);
        tmpBlock = arrayMoveImmutable(tmpBlock, oldIndex, newIndex).filter(
            (el) => !!el,
        );
        setCurConfig(tmpBlock);
    };

    return (
        <Modal
            title={`Feedback Pool for ${name}`}
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={null}
            width={720}
            maskClosable={false}
            style={{ top: 10 }}
            destroyOnClose
        >
            <Row
                style={{
                    maxHeight: "70vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                    scrollbarWidth: "thin",
                }}
            >
                <Col span={24}>
                    {schema && !disableWrite && (
                        <Button
                            ghost
                            type="primary"
                            onClick={() => {
                                let tmpBlock = _.cloneDeep(curConfig);
                                tmpBlock.push(schema);
                                setCurConfig(tmpBlock);
                            }}
                        >
                            Add New Text
                        </Button>
                    )}
                    <Divider />
                </Col>
                <Col span={24}>
                    {!loading && (
                        <SortableContainerCustom
                            onSortEnd={onSortEnd}
                            useDragHandle
                        >
                            {curConfig?.map(
                                (feedback: any, feedbackIdx: number) => {
                                    return (
                                        <SortableItemCustom
                                            disabled={disableWrite}
                                            index={feedbackIdx}
                                            sid={feedbackIdx}
                                            key={feedbackIdx}
                                        >
                                            <Row gutter={[20, 20]}>
                                                <Col span={24}>
                                                    <Space
                                                        style={{
                                                            marginBottom:
                                                                "10px",
                                                        }}
                                                    >
                                                        <DragHandle />
                                                        <h3
                                                            style={{
                                                                marginBottom:
                                                                    "10px",
                                                            }}
                                                        >
                                                            Name -{" "}
                                                            {feedback?.name}
                                                        </h3>
                                                        <Button
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            icon={
                                                                <DeleteOutlined />
                                                            }
                                                            type="primary"
                                                            shape="circle"
                                                            danger
                                                            size="small"
                                                            onClick={() => {
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        curConfig,
                                                                    );
                                                                _.pullAt(
                                                                    tmpBlock,
                                                                    feedbackIdx,
                                                                );
                                                                setCurConfig(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                        />

                                                        <Button
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            icon={<CopyIcon />}
                                                            type="primary"
                                                            shape="circle"
                                                            size="small"
                                                            onClick={() => {
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        curConfig,
                                                                    );
                                                                tmpBlock.splice(
                                                                    feedbackIdx,
                                                                    0,
                                                                    _.cloneDeep(
                                                                        feedback,
                                                                    ),
                                                                );
                                                                setCurConfig(
                                                                    tmpBlock,
                                                                );
                                                                message.info(
                                                                    `Duplicated!`,
                                                                );
                                                            }}
                                                        />
                                                        <Button
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            type="primary"
                                                            size="small"
                                                            onClick={() => {
                                                                message.info(
                                                                    `Copied to clipboard!`,
                                                                );
                                                                navigator.clipboard.writeText(
                                                                    JSON.stringify(
                                                                        [
                                                                            feedback,
                                                                        ],
                                                                    ),
                                                                );
                                                            }}
                                                        >
                                                            Copy to clipboard
                                                        </Button>
                                                    </Space>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={"Name"}
                                                        validateStatus={
                                                            !feedback["name"]
                                                                ? "error"
                                                                : ""
                                                        }
                                                        help={
                                                            !feedback["name"]
                                                                ? "Required Field"
                                                                : ""
                                                        }
                                                    >
                                                        <Input
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            value={
                                                                feedback["name"]
                                                                // ?.replace(`${name}_`, "")
                                                            }
                                                            placeholder="Type here..."
                                                            onChange={(e) => {
                                                                let value =
                                                                    e.target
                                                                        .value;
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        curConfig,
                                                                    );
                                                                _.set(
                                                                    tmpBlock,
                                                                    [
                                                                        feedbackIdx,
                                                                        "name",
                                                                    ],
                                                                    value,
                                                                    // `${name}_${value}`,
                                                                );
                                                                setCurConfig(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={"Duration"}
                                                        validateStatus={
                                                            !feedback[
                                                                "duration"
                                                            ]
                                                                ? "error"
                                                                : ""
                                                        }
                                                        help={
                                                            !feedback[
                                                                "duration"
                                                            ]
                                                                ? "Required Field"
                                                                : ""
                                                        }
                                                    >
                                                        <Input
                                                            type="number"
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            value={
                                                                feedback[
                                                                    "duration"
                                                                ]
                                                            }
                                                            placeholder="Type here..."
                                                            onChange={(e) => {
                                                                let value =
                                                                    e.target
                                                                        .value;
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        curConfig,
                                                                    );
                                                                _.set(
                                                                    tmpBlock,
                                                                    [
                                                                        feedbackIdx,
                                                                        "duration",
                                                                    ],
                                                                    value,
                                                                );
                                                                setCurConfig(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label={"Feedback"}
                                                    >
                                                        <SlateEditor
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            hasMentions={
                                                                hasMentions
                                                            }
                                                            mentionsList={
                                                                mentionsList
                                                            }
                                                            id={`${feedbackIdx}_figma_${feedbackIdx}`}
                                                            onChange={(
                                                                value: any,
                                                            ) => {
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        curConfig,
                                                                    );
                                                                tmpBlock =
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            feedbackIdx,
                                                                            "text",
                                                                        ],
                                                                        value,
                                                                    );
                                                                setCurConfig(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                            isFocused={
                                                                currentEditor ===
                                                                `${feedbackIdx}_figma_${feedbackIdx}`
                                                            }
                                                            setEditor={(
                                                                id: string,
                                                            ) =>
                                                                setCurrentEditor(
                                                                    id,
                                                                )
                                                            }
                                                            value={
                                                                feedback?.text ||
                                                                ""
                                                            }
                                                            placeholder={
                                                                "Type here..."
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </SortableItemCustom>
                                    );
                                },
                            )}
                        </SortableContainerCustom>
                    )}
                </Col>
                {curConfig?.length > 0 && (
                    <Col span={24}>
                        {schema && !disableWrite && (
                            <Button
                                ghost
                                type="primary"
                                onClick={() => {
                                    let tmpBlock = _.cloneDeep(curConfig);
                                    tmpBlock.push(schema);
                                    setCurConfig(tmpBlock);
                                }}
                            >
                                Add New Text
                            </Button>
                        )}
                        <Divider />
                    </Col>
                )}
                {!disableWrite && (
                    <Col span={24}>
                        <Form.Item label={"Paste Feedback Config here"}>
                            <Input.TextArea
                                disabled={disableWrite}
                                value={tempConfig}
                                placeholder="Paste Config here"
                                onChange={(e) => setTempConfig(e.target.value)}
                            />

                            <Space>
                                <Button
                                    disabled={disableWrite}
                                    style={{ marginTop: "10px" }}
                                    type="primary"
                                    onClick={() => {
                                        try {
                                            const parsedJson =
                                                JSON.parse(tempConfig);
                                            if (
                                                !Array.isArray(parsedJson) ||
                                                parsedJson?.some(
                                                    (v) => !v?.text,
                                                )
                                            ) {
                                                message.error("Error in JSON!");
                                                return;
                                            }
                                            setLoading(true);
                                            setCurConfig(parsedJson);
                                            setTempConfig("");
                                            message.info("Updated!");
                                        } catch (e) {
                                            // captureException(e)
                                            message.error("Error in JSON!");
                                        }
                                    }}
                                >
                                    Replace
                                </Button>
                                <Button
                                    disabled={disableWrite}
                                    style={{ marginTop: "10px" }}
                                    type="primary"
                                    onClick={() => {
                                        try {
                                            const parsedJson =
                                                JSON.parse(tempConfig);
                                            if (
                                                !Array.isArray(parsedJson) ||
                                                parsedJson?.some(
                                                    (v) => !v?.text,
                                                )
                                            ) {
                                                message.error("Error in JSON!");
                                                return;
                                            }
                                            setCurConfig([
                                                ...curConfig,
                                                ...parsedJson,
                                            ]);
                                            setTempConfig("");
                                            message.info("Updated!");
                                        } catch (e) {
                                            // captureException(e);
                                            message.error("Error in JSON!");
                                        }
                                    }}
                                >
                                    Append
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <div
                style={{
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                {curConfig?.length > 0 ? (
                    <Button
                        disabled={disableWrite}
                        type="primary"
                        onClick={() => {
                            message.info(
                                `Copied components config to clipboard!`,
                            );

                            navigator.clipboard.writeText(
                                JSON.stringify(curConfig),
                            );
                        }}
                    >
                        Copy this Feedback Config to Clipboard
                    </Button>
                ) : (
                    <div />
                )}
                <Space>
                    <Button
                        type="default"
                        onClick={() => setIsModalOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                        loading={isSubmitting}
                        disabled={disableWrite}
                    >
                        Submit
                    </Button>
                </Space>
            </div>
        </Modal>
    );
};
