import { signOut } from "supertokens-auth-react/recipe/session";
import { Button, Layout as AntdLayout, PageHeader } from "antd";
import { useRouter } from "src/helpers";
import React from "react";
import headers from "./util";
import { useUserData } from "src/helpers/supertokens";
import { resetStore } from "src/services/graphql";
const { Header: AntdHeader } = AntdLayout;

export const Header: React.FC = () => {
    const router = useRouter();
    const title = headers(router.asPath);

    const { user } = useUserData();

    return (
        <>
            <AntdHeader
                className="site-layout-background"
                style={{ padding: 0 }}
            >
                <PageHeader
                    onBack={() => router.back()}
                    title={title}
                    extra={[
                        <span style={{ float: "right", color: "#808080 " }}>
                            (logged in as {user?.email})
                        </span>,

                        <Button
                            type="dashed"
                            danger
                            onClick={async () => {
                                await signOut();
                                resetStore();
                                window.location.href = "/auth";
                            }}
                        >
                            Logout
                        </Button>,
                    ]}
                />
            </AntdHeader>
        </>
    );
};
