import {
    EyeInvisibleOutlined,
    MenuOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { Button, Card, Typography, Collapse, Row, Col } from "antd";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Can } from "src/services/casl";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { SortableHandle } from "react-sortable-hoc";
import {
    BLOCK_COMPUTE_FUNCS,
    DEFAULT_CHUNK_SCHEMA,
    V4_PROGRESS_LEVEL_DEFAULT_VALUE,
} from "src/modules/worksheet/components/blocks/renderers/ProgressLevel/defaultValue";
import { v4 as uuid } from "uuid";
import getV2StoriesDefaultValue from "src/modules/worksheet/components/blocks/renderers/V2Stories/defaultValue";
import { Actions } from "../../Editor/BlocksColumn/Actions";
import DEFAULT_BLOCK_SCHEMA from "src/modules/worksheet/components/blocks/renderers/AllInOne/defaultValue";
import { StoryJobs } from "../../Modals/StoryJobs";
import { checkBlockValid } from "src/modules/worksheet/components/blocks";
import { useMemo, useState } from "react";
import CollapseBtn from "../../../helpers/CollapseBtn";
import { checkChunkValid } from "src/modules/worksheet/components/blocks/renderers/ProgressLevel/isValid";

const DragHandle = SortableHandle(() => <MenuOutlined />);

const { Paragraph } = Typography;
const { Panel } = Collapse;

const SortableItemCustom = SortableElement((props: any) => {
    const {
        block,
        idx,
        type,
        startLetter,
        onClick,
        checkIsValid,
        worksheet,
        chunkIndex,
        chunkSlug,
        blockIndex,
    } = props;
    const isValid: boolean = checkIsValid
        ? checkIsValid({ block, worksheet })?.isValid
        : checkBlockValid(block, worksheet, {
              chunkIndex,
              chunkSlug,
              blockIndex,
          });
    const getBg = () => {
        return isValid
            ? {}
            : {
                  background: "#FFD6D7",
                  paddingTop: "10px",
                  paddingRight: "10px",
              };
    };
    return (
        <div
            style={{
                display: "flex",
                gap: "20px",
                width: "100%",
                alignItems: "center",
                ...getBg(),
            }}
        >
            <div style={{ flexShrink: 0 }}>
                <DragHandle />
            </div>
            <Paragraph
                onClick={onClick}
                style={{
                    width: "100%",
                    cursor: "pointer",
                }}
            >
                {startLetter}
                {idx + 1} {block.data[type].name}{" "}
                {block?.is_hidden && <EyeInvisibleOutlined />}
            </Paragraph>
        </div>
    );
});

const SortableContainerCustom = SortableContainer(({ children }: any) => {
    return <div style={{ overflow: "auto" }}>{children}</div>;
});

export const BlocksColumn = (props: any) => {
    const {
        blocks = [],
        addBlock,
        deleteBlock,
        // blockTypes = [],
        setBlocks,
        setIsEditor,
        currentBlock,
        setBlock,
        setCurrentSubBlock,
        setCurrentBlock,
        currentSubBlock,
        currentChunkBlock,
        setCurrentChunkBlock,
        storyJobs,
        worksheet,
        worksheetStoryJobs,
        updateStoriesJob,
        updateAudiosJob,
        audioJobs,
        worksheetAudioJobs,
        toggleHasChanged,
    } = props;

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        setBlocks(
            arrayMoveImmutable(blocks, oldIndex, newIndex).filter((el) => !!el),
        );
        setCurrentBlock(newIndex);
    };

    const onSortEndChunk = ({ oldIndex, newIndex, idx }: any) => {
        let tmpBlock = cloneDeep(blocks[idx]);
        const tmpChildren = arrayMoveImmutable(
            tmpBlock.children,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = set(tmpBlock, ["children"], tmpChildren);
        setBlock(tmpBlock, idx);
        setCurrentSubBlock(newIndex);
    };

    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    let hasNullIds = useMemo(() => {
        const retVal = blocks?.some((v) =>
            v?.children?.some((w) =>
                w?.data?.chunk?.blocks?.some(
                    (x) =>
                        !x?.id || x?.data?.other?.stories?.some((y) => !y?.id),
                ),
            ),
        );
        return retVal;
    }, [blocks]);

    const updateIds = () => {
        let tmpBlocks = cloneDeep(blocks);
        setBlocks(
            tmpBlocks?.map((v) => {
                if (v?.children?.length)
                    v.children = v.children?.map((w) => {
                        if (w?.data?.chunk?.blocks?.length)
                            w.data.chunk.blocks = w?.data?.chunk?.blocks?.map(
                                (x) => {
                                    if (!x?.id) x.id = uuid();
                                    if (x?.data?.other?.stories)
                                        x.data.other.stories =
                                            x?.data?.other?.stories?.map(
                                                (y) => {
                                                    if (!y?.id) y.id = uuid();
                                                    return y;
                                                },
                                            );
                                    return x;
                                },
                            );
                        return w;
                    });
                return v;
            }),
        );
    };

    return (
        <Can I="edit" a={"worksheet_block"} passThrough>
            {(allowed: boolean) => (
                <Card
                    style={{
                        borderRadius: "8px",
                        marginRight: "8px",
                        height: "85vh",
                        width: "100%",
                    }}
                    bordered={true}
                    onClick={() => {
                        setIsEditor(false);
                    }}
                    bodyStyle={{
                        padding: 0,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <CollapseBtn
                            collapsed={collapsed}
                            toggleCollapsed={toggleCollapsed}
                            style={{
                                margin: collapsed ? 2 : 15,
                                marginTop: collapsed ? 10 : 15,
                            }}
                        />
                        {!collapsed && allowed && (
                            <StoryJobs
                                {...{
                                    worksheet,
                                    storyJobs,
                                    worksheetStoryJobs,
                                    setBlock,
                                    blocks,
                                    updateStoriesJob,
                                    updateAudiosJob,
                                    audioJobs,
                                    worksheetAudioJobs,
                                    setBlocks,
                                    toggleHasChanged,
                                    setCurrentBlock,
                                    setCurrentSubBlock,
                                    setCurrentChunkBlock,
                                }}
                            />
                        )}
                    </div>
                    {worksheet?.type !== "personalized_learning_v4" &&
                        !collapsed &&
                        allowed &&
                        hasNullIds && (
                            <Button
                                size="small"
                                danger
                                type="primary"
                                onClick={updateIds}
                                style={{
                                    margin: "0 20px",
                                }}
                            >
                                Update IDs
                            </Button>
                        )}
                    <div
                        style={{
                            display: collapsed ? "none" : "block",
                        }}
                    >
                        <div
                            style={{
                                maxHeight: "80vh",
                                overflow: "auto",
                                scrollbarWidth: "thin",
                            }}
                        >
                            <SortableContainerCustom
                                onSortEnd={onSortEnd}
                                useDragHandle
                            >
                                <Collapse
                                    accordion
                                    expandIconPosition="right"
                                    ghost
                                    activeKey={currentBlock}
                                    collapsible="icon"
                                    onChange={(key) => {
                                        setCurrentBlock(Number(key));
                                        setCurrentSubBlock(null);
                                        setCurrentChunkBlock(null);
                                    }}
                                >
                                    {blocks.map((block: any, idx: number) => {
                                        const isValid = checkBlockValid(
                                            block,
                                            worksheet,
                                        );
                                        return (
                                            <Panel
                                                key={idx}
                                                header={
                                                    <SortableItemCustom
                                                        {...props}
                                                        disabled={!allowed}
                                                        idx={idx}
                                                        type={block.type}
                                                        key={`item-${
                                                            block.id ||
                                                            block.tmpId
                                                        }`}
                                                        index={idx}
                                                        block={block}
                                                        startLetter={"L"}
                                                        onClick={() => {
                                                            setCurrentBlock(
                                                                idx,
                                                            );
                                                            setCurrentSubBlock(
                                                                null,
                                                            );
                                                            setCurrentChunkBlock(
                                                                null,
                                                            );
                                                        }}
                                                        worksheet={worksheet}
                                                    />
                                                }
                                                extra={
                                                    <Actions
                                                        {...{
                                                            disableActions:
                                                                !allowed,
                                                            blocks,
                                                            disableDuplicateAction:
                                                                !allowed,
                                                            addBlock,
                                                            deleteBlock,
                                                            idx,
                                                            type: block.type,
                                                            block,
                                                        }}
                                                    />
                                                }
                                                style={{
                                                    background:
                                                        currentBlock === idx &&
                                                        currentSubBlock ===
                                                            null &&
                                                        currentChunkBlock ===
                                                            null
                                                            ? "#E6F7FF"
                                                            : "#ffffff",
                                                    borderRight:
                                                        currentBlock === idx &&
                                                        currentSubBlock ===
                                                            null &&
                                                        currentChunkBlock ===
                                                            null
                                                            ? !isValid
                                                                ? "4px solid red"
                                                                : "4px solid #1890FF"
                                                            : 0,
                                                }}
                                            >
                                                <SortableContainerCustom
                                                    onSortEnd={(v) =>
                                                        onSortEndChunk({
                                                            ...v,
                                                            idx,
                                                        })
                                                    }
                                                    useDragHandle
                                                >
                                                    <Collapse
                                                        accordion
                                                        collapsible="icon"
                                                        expandIconPosition="right"
                                                        ghost
                                                        onChange={(key) => {
                                                            setCurrentBlock(
                                                                idx,
                                                            );
                                                            setCurrentSubBlock(
                                                                Number(key),
                                                            );
                                                            setCurrentChunkBlock(
                                                                null,
                                                            );
                                                        }}
                                                        activeKey={
                                                            currentSubBlock
                                                        }
                                                    >
                                                        {block.children.map(
                                                            (
                                                                chunk: any,
                                                                cidx: number,
                                                            ) => {
                                                                const isValid =
                                                                    checkChunkValid(
                                                                        {
                                                                            block: chunk,
                                                                            worksheet,
                                                                            chunkIndex:
                                                                                cidx,
                                                                        },
                                                                    )?.isValid;

                                                                const {
                                                                    data: {
                                                                        slug: dataSlug,
                                                                    },
                                                                    backend,
                                                                } = chunk;
                                                                let chunkSlug =
                                                                    worksheet?.type ===
                                                                    "personalized_learning_v4"
                                                                        ? backend?.slug
                                                                        : dataSlug;
                                                                return (
                                                                    <Panel
                                                                        key={
                                                                            cidx
                                                                        }
                                                                        header={
                                                                            <SortableItemCustom
                                                                                {...props}
                                                                                disabled={
                                                                                    !allowed
                                                                                }
                                                                                checkIsValid={
                                                                                    checkChunkValid
                                                                                }
                                                                                onClick={() => {
                                                                                    setCurrentBlock(
                                                                                        idx,
                                                                                    );
                                                                                    setCurrentSubBlock(
                                                                                        cidx,
                                                                                    );
                                                                                    setCurrentChunkBlock(
                                                                                        null,
                                                                                    );
                                                                                }}
                                                                                idx={
                                                                                    cidx
                                                                                }
                                                                                type={
                                                                                    chunk.type
                                                                                }
                                                                                key={`chunk_item-${idx}-${
                                                                                    chunk.id ||
                                                                                    chunk.tmpId
                                                                                }`}
                                                                                index={
                                                                                    cidx
                                                                                }
                                                                                block={
                                                                                    chunk
                                                                                }
                                                                                worksheet={
                                                                                    worksheet
                                                                                }
                                                                                startLetter={
                                                                                    ((worksheet?.type !==
                                                                                        "personalized_learning_v4" &&
                                                                                        chunk
                                                                                            .data[
                                                                                            "chunk"
                                                                                        ]
                                                                                            ?.is_variant) ||
                                                                                    (worksheet?.type ===
                                                                                        "personalized_learning_v4" &&
                                                                                        chunk
                                                                                            ?.backend
                                                                                            ?.is_variant)
                                                                                        ? "(V) "
                                                                                        : "") +
                                                                                    "C"
                                                                                }
                                                                                setBlock={(
                                                                                    b: any,
                                                                                ) => {
                                                                                    let tmpBlock =
                                                                                        cloneDeep(
                                                                                            block,
                                                                                        );
                                                                                    tmpBlock =
                                                                                        set(
                                                                                            tmpBlock,
                                                                                            [
                                                                                                "children",
                                                                                                cidx,
                                                                                            ],
                                                                                            b,
                                                                                        );
                                                                                    setBlock(
                                                                                        tmpBlock,
                                                                                        idx,
                                                                                    );
                                                                                }}
                                                                            />
                                                                        }
                                                                        extra={
                                                                            <Actions
                                                                                {...{
                                                                                    disableActions:
                                                                                        !allowed,
                                                                                    blocks,
                                                                                    disableDuplicateAction:
                                                                                        !allowed,
                                                                                    addBlockCustom:
                                                                                        (
                                                                                            type: string,
                                                                                            index: number,
                                                                                            newBlock: any,
                                                                                        ) => {
                                                                                            let tmpBlock =
                                                                                                _.cloneDeep(
                                                                                                    block,
                                                                                                );
                                                                                            const tmpChildren =
                                                                                                [
                                                                                                    ...block.children,
                                                                                                ];
                                                                                            const newBlock2 =
                                                                                                {
                                                                                                    ...newBlock,
                                                                                                    children:
                                                                                                        newBlock?.children?.map(
                                                                                                            (
                                                                                                                gChild: any,
                                                                                                            ) =>
                                                                                                                _.omit(
                                                                                                                    {
                                                                                                                        ...gChild,
                                                                                                                        tmpId: uuid(),
                                                                                                                    },
                                                                                                                    [
                                                                                                                        "id",
                                                                                                                    ],
                                                                                                                ),
                                                                                                        ),
                                                                                                };
                                                                                            tmpChildren.splice(
                                                                                                index,
                                                                                                0,
                                                                                                newBlock2,
                                                                                            );
                                                                                            tmpBlock =
                                                                                                _.set(
                                                                                                    tmpBlock,
                                                                                                    [
                                                                                                        "children",
                                                                                                    ],
                                                                                                    tmpChildren,
                                                                                                );

                                                                                            setBlock(
                                                                                                tmpBlock,
                                                                                                idx,
                                                                                            );
                                                                                            setCurrentBlock(
                                                                                                idx,
                                                                                            );
                                                                                            setCurrentSubBlock(
                                                                                                index,
                                                                                            );
                                                                                            setCurrentChunkBlock(
                                                                                                null,
                                                                                            );
                                                                                        },
                                                                                    deleteBlock:
                                                                                        (
                                                                                            index: number,
                                                                                        ) => {
                                                                                            setCurrentSubBlock(
                                                                                                null,
                                                                                            );
                                                                                            setCurrentChunkBlock(
                                                                                                null,
                                                                                            );
                                                                                            let tmpBlock =
                                                                                                _.cloneDeep(
                                                                                                    block,
                                                                                                );
                                                                                            const tmpChildren =
                                                                                                [
                                                                                                    ...block.children,
                                                                                                ];
                                                                                            _.pullAt(
                                                                                                tmpChildren,
                                                                                                cidx,
                                                                                            );
                                                                                            tmpBlock =
                                                                                                _.set(
                                                                                                    tmpBlock,
                                                                                                    [
                                                                                                        "children",
                                                                                                    ],
                                                                                                    tmpChildren,
                                                                                                );
                                                                                            setBlock(
                                                                                                tmpBlock,
                                                                                                idx,
                                                                                            );
                                                                                        },
                                                                                    idx: cidx,
                                                                                    type: chunk.type,
                                                                                    block: chunk,

                                                                                    // for progression stage
                                                                                    updateId:
                                                                                        true,
                                                                                    ignoreId:
                                                                                        true,
                                                                                }}
                                                                            />
                                                                        }
                                                                        style={{
                                                                            background:
                                                                                currentBlock ===
                                                                                    idx &&
                                                                                currentSubBlock ===
                                                                                    cidx &&
                                                                                currentChunkBlock ===
                                                                                    null
                                                                                    ? "#E6F7FF"
                                                                                    : (worksheet?.type !==
                                                                                          "personalized_learning_v4" &&
                                                                                          chunk
                                                                                              .data[
                                                                                              "chunk"
                                                                                          ]
                                                                                              ?.is_variant) ||
                                                                                      (worksheet?.type ===
                                                                                          "personalized_learning_v4" &&
                                                                                          chunk
                                                                                              ?.backend
                                                                                              ?.is_variant)
                                                                                    ? "#d2fae8"
                                                                                    : "#ffffff",
                                                                            borderRight:
                                                                                currentBlock ===
                                                                                    idx &&
                                                                                currentSubBlock ===
                                                                                    cidx &&
                                                                                currentChunkBlock ===
                                                                                    null
                                                                                    ? !isValid
                                                                                        ? "4px solid red"
                                                                                        : "4px solid #1890FF"
                                                                                    : 0,
                                                                        }}
                                                                    >
                                                                        <SortableContainerCustom
                                                                            onSortEnd={({
                                                                                oldIndex,
                                                                                newIndex,
                                                                            }: any) => {
                                                                                let tmpBlock =
                                                                                    cloneDeep(
                                                                                        block,
                                                                                    );
                                                                                const tmpChildren =
                                                                                    arrayMoveImmutable(
                                                                                        worksheet?.type ===
                                                                                            "personalized_learning_v4"
                                                                                            ? tmpBlock
                                                                                                  .children[
                                                                                                  cidx
                                                                                              ]
                                                                                                  .children
                                                                                            : tmpBlock
                                                                                                  .children[
                                                                                                  cidx
                                                                                              ]
                                                                                                  .data
                                                                                                  .chunk
                                                                                                  .blocks,
                                                                                        oldIndex,
                                                                                        newIndex,
                                                                                    ).filter(
                                                                                        (
                                                                                            el,
                                                                                        ) =>
                                                                                            !!el,
                                                                                    );
                                                                                tmpBlock =
                                                                                    set(
                                                                                        tmpBlock,
                                                                                        worksheet?.type ===
                                                                                            "personalized_learning_v4"
                                                                                            ? [
                                                                                                  "children",
                                                                                                  cidx,
                                                                                                  "children",
                                                                                              ]
                                                                                            : [
                                                                                                  "children",
                                                                                                  cidx,
                                                                                                  "data",
                                                                                                  "chunk",
                                                                                                  "blocks",
                                                                                              ],
                                                                                        tmpChildren,
                                                                                    );
                                                                                setBlock(
                                                                                    tmpBlock,
                                                                                    idx,
                                                                                );
                                                                                setCurrentChunkBlock(
                                                                                    newIndex,
                                                                                );
                                                                            }}
                                                                            useDragHandle
                                                                        >
                                                                            {(worksheet?.type ===
                                                                            "personalized_learning_v4"
                                                                                ? chunk.children ||
                                                                                  []
                                                                                : chunk
                                                                                      .data[
                                                                                      "chunk"
                                                                                  ]
                                                                                      .blocks
                                                                            )?.map(
                                                                                (
                                                                                    childBlock: any,
                                                                                    bidx: number,
                                                                                ) => {
                                                                                    const isValid =
                                                                                        checkBlockValid(
                                                                                            childBlock,
                                                                                            worksheet,
                                                                                        );
                                                                                    return (
                                                                                        <Row
                                                                                            style={{
                                                                                                display:
                                                                                                    "flex",
                                                                                                alignItems:
                                                                                                    "center",
                                                                                                padding:
                                                                                                    "10px",
                                                                                                background:
                                                                                                    currentBlock ===
                                                                                                        idx &&
                                                                                                    currentSubBlock ===
                                                                                                        cidx &&
                                                                                                    currentChunkBlock ===
                                                                                                        bidx
                                                                                                        ? "#E6F7FF"
                                                                                                        : "#ffffff",
                                                                                                borderRight:
                                                                                                    currentBlock ===
                                                                                                        idx &&
                                                                                                    currentSubBlock ===
                                                                                                        cidx &&
                                                                                                    currentChunkBlock ===
                                                                                                        bidx
                                                                                                        ? !isValid
                                                                                                            ? "4px solid red"
                                                                                                            : "4px solid #1890FF"
                                                                                                        : 0,
                                                                                            }}
                                                                                        >
                                                                                            <Col
                                                                                                span={
                                                                                                    20
                                                                                                }
                                                                                            >
                                                                                                <SortableItemCustom
                                                                                                    {...props}
                                                                                                    idx={
                                                                                                        bidx
                                                                                                    }
                                                                                                    disabled={
                                                                                                        !allowed
                                                                                                    }
                                                                                                    chunkIndex={
                                                                                                        cidx
                                                                                                    }
                                                                                                    blockIndex={
                                                                                                        bidx
                                                                                                    }
                                                                                                    chunkSlug={
                                                                                                        chunkSlug
                                                                                                    }
                                                                                                    onClick={() => {
                                                                                                        setCurrentBlock(
                                                                                                            idx,
                                                                                                        );
                                                                                                        setCurrentSubBlock(
                                                                                                            cidx,
                                                                                                        );
                                                                                                        setCurrentChunkBlock(
                                                                                                            bidx,
                                                                                                        );
                                                                                                    }}
                                                                                                    type={
                                                                                                        childBlock.type
                                                                                                    }
                                                                                                    index={
                                                                                                        bidx
                                                                                                    }
                                                                                                    block={
                                                                                                        childBlock
                                                                                                    }
                                                                                                    startLetter={
                                                                                                        "B"
                                                                                                    }
                                                                                                    setBlock={(
                                                                                                        b: any,
                                                                                                    ) => {
                                                                                                        let tmpBlock =
                                                                                                            cloneDeep(
                                                                                                                block,
                                                                                                            );
                                                                                                        tmpBlock =
                                                                                                            set(
                                                                                                                tmpBlock,
                                                                                                                worksheet?.type ===
                                                                                                                    "personalized_learning_v4"
                                                                                                                    ? [
                                                                                                                          "children",
                                                                                                                          cidx,
                                                                                                                          "children",
                                                                                                                      ]
                                                                                                                    : [
                                                                                                                          "children",
                                                                                                                          cidx,
                                                                                                                          "data",
                                                                                                                          "chunk",
                                                                                                                          "blocks",
                                                                                                                          bidx,
                                                                                                                      ],
                                                                                                                b,
                                                                                                            );
                                                                                                        setBlock(
                                                                                                            tmpBlock,
                                                                                                            idx,
                                                                                                        );
                                                                                                    }}
                                                                                                    worksheet={
                                                                                                        worksheet
                                                                                                    }
                                                                                                />
                                                                                            </Col>
                                                                                            <Col
                                                                                                span={
                                                                                                    4
                                                                                                }
                                                                                            >
                                                                                                <Actions
                                                                                                    {...{
                                                                                                        disableActions:
                                                                                                            !allowed,
                                                                                                        blocks,
                                                                                                        disableDuplicateAction:
                                                                                                            !allowed,
                                                                                                        addBlockCustom:
                                                                                                            (
                                                                                                                type: string,
                                                                                                                index: number,
                                                                                                                newBlock: any,
                                                                                                            ) => {
                                                                                                                let tmpBlock =
                                                                                                                    _.cloneDeep(
                                                                                                                        block,
                                                                                                                    );
                                                                                                                const tmpChildren =
                                                                                                                    worksheet?.type ===
                                                                                                                    "personalized_learning_v4"
                                                                                                                        ? chunk.children ||
                                                                                                                          []
                                                                                                                        : [
                                                                                                                              ...chunk
                                                                                                                                  .data[
                                                                                                                                  "chunk"
                                                                                                                              ]
                                                                                                                                  .blocks,
                                                                                                                          ];
                                                                                                                const newBlock2 =
                                                                                                                    newBlock;
                                                                                                                tmpChildren.splice(
                                                                                                                    index,
                                                                                                                    0,
                                                                                                                    newBlock2,
                                                                                                                );
                                                                                                                tmpBlock =
                                                                                                                    _.set(
                                                                                                                        tmpBlock,
                                                                                                                        worksheet?.type ===
                                                                                                                            "personalized_learning_v4"
                                                                                                                            ? [
                                                                                                                                  "children",
                                                                                                                                  cidx,
                                                                                                                                  "children",
                                                                                                                              ]
                                                                                                                            : [
                                                                                                                                  "children",
                                                                                                                                  cidx,
                                                                                                                                  "data",
                                                                                                                                  "chunk",
                                                                                                                                  "blocks",
                                                                                                                              ],
                                                                                                                        tmpChildren,
                                                                                                                    );

                                                                                                                setBlock(
                                                                                                                    tmpBlock,
                                                                                                                    idx,
                                                                                                                );
                                                                                                                setCurrentBlock(
                                                                                                                    idx,
                                                                                                                );
                                                                                                                setCurrentSubBlock(
                                                                                                                    cidx,
                                                                                                                );
                                                                                                                setCurrentChunkBlock(
                                                                                                                    index,
                                                                                                                );
                                                                                                            },
                                                                                                        deleteBlock:
                                                                                                            (
                                                                                                                index: number,
                                                                                                            ) => {
                                                                                                                setCurrentChunkBlock(
                                                                                                                    null,
                                                                                                                );
                                                                                                                setCurrentChunkBlock(
                                                                                                                    null,
                                                                                                                );
                                                                                                                let tmpBlock =
                                                                                                                    _.cloneDeep(
                                                                                                                        block,
                                                                                                                    );
                                                                                                                const tmpChildren =
                                                                                                                    worksheet?.type ===
                                                                                                                    "personalized_learning_v4"
                                                                                                                        ? chunk.children ||
                                                                                                                          []
                                                                                                                        : [
                                                                                                                              ...chunk
                                                                                                                                  .data[
                                                                                                                                  "chunk"
                                                                                                                              ]
                                                                                                                                  .blocks,
                                                                                                                          ];
                                                                                                                _.pullAt(
                                                                                                                    tmpChildren,
                                                                                                                    bidx,
                                                                                                                );
                                                                                                                tmpBlock =
                                                                                                                    _.set(
                                                                                                                        tmpBlock,
                                                                                                                        worksheet?.type ===
                                                                                                                            "personalized_learning_v4"
                                                                                                                            ? [
                                                                                                                                  "children",
                                                                                                                                  cidx,
                                                                                                                                  "children",
                                                                                                                              ]
                                                                                                                            : [
                                                                                                                                  "children",
                                                                                                                                  cidx,
                                                                                                                                  "data",
                                                                                                                                  "chunk",
                                                                                                                                  "blocks",
                                                                                                                              ],
                                                                                                                        tmpChildren,
                                                                                                                    );
                                                                                                                setBlock(
                                                                                                                    tmpBlock,
                                                                                                                    idx,
                                                                                                                );
                                                                                                            },
                                                                                                        idx: bidx,
                                                                                                        type: childBlock.type,
                                                                                                        block: childBlock,

                                                                                                        // for progression stage
                                                                                                        updateId:
                                                                                                            true,
                                                                                                        ignoreId:
                                                                                                            worksheet?.type ===
                                                                                                            "personalized_learning_v4",
                                                                                                    }}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    );
                                                                                },
                                                                            )}
                                                                        </SortableContainerCustom>
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "center",
                                                                                padding:
                                                                                    "10px",
                                                                                flexDirection:
                                                                                    "column",
                                                                            }}
                                                                        >
                                                                            <Button
                                                                                type="link"
                                                                                onClick={() => {
                                                                                    let tmpBlock =
                                                                                        cloneDeep(
                                                                                            block,
                                                                                        );
                                                                                    tmpBlock =
                                                                                        set(
                                                                                            tmpBlock,
                                                                                            worksheet?.type ===
                                                                                                "personalized_learning_v4"
                                                                                                ? [
                                                                                                      "children",
                                                                                                      cidx,
                                                                                                      "children",
                                                                                                      chunk
                                                                                                          ?.children
                                                                                                          ?.length ||
                                                                                                          0,
                                                                                                  ]
                                                                                                : [
                                                                                                      "children",
                                                                                                      cidx,
                                                                                                      "data",
                                                                                                      "chunk",
                                                                                                      "blocks",
                                                                                                      chunk
                                                                                                          .data[
                                                                                                          "chunk"
                                                                                                      ]
                                                                                                          .blocks
                                                                                                          .length,
                                                                                                  ],
                                                                                            {
                                                                                                tmpId: uuid(),
                                                                                                ...(worksheet?.type ===
                                                                                                "personalized_learning_v4"
                                                                                                    ? {}
                                                                                                    : {
                                                                                                          id: uuid(),
                                                                                                      }),

                                                                                                ...DEFAULT_BLOCK_SCHEMA(
                                                                                                    worksheet?.type,
                                                                                                ),
                                                                                            },
                                                                                        );
                                                                                    setBlock(
                                                                                        tmpBlock,
                                                                                        idx,
                                                                                    );
                                                                                    setCurrentBlock(
                                                                                        idx,
                                                                                    );
                                                                                    setCurrentSubBlock(
                                                                                        cidx,
                                                                                    );
                                                                                    setCurrentChunkBlock(
                                                                                        worksheet?.type ===
                                                                                            "personalized_learning_v4"
                                                                                            ? chunk
                                                                                                  ?.children
                                                                                                  ?.length ||
                                                                                                  0
                                                                                            : chunk
                                                                                                  .data[
                                                                                                  "chunk"
                                                                                              ]
                                                                                                  .blocks
                                                                                                  .length,
                                                                                    );
                                                                                }}
                                                                                disabled={
                                                                                    !allowed
                                                                                }
                                                                            >
                                                                                Add
                                                                                Block{" "}
                                                                                <PlusOutlined
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "15px",
                                                                                    }}
                                                                                />
                                                                            </Button>
                                                                            <Button
                                                                                type="link"
                                                                                onClick={() => {
                                                                                    let tmpBlock =
                                                                                        cloneDeep(
                                                                                            block,
                                                                                        );
                                                                                    tmpBlock =
                                                                                        set(
                                                                                            tmpBlock,
                                                                                            worksheet?.type ===
                                                                                                "personalized_learning_v4"
                                                                                                ? [
                                                                                                      "children",
                                                                                                      cidx,
                                                                                                      "children",
                                                                                                      chunk
                                                                                                          ?.children
                                                                                                          ?.length ||
                                                                                                          0,
                                                                                                  ]
                                                                                                : [
                                                                                                      "children",
                                                                                                      cidx,
                                                                                                      "data",
                                                                                                      "chunk",
                                                                                                      "blocks",
                                                                                                      chunk
                                                                                                          .data[
                                                                                                          "chunk"
                                                                                                      ]
                                                                                                          .blocks
                                                                                                          .length,
                                                                                                  ],
                                                                                            {
                                                                                                tmpId: uuid(),
                                                                                                ...(worksheet?.type ===
                                                                                                "personalized_learning_v4"
                                                                                                    ? {}
                                                                                                    : {
                                                                                                          id: uuid(),
                                                                                                      }),
                                                                                                ...getV2StoriesDefaultValue(
                                                                                                    {
                                                                                                        logic: {
                                                                                                            compute_functions:
                                                                                                                BLOCK_COMPUTE_FUNCS,
                                                                                                        },
                                                                                                    },
                                                                                                    worksheet?.type,
                                                                                                ),
                                                                                            },
                                                                                        );
                                                                                    setBlock(
                                                                                        tmpBlock,
                                                                                        idx,
                                                                                    );
                                                                                    setCurrentBlock(
                                                                                        idx,
                                                                                    );
                                                                                    setCurrentSubBlock(
                                                                                        cidx,
                                                                                    );
                                                                                    setCurrentChunkBlock(
                                                                                        worksheet?.type ===
                                                                                            "personalized_learning_v4"
                                                                                            ? chunk
                                                                                                  ?.children
                                                                                                  ?.length ||
                                                                                                  0
                                                                                            : chunk
                                                                                                  .data[
                                                                                                  "chunk"
                                                                                              ]
                                                                                                  ?.blocks
                                                                                                  ?.length,
                                                                                    );
                                                                                }}
                                                                                disabled={
                                                                                    !allowed
                                                                                }
                                                                            >
                                                                                Add
                                                                                Story{" "}
                                                                                <PlusOutlined
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "15px",
                                                                                    }}
                                                                                />
                                                                            </Button>
                                                                        </div>
                                                                    </Panel>
                                                                );
                                                            },
                                                        )}
                                                    </Collapse>
                                                </SortableContainerCustom>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <Button
                                                        type="link"
                                                        onClick={() => {
                                                            let tmpBlock =
                                                                cloneDeep(
                                                                    block,
                                                                );
                                                            tmpBlock = set(
                                                                tmpBlock,
                                                                [
                                                                    "children",
                                                                    block
                                                                        .children
                                                                        .length,
                                                                ],
                                                                {
                                                                    tmpId: uuid(),
                                                                    ...DEFAULT_CHUNK_SCHEMA(
                                                                        worksheet?.type,
                                                                    ),
                                                                },
                                                            );
                                                            setBlock(
                                                                tmpBlock,
                                                                idx,
                                                            );
                                                            setCurrentBlock(
                                                                idx,
                                                            );
                                                            setCurrentSubBlock(
                                                                block.children
                                                                    .length,
                                                            );
                                                            setCurrentChunkBlock(
                                                                null,
                                                            );
                                                        }}
                                                        disabled={!allowed}
                                                    >
                                                        Add Chunk{" "}
                                                        <PlusOutlined
                                                            style={{
                                                                fontSize:
                                                                    "15px",
                                                            }}
                                                        />
                                                    </Button>
                                                </div>
                                            </Panel>
                                        );
                                    })}
                                </Collapse>
                            </SortableContainerCustom>

                            <div
                                style={{
                                    minWidth: "208px",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "10px",
                                }}
                            >
                                <Button
                                    type="link"
                                    onClick={() =>
                                        addBlock(
                                            null,
                                            blocks.length,
                                            V4_PROGRESS_LEVEL_DEFAULT_VALUE(
                                                worksheet?.type,
                                            ),
                                        )
                                    }
                                    disabled={!allowed}
                                >
                                    Add Level{" "}
                                    <PlusOutlined
                                        style={{
                                            fontSize: "15px",
                                        }}
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                </Card>
            )}
        </Can>
    );
};
