import {
    Card,
    Form,
    Row,
    Col,
    Input,
    Divider,
    Button,
    message,
    Switch,
} from "antd";
import _ from "lodash";
import { BLOCK_TYPES, DurationPicker } from "../../../common";
import { SlateEditor } from "src/components";
import { AudioGenerateComponent } from "src/modules/worksheet/components/WorksheetEditor/components/Modals/AudioGenerateComponent";
import FigmaStoryEditor from "../../Figma/renderEditor/FigmaStoryEditor";
import { useWorksheetState } from "src/modules/worksheet/components/contexts/WorksheetContext";

const { ALL_IN_ONE } = BLOCK_TYPES;

export const QuestionColumn = (props: any) => {
    const {
        block,
        setBlock,
        setIsEditor,
        mentionsList = [],
        hasMentions,
        disableWrite,
        currentEditor,
        setCurrentEditor,
        isReadOnlyMode,
        worksheet,
        previewData,
        renderLinkSelect,
    } = props;
    const {
        id,
        tmpId,
        data: {
            slug: dataSlug,
            [ALL_IN_ONE]: {
                badge_count: dataBadgeCount,
                logic: { variables = [] } = {},
                dev_remarks = [],
                generating_audio_config,
                duration_link,
            },
            other: { description = [], stories = [] } = {},
        },
        backend,
    } = block;

    let slug =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.slug || []
            : dataSlug;
    let badge_count =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.badge_count || []
            : dataBadgeCount;

    const { publishedBlocksMap = {} }: any = useWorksheetState();

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    const figmaStoryEditorProps = {
        ...props,
        disableWrite:
            disableWrite ||
            (!block?.id && worksheet?.type !== "personalized_learning_v4"),
        stories,
        storiesPath: [id || block.data?.storiesId, "data", "other", "stories"],
        setStories: (data: any, storiesId: any) => {
            let tmpBlock = _.cloneDeep(block);
            tmpBlock = _.set(tmpBlock, ["data", "other", "stories"], data);
            if (storiesId)
                tmpBlock = _.set(tmpBlock, ["data", "storiesId"], storiesId);
            setBlock(tmpBlock);
        },
        previewData: block?.id
            ? {
                  ...previewData,
                  variant_id: block?.id,
                  block_id: block?.id,
              }
            : null,
    };

    return (
        <Card
            style={{
                borderRadius: "8px",
                height: "85vh",
                border: "1px solid  rgba(0, 0, 0, 0.06)",
                overflow: "auto",
                scrollbarWidth: "none",
                marginLeft: "8px",
                flexGrow: 0,
                width: "100%",
            }}
            bordered={true}
            onClick={() => {
                setIsEditor(false);
            }}
            bodyStyle={{
                padding: "10px",
            }}
        >
            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
                gutter={[20, 20]}
            >
                <Col span={12}>
                    {worksheet?.type === "personalized_learning_v4" &&
                        block?.id && (
                            <Form.Item label="Block Id">
                                <Input.Search
                                    value={block.id}
                                    onChange={() => {}}
                                    enterButton="Copy"
                                    size="small"
                                    onSearch={() => {
                                        message.info(`Copied Id to clipboard!`);
                                        navigator.clipboard.writeText(block.id);
                                    }}
                                />
                            </Form.Item>
                        )}
                    <Form.Item>
                        <Button
                            disabled={disableWrite}
                            type="primary"
                            size="small"
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    JSON.stringify(block),
                                );
                                message.info(`Copied Question to clipboard!`);
                            }}
                        >
                            Copy the Question to Clipboard
                        </Button>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    {worksheet?.type === "personalized_learning_v4" &&
                        block?.id && (
                            <Form.Item label="Published Block Id">
                                {publishedBlocksMap[block.id] ? (
                                    <Input.Search
                                        value={publishedBlocksMap[block.id]}
                                        onChange={() => {}}
                                        enterButton="Copy"
                                        size="small"
                                        onSearch={() => {
                                            message.info(
                                                `Copied Id to clipboard!`,
                                            );
                                            navigator.clipboard.writeText(
                                                block.id,
                                            );
                                        }}
                                    />
                                ) : (
                                    <span>Not published Yet</span>
                                )}
                            </Form.Item>
                        )}
                    <Form.Item label="Slug">
                        <Input
                            value={slug}
                            disabled={disableWrite}
                            onChange={(e) => {
                                const value = e.target.value;
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    worksheet?.type ===
                                        "personalized_learning_v4"
                                        ? ["backend", "slug"]
                                        : ["data", "slug"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <h3
                        style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        Question
                    </h3>
                    <SlateEditor
                        disabled={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_description`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "other", "description"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={
                            currentEditor === `${id || tmpId}_description`
                        }
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={description}
                        placeholder={"Type your description here..."}
                    />
                </Col>
                <Col span={12}>
                    <Form.Item label="Duration Link">
                        {renderLinkSelect &&
                            renderLinkSelect({
                                style: { width: 200 },
                                value: duration_link
                                    ?.linked_global_context_variable?.name,
                                onChange: (value: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            ALL_IN_ONE,
                                            "duration_link",
                                            "linked_global_context_variable",
                                            "name",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                },
                            })}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <h3
                        style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        Dev Remarks
                    </h3>
                    <SlateEditor
                        disabled={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_remarks`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", ALL_IN_ONE, "dev_remarks"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={currentEditor === `${id || tmpId}_remarks`}
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={dev_remarks}
                        placeholder={"Type here..."}
                        required={false}
                    />
                </Col>
                <Col span={12}>
                    <h3>Background Audio</h3>
                    <AudioGenerateComponent
                        name={"Background Audio"}
                        onSave={(data: any) => {
                            setBlock({
                                ...block,
                                data: {
                                    ...block.data,
                                    other: {
                                        ...block.data.other,
                                        audio: data,
                                    },
                                },
                            });
                        }}
                        disableWrite={isReadOnlyMode}
                        audioData={block?.data?.other?.audio}
                    />
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Badge Count"
                        rules={[{ required: true }]}
                        validateStatus={
                            !badge_count || badge_count <= 0 ? "error" : ""
                        }
                        help={
                            !badge_count || badge_count <= 0
                                ? "Field cannot be empty"
                                : ""
                        }
                    >
                        <Input
                            disabled={disableWrite}
                            value={badge_count}
                            onChange={(e) => {
                                const value = e.target.value;
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    worksheet?.type ===
                                        "personalized_learning_v4"
                                        ? ["backend", "badge_count"]
                                        : ["data", ALL_IN_ONE, "badge_count"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            required
                            type="number"
                            placeholder={`Badge Count`}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Total Lives"
                        rules={[{ required: true }]}
                        validateStatus={
                            !(
                                variables?.find(
                                    (v: { name: string }) =>
                                        v.name === "total_lives",
                                )?.value > 0
                            )
                                ? "error"
                                : ""
                        }
                        help={
                            !(
                                variables?.find(
                                    (v: { name: string }) =>
                                        v.name === "total_lives",
                                )?.value > 0
                            )
                                ? "Field cannot be empty"
                                : ""
                        }
                    >
                        <Input
                            disabled={disableWrite}
                            value={
                                variables?.find(
                                    (v: { name: string }) =>
                                        v.name === "total_lives",
                                )?.value
                            }
                            onChange={(e) => {
                                const value = e.target.value;
                                let tmpBlock = _.cloneDeep(block);
                                let idx = variables?.findIndex(
                                    (v: { name: string }) =>
                                        v.name === "total_lives",
                                );
                                if (idx >= 0) {
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            ALL_IN_ONE,
                                            "logic",
                                            "variables",
                                            idx,
                                            "value",
                                        ],
                                        value,
                                    );
                                } else {
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            ALL_IN_ONE,
                                            "logic",
                                            "variables",
                                            variables?.length,
                                        ],
                                        {
                                            name: "total_lives",
                                            objectType: "NUMBER",
                                            value,
                                        },
                                    );
                                }
                                setBlock(tmpBlock);
                            }}
                            required
                            type="number"
                            placeholder={`Total Lives`}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Reset on try again?"
                        rules={[{ required: true }]}
                    >
                        <Switch
                            disabled={disableWrite}
                            checked={
                                variables?.find(
                                    (v: { name: string }) =>
                                        v.name === "is_reset",
                                )?.value
                            }
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);
                                let idx = variables?.findIndex(
                                    (v: { name: string }) =>
                                        v.name === "is_reset",
                                );
                                if (idx >= 0) {
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            ALL_IN_ONE,
                                            "logic",
                                            "variables",
                                            idx,
                                            "value",
                                        ],
                                        value,
                                    );
                                } else {
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            ALL_IN_ONE,
                                            "logic",
                                            "variables",
                                            variables?.length,
                                        ],
                                        {
                                            name: "is_reset",
                                            objectType: "BOOLEAN",
                                            value,
                                        },
                                    );
                                }
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <h3>Generating Audio Config</h3>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={"Audio"}
                        labelCol={{
                            span: 24,
                        }}
                    >
                        <AudioGenerateComponent
                            onSave={(data: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        ALL_IN_ONE,
                                        "generating_audio_config",
                                        "audio",
                                    ],
                                    data,
                                );
                                setBlock(tmpBlock);
                            }}
                            disableWrite={isReadOnlyMode}
                            audioData={generating_audio_config?.audio}
                        />
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <h3>Feedback</h3>
                    <Form.Item label={"Duration"}>
                        <Input
                            type="number"
                            disabled={disableWrite}
                            value={generating_audio_config?.feedback?.duration}
                            placeholder="Type here..."
                            onChange={(e) => {
                                let value = e.target.value;
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        ALL_IN_ONE,
                                        "generating_audio_config",
                                        "feedback",
                                        "duration",
                                    ],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label={"Text"}>
                        <SlateEditor
                            disabled={disableWrite}
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            id={`feedbackIdx_figma`}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        ALL_IN_ONE,
                                        "generating_audio_config",
                                        "feedback",
                                        "text",
                                    ],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            isFocused={currentEditor === `feedbackIdx_figma`}
                            setEditor={(id: string) => setCurrentEditor(id)}
                            value={
                                generating_audio_config?.feedback?.text || ""
                            }
                            placeholder={"Type here..."}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <h3>Duration</h3>
                    <DurationPicker
                        disabled={disableWrite}
                        onChange={onDurationChange}
                        initialValue={block?.data?.other?.duration || 0}
                    />
                </Col>
                <Divider />
                <Col span={24}>
                    <Form.Item
                        label={<h2>Stories</h2>}
                        labelCol={{
                            span: 24,
                        }}
                    >
                        <FigmaStoryEditor {...figmaStoryEditorProps} />
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    );
};
