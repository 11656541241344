import { FunctionComponent } from "react";

import {
    getLastStringFromUrl,
    getLastStringParamFromUrl,
    useHasuraSubscriptionWithCache,
    useHasuraSubscriptionWithFilter,
} from "src/helpers";

import {
    WORKSHEET_QUERY,
    WORKSHEETS_QUERY,
    WORKSHEET_AGGREGATE_QUERY,
    WORKSHEET_BLOCKS_QUERY,
    VERSION_MODEL_MAP_QUERY,
    WORKSHEET_STATS,
    STORIES_JOB,
} from "../graphql";

export const withHookForWorksheets = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { pagination } = props;
        const queryWorksheetResult = useHasuraSubscriptionWithCache(
            WORKSHEETS_QUERY,
            {
                variables: {
                    ...pagination,
                    group_id: getLastStringParamFromUrl(),
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryWorksheetResult,
            { queryName: "worksheets", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    worksheets: items,
                    subscribeToMore,
                    updateQuery,
                    loadWorksheetsData: loadItemsData,
                    refetchWorksheets: refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForAggregateWorksheet = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryWorksheetResult = useHasuraSubscriptionWithCache(
            WORKSHEET_AGGREGATE_QUERY,
            {
                variables: {
                    group_id: getLastStringParamFromUrl(),
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryWorksheetResult,
            { queryName: "worksheetAggregate", ...props },
        );
        const { loading, items, error, subscribeToMore, updateQuery } =
            graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    worksheetAggregate: items,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForWorksheet = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const queryWorksheetResult = useHasuraSubscriptionWithCache(
            WORKSHEET_QUERY,
            {
                variables: {
                    id: getLastStringFromUrl(),
                },
                fetchPolicy: "network-only",
            },
        );

        const { loading, error, data, subscribeToMore, updateQuery } =
            queryWorksheetResult;
        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingWorksheet: loading,
                    worksheet: data?.worksheet,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForWorksheetBlockMap = (Component: FunctionComponent) => {
    const WithComponent = (props: any) => {
        const {} = props;
        const queryWorksheetResult = useHasuraSubscriptionWithCache(
            WORKSHEET_BLOCKS_QUERY,
            {
                variables: {
                    worksheet_id: getLastStringFromUrl(),
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryWorksheetResult,
            { queryName: "worksheetBlockMap", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingWorksheetMap: loading,
                    worksheetBlockMap: items,
                    subscribeToMore,
                    updateQuery,
                    loadWorksheetData: loadItemsData,
                    refetchWorksheet: refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForVersionModelMap = (Component: FunctionComponent) => {
    const WithComponent = (props: any) => {
        const {} = props;
        const version = getLastStringParamFromUrl("version") || 0;

        const queryWorksheetResult = useHasuraSubscriptionWithCache(
            VERSION_MODEL_MAP_QUERY,
            {
                variables: {
                    model_name: "worksheet",
                    model_id: getLastStringFromUrl(),
                    version,
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryWorksheetResult,
            { queryName: "versionModelMap", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingVersionModelMap: loading,
                    versionModelMap: items,
                    subscribeToMore,
                    updateQuery,
                    loadVersionModelMapData: loadItemsData,
                    refetchVersionModelMap: refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForWorksheetsStats = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const queryWorksheetResult = useHasuraSubscriptionWithCache(
            WORKSHEET_STATS,
            {
                variables: {
                    worksheet_id: getLastStringFromUrl(),
                },
                fetchPolicy: "network-only",
            },
        );
        const {
            loading,
            error,
            data,
            // subscribeToMore, updateQuery
        } = queryWorksheetResult;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    worksheetStats: data?.worksheet_worksheet_stats[0],
                    worksheetStatsLoading: loading,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForWorksheetStories = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { worksheetStats } = props;
        if (worksheetStats?.other?.story_jobs) {
            const queryWorksheetResult = useHasuraSubscriptionWithCache(
                STORIES_JOB,
                {
                    variables: {
                        status: ["success", "failed"], // "ongoing"
                        type: "generate_stories",
                        id:
                            worksheetStats?.other?.story_jobs
                                ?.filter(
                                    (v: { job_id: any; is_sync: any }) =>
                                        v.job_id && !v.is_sync,
                                )
                                .map((v: { job_id: any }) => v.job_id) || [],
                    },
                    fetchPolicy: "network-only",
                },
            );
            const { loading = true, error, data } = queryWorksheetResult;

            if (error) throw new Error(error.message);
            return (
                <Component
                    {...props}
                    {...{
                        storyJobs: data?.other_job,
                        storyJobsLoading: loading,
                    }}
                />
            );
        }
        return (
            <Component
                {...props}
                {...{
                    storyJobsLoading: true,
                }}
            />
        );
    };
    return WithComponent;
};

export const withHookForWorksheetAudios = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { worksheetStats } = props;
        if (worksheetStats?.other?.audio_jobs) {
            const queryWorksheetResult = useHasuraSubscriptionWithCache(
                STORIES_JOB,
                {
                    variables: {
                        status: ["success", "failed"], // "ongoing"
                        type: "generate_audios",
                        id:
                            worksheetStats?.other?.audio_jobs
                                ?.filter((v: { is_sync: any }) => !v.is_sync)
                                .map((v: { job_id: any }) => v.job_id) || [],
                    },
                    fetchPolicy: "network-only",
                },
            );
            const { loading = true, error, data } = queryWorksheetResult;

            if (error) throw new Error(error.message);
            return (
                <Component
                    {...props}
                    {...{
                        audioJobs: data?.other_job,
                        audioJobsLoading: loading,
                    }}
                />
            );
        }
        return (
            <Component
                {...props}
                {...{
                    audioJobsLoading: true,
                }}
            />
        );
    };
    return WithComponent;
};
