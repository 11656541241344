import { useEffect, useState } from "react";
import { BlocksColumn } from "./BlocksColumn";
import { DataColumn } from "./DataColumn";

const MathaiEditor = ({ blocksColumnProps, dataColumnProps }: any) => {
    const [load, setLoad] = useState(false);

    const setCurrentChunkBlock = (v) => {
        setLoad(true);
        blocksColumnProps?.setCurrentChunkBlock(v);
    };

    useEffect(() => {
        if (load) {
            setTimeout(() => {
                setLoad(false);
            }, 50);
        }
    }, [load]);

    return (
        <div
            style={{
                paddingBottom: "10px",
                display: "flex",
                gap: "8px",
                overflowX: "auto",
                maxWidth: "100vw",
            }}
        >
            <div
                style={{
                    maxWidth: "300px",
                    flexShrink: 0,
                }}
            >
                <BlocksColumn
                    {...blocksColumnProps}
                    setCurrentChunkBlock={setCurrentChunkBlock}
                />
            </div>
            <div
                style={{
                    minWidth: "300px",
                    flexShrink: 1,
                    width: "100%",
                    flexGrow: 0,
                    maxHeight: "87vh",
                    overflowY: "scroll",
                    scrollbarWidth: "thin",
                }}
            >
                {!load && (
                    <DataColumn
                        {...dataColumnProps}
                        setCurrentChunkBlock={setCurrentChunkBlock}
                    />
                )}
            </div>
        </div>
    );
};

export { MathaiEditor };
