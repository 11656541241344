export const KEYMAP = {
    generic_cluster: "inputs_and_tags",
    drag_drop_category: "drag_drop_category",
    check_type: "check_type",
    feedback_type: "feedback_type",
    start_audio: "start_audio",
    end_audio: "end_audio",
    start_feedback: "start_feedback",
    end_feedback: "end_feedback",
    has_correctness_stroke: "has_correctness_stroke",
    has_highlight: "has_highlight",
    align_items: "align_items",
};

export const CLUSTER_KEYS = {
    generic_collection: "Input Cluster",
    tags_collection: "Tags Cluster",
};

export const COMPUTE_FUNCTION_MULTIINPUT = [
    "updateFeedbackType",
    "updateStory",
    "updateSimpleInput",
    "updateEndAudio",
    "updateStartAudio",
    "updateStartFeedback",
    "updateEndFeedback",
    "updateStartFeedbackPool",
    "updateIterCount",
];

export const COMPUTE_FUNC_INPUT = [
    "updateStory",
    "updateFeedbackType",
    "updateSimpleInput",
    "updateEndInputAudio",
    "updateStartInputAudio",
    "updateEndAudio",
    "updateStartAudio",
    "setMathQuillInputAudio",
    "evaluateMathQuillValues",
    "checkCorrectAnswers",
    "updateStartFeedback",
    "updateEndFeedback",
    "updateStartInputFeedback",
    "updateEndInputFeedback",
    "updateStartFeedbackPool",
    "updateEndFeedbackPool",
];
