import React from "react";
import _ from "lodash";
import { Form, Input, Button } from "antd";
import { SlateEditor } from "src/components";

const TableDropdownOptionsEditor = (props: any) => {
    const {
        blockData,
        setBlock,
        disableWrite,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
    } = props;
    const options = blockData?.dropdown?.options || [];
    return (
        <>
            {options.map(
                (
                    dropOp: {
                        label: any;
                        value: string;
                    },
                    idx: number,
                ) => (
                    <Form.Item
                        label={`Option ${idx + 1}`}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                        }}
                    >
                        <SlateEditor
                            disabled={disableWrite}
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            id={`${idx}_drop_label`}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(blockData);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["dropdown", "options", idx, "label"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            isFocused={currentEditor === `${idx}_drop_label`}
                            setEditor={(id: string) => setCurrentEditor(id)}
                            value={dropOp?.label}
                            placeholder={"Label"}
                        />
                        <Input
                            placeholder="Value"
                            value={dropOp?.value}
                            disabled={disableWrite}
                            onChange={(e) => {
                                const value = e.target.value;
                                let tmpBlock = _.cloneDeep(blockData);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["dropdown", "options", idx, "value"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                        <Button
                            ghost
                            danger
                            type="primary"
                            onClick={() => {
                                let tmpBlock = _.cloneDeep(blockData);
                                const tmpOptions = [...options];
                                _.pullAt(tmpOptions, idx);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["dropdown", "options"],
                                    tmpOptions,
                                );

                                setBlock(tmpBlock);
                            }}
                        >
                            Delete
                        </Button>
                    </Form.Item>
                ),
            )}
            <Button
                onClick={() => {
                    let tmpBlock = _.cloneDeep(blockData);

                    tmpBlock = _.set(
                        tmpBlock,
                        ["dropdown", "options", options.length],
                        {
                            label: "",
                            value: "",
                        },
                    );
                    setBlock(tmpBlock);
                }}
            >
                Add Option
            </Button>
        </>
    );
};

export default TableDropdownOptionsEditor;
