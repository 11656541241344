import {
    CheckOutlined,
    CloseOutlined,
    HomeOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import {
    Affix,
    Breadcrumb,
    Button,
    Card,
    Col,
    Form,
    Modal,
    Popover,
    Row,
    Switch,
    Typography,
} from "antd";
import { Link } from "src/components";
import { SelectSheetsModal, SharePopup, WorksheetFormPopup } from "../Modals";
import { Can } from "src/services/casl";
import ActiveUsersModal from "../Modals/ActiveUsersModal";
import { useUserData } from "src/helpers/supertokens";
import PublishModal from "./PublishModal";
import { useState } from "react";
import TimeAgo from "react-timeago";

export const Navbar = ({
    setIsEditor,
    router,
    props,
    lastSavedAt,
    currentDateTime,
    version,
    onSave,
    isSaving,
    blocks,
    hasChanged,
    worksheet_id,
    worksheet,
    gcpSpreadsheetURL,
    selectSheetsFlow,
    toggleSelectSheetsFlow,
    setGcpSpreadsheetURL,
    publishWorksheet,
    canPublishFinal,
    isReadOnlyMode,
    activeUserProps = {},
    worksheetStats,
    currentSavedCount,
}: any) => {

    const { user } = useUserData();
    const user_role = user?.role;
    const currentUserId = user?.user_id;
    const [isOpen, setIsOpen] = useState(false);
    const [publish, setPublish] = useState(false);
    const [generateAudio, setGenerateAudio] = useState(false);

    const handleClose = async () => {
        setPublish(false);
        setIsOpen(false);
        setGenerateAudio(false);
    };

    const isRead =
        worksheet?.type === "personalized_learning_v4" &&
        activeUserProps?.activeUsers?.length &&
        activeUserProps?.activeUsers.every(
            (item: { user_id: any; is_editor: any }) =>
                item?.user_id !== currentUserId ||
                (item?.user_id === currentUserId && !item?.is_editor),
        );

    const currentUser = activeUserProps?.activeUsers?.find(
        (user) => user.is_editor,
    );
    return (
        <Affix>
            <div
                style={{
                    width: "100%",
                }}
            >
                <Modal
                    open={isOpen}
                    title={
                        currentUser?.user_id != currentUserId
                            ? "Are you sure?"
                            : "Save Worksheet"
                    }
                    okText={publish ? "Publish" : "Save"}
                    onOk={() => {
                        handleClose();
                        if (
                            (worksheet?.type === "personalized_learning_v3" &&
                                blocks?.some(
                                    (v) =>
                                        !v?.id ||
                                        v?.children?.some((vc) => !vc?.id),
                                )) ||
                            publish
                        )
                            publishWorksheet({});
                        else {
                            onSave({
                                isSaveButton: true,
                                generate: generateAudio,
                            });
                        }
                    }}
                    onCancel={handleClose}
                >
                    {currentUser?.user_id != currentUserId && (
                        <Typography.Text>
                            You currently don't have edit access to this
                            worksheet.
                        </Typography.Text>
                    )}
                    {worksheet?.type === "personalized_learning_v4" && (
                        <Form.Item label={`Generate Audio?`}>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={generateAudio}
                                onChange={setGenerateAudio}
                            />
                        </Form.Item>
                    )}
                </Modal>
                {/* {!navigator.onLine && (
                    <div
                        style={{
                            background: "red",
                            width: "100%",
                            padding: "5px 10px",
                            textAlign: "center",
                            fontSize: "16px",
                            color: "white",
                            fontWeight: 900,
                        }}
                    >
                        No Internet!
                    </div>
                )} */}
                {worksheet?.type === "personalized_learning_v4" &&
                    // currentSavedCount > 0 &&
                    worksheetStats?.other?.save_count >
                    (currentSavedCount || 0) && (
                        <div
                            style={{
                                background: "red",
                                width: "100%",
                                padding: "5px 10px",
                                textAlign: "center",
                                fontSize: "16px",
                                color: "white",
                                fontWeight: 900,
                            }}
                        >
                            Save Version mis-match. Please refresh!
                        </div>
                    )}
                {worksheet?.type === "personalized_learning_v4" && !!isRead && (
                    <div
                        style={{
                            background: "red",
                            width: "100%",
                            padding: "5px 10px",
                            textAlign: "center",
                            fontSize: "16px",
                            color: "white",
                            fontWeight: 900,
                        }}
                    >
                        Someone else is the current editor!
                    </div>
                )}
                <Row
                    justify="center"
                    align="middle"
                    style={{
                        padding: "10px 10px 0 10px",
                        background: "#F7F7F7",
                    }}
                >
                    <Col xs={24} lg={24} md={24}>
                        <Card
                            style={{
                                borderRadius: "8px",
                                marginBottom: "8px",
                            }}
                            bordered={true}
                            bodyStyle={{ padding: "0px 12px" }}
                            onClick={() => {
                                setIsEditor(false);
                            }}
                        >
                            <Row align="middle" gutter={4}>
                                <Col xs={8} lg={8} md={8}>
                                    <Breadcrumb style={{ margin: "16px 10px" }}>
                                        <Link href={"/"} passHref>
                                            <Breadcrumb.Item>
                                                <HomeOutlined />
                                            </Breadcrumb.Item>
                                        </Link>

                                        <Breadcrumb.Item
                                            onClick={() =>
                                                router.push(props.redirect_to)
                                            }
                                        >
                                            <span
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                            >
                                                Chapters
                                            </span>
                                        </Breadcrumb.Item>

                                        <WorksheetFormPopup
                                            {...props}
                                            isReadOnlyMode={isReadOnlyMode}
                                        />
                                    </Breadcrumb>
                                </Col>
                                <Col xs={4} lg={4} md={4}>
                                    {worksheet?.type !==
                                        "personalized_learning_v4" ? (
                                        lastSavedAt &&
                                        currentDateTime && (
                                            <p style={{ color: "#808080" }}>
                                                (v{version}) Last saved (
                                                {Math.round(
                                                    Math.abs(
                                                        currentDateTime.getTime() -
                                                        lastSavedAt.getTime(),
                                                    ) / 1000,
                                                )}{" "}
                                                seconds ago)
                                            </p>
                                        )
                                    ) : (
                                        <div>
                                            {worksheetStats?.other
                                                ?.current_save && (
                                                    <div>
                                                        Last saved:
                                                        <TimeAgo
                                                            date={
                                                                worksheetStats
                                                                    ?.other
                                                                    ?.current_save
                                                                    ?.time
                                                            }
                                                        />
                                                        <Popover
                                                            placement="bottom"
                                                            title={
                                                                "Save & Publish Details"
                                                            }
                                                            content={
                                                                <>
                                                                    <p>
                                                                        Last saved:{" "}
                                                                        <strong>
                                                                            {new Date(
                                                                                worksheetStats?.other?.current_save?.time,
                                                                            ).toLocaleString(
                                                                                "en-IN",
                                                                                {
                                                                                    timeZone:
                                                                                        "Asia/Kolkata",
                                                                                    year: "numeric",
                                                                                    month: "long",
                                                                                    day: "numeric",
                                                                                    hour: "2-digit",
                                                                                    minute: "2-digit",
                                                                                    second: "2-digit",
                                                                                    hour12: true,
                                                                                },
                                                                            )}
                                                                        </strong>{" "}
                                                                        by{" "}
                                                                        {worksheetStats
                                                                            ?.other
                                                                            ?.current_save
                                                                            ?.user
                                                                            ?.name ||
                                                                            worksheetStats
                                                                                ?.other
                                                                                ?.current_save
                                                                                ?.user
                                                                                ?.email}
                                                                    </p>
                                                                    {worksheetStats
                                                                        ?.other
                                                                        ?.current_publish && (
                                                                            <p>
                                                                                Last
                                                                                published:{" "}
                                                                                <strong>
                                                                                    {new Date(
                                                                                        worksheetStats?.other?.current_publish?.time,
                                                                                    ).toLocaleString(
                                                                                        "en-IN",
                                                                                        {
                                                                                            timeZone:
                                                                                                "Asia/Kolkata",
                                                                                            year: "numeric",
                                                                                            month: "long",
                                                                                            day: "numeric",
                                                                                            hour: "2-digit",
                                                                                            minute: "2-digit",
                                                                                            second: "2-digit",
                                                                                            hour12: true,
                                                                                        },
                                                                                    )}
                                                                                </strong>{" "}
                                                                                by{" "}
                                                                                {worksheetStats
                                                                                    ?.other
                                                                                    ?.current_publish
                                                                                    ?.user
                                                                                    ?.name ||
                                                                                    worksheetStats
                                                                                        ?.other
                                                                                        ?.current_publish
                                                                                        ?.user
                                                                                        ?.email}
                                                                            </p>
                                                                        )}
                                                                </>
                                                            }
                                                        >
                                                            <Button
                                                                type="link"
                                                                icon={
                                                                    <InfoCircleOutlined />
                                                                }
                                                            />
                                                        </Popover>
                                                    </div>
                                                )}
                                            {worksheetStats?.other
                                                ?.current_publish && (
                                                    <div>
                                                        Last published:
                                                        <TimeAgo
                                                            date={
                                                                worksheetStats
                                                                    ?.other
                                                                    ?.current_publish
                                                                    ?.time
                                                            }
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    )}
                                </Col>
                                <Col xs={3} lg={3} md={3}>
                                    <Can I="save" a={"worksheet"} passThrough>
                                        {(allowed: boolean) => (
                                            <ActiveUsersModal
                                                {...activeUserProps}
                                                disabled={!allowed}
                                                isRead={isRead}
                                            />
                                        )}
                                    </Can>
                                </Col>
                                <Col xs={2} lg={2} md={2}>
                                    <Can I="save" a={"worksheet"} passThrough>
                                        {(allowed: boolean) => (
                                            <Button
                                                onClick={(e) => {
                                                    if (
                                                        currentUser?.user_id !=
                                                        currentUserId ||
                                                        worksheet?.type ===
                                                        "personalized_learning_v4"
                                                    ) {
                                                        setIsOpen(true);
                                                    } else {
                                                        if (
                                                            worksheet?.type ===
                                                            "personalized_learning_v3" &&
                                                            blocks?.some(
                                                                (v) =>
                                                                    !v?.id ||
                                                                    v?.children?.some(
                                                                        (vc) =>
                                                                            !vc?.id,
                                                                    ),
                                                            )
                                                        )
                                                            publishWorksheet(e);
                                                        else
                                                            onSave({
                                                                ...e,
                                                                isSaveButton:
                                                                    true,
                                                                // generate: true,
                                                            });
                                                    }
                                                }}
                                                block
                                                loading={isSaving}
                                                disabled={
                                                    !blocks?.length ||
                                                    !hasChanged ||
                                                    isReadOnlyMode ||
                                                    !allowed
                                                }
                                            >
                                                {isSaving ? "Saving" : "Save"}
                                            </Button>
                                        )}
                                    </Can>
                                </Col>
                                <Col xs={2} lg={2} md={2}>
                                    <a
                                        rel="noreferrer"
                                        href={
                                            [
                                                "personalized_learning_v3",
                                                "personalized_learning_v4",
                                            ].includes(worksheet?.type)
                                                ? `${process.env.REACT_APP_CLIENT_PREVIEW}/home/worksheet/${worksheet?.id}`
                                                : `/worksheet/preview/${worksheet_id}`
                                        }
                                        target="_blank"
                                    >
                                        <Button
                                            type="dashed"
                                            block
                                            disabled={
                                                isSaving || !blocks?.length
                                            }
                                        >
                                            Preview
                                        </Button>
                                    </a>
                                </Col>
                                <Col xs={2} lg={2} md={2}>
                                    <SharePopup
                                        blocks={blocks}
                                        isSaving={isSaving}
                                        worksheet_id={worksheet_id}
                                        version={version || 0}
                                        worksheet={worksheet}
                                        onFinish={props.onFinish}
                                        gcpSpreadsheetId={gcpSpreadsheetURL}
                                        isReadOnlyMode={isReadOnlyMode}
                                    />
                                    <SelectSheetsModal
                                        selectSheetsFlow={selectSheetsFlow}
                                        toggleSelectSheetsFlow={
                                            toggleSelectSheetsFlow
                                        }
                                        worksheet={worksheet}
                                        setGcpSpreadsheetURL={
                                            setGcpSpreadsheetURL
                                        }
                                        gcpSpreadsheetId={gcpSpreadsheetURL}
                                        isReadOnlyMode={isReadOnlyMode}
                                    />
                                </Col>

                                <Col xs={3} lg={3} md={3}>
                                    <Can
                                        I="publish"
                                        a={"worksheet"}
                                        passThrough
                                    >
                                        {(allowed: boolean) =>
                                            worksheet?.type ===
                                                "personalized_learning_v4" ? (
                                                <PublishModal
                                                    isRead={isRead}
                                                    blocks={blocks}
                                                    disableWrite={
                                                        isSaving ||
                                                        canPublishFinal ||
                                                        (!allowed &&
                                                            !(
                                                                [
                                                                    "personalized_learning_v3",
                                                                    "personalized_learning_v4",
                                                                ].includes(
                                                                    worksheet?.type,
                                                                ) &&
                                                                user_role ===
                                                                "creator"
                                                            )) ||
                                                        isReadOnlyMode
                                                    }
                                                    publishWorksheet={
                                                        publishWorksheet
                                                    }
                                                />
                                            ) : (
                                                <Button
                                                    type={"primary"}
                                                    onClick={() => {
                                                        const currentUser =
                                                            activeUserProps.activeUsers.find(
                                                                (user) =>
                                                                    user.is_editor,
                                                            );
                                                        if (
                                                            currentUser?.user_id !=
                                                            currentUserId
                                                        ) {
                                                            setPublish(true);
                                                            setIsOpen(true);
                                                        } else {
                                                            publishWorksheet();
                                                        }
                                                    }}
                                                    block
                                                    disabled={
                                                        canPublishFinal ||
                                                        (!allowed &&
                                                            !(
                                                                [
                                                                    "personalized_learning_v3",
                                                                    "personalized_learning_v4",
                                                                ].includes(
                                                                    worksheet?.type,
                                                                ) &&
                                                                user_role ===
                                                                "creator"
                                                            )) ||
                                                        isReadOnlyMode
                                                    }
                                                >
                                                    {worksheet?.status ==
                                                        "active"
                                                        ? "Publish Changes"
                                                        : "Publish"}
                                                </Button>
                                            )
                                        }
                                    </Can>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Affix>
    );
};
