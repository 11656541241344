import CancelIcon from "src/components/CancelIcon";
import { Form, Input, Button } from "antd";
import { useEffect, useRef, useState } from "react";
const { TextArea } = Input;
const FormItem = Form.Item;

export const VideoInsertComponent = (props: any) => {
    const { onChange, onDelete, value } = props;
    const inputRef = useRef<any>();

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    // Solving Caret Jumping in React Inputs
    const [val, setVal] = useState(value);
    const updateVal = (val: any) => {
        /* Make update synchronous, to avoid caret jumping when the value doesn't change asynchronously */
        setVal(val);
        /* Make the real update afterwards */
        onChange(val);
    };

    return (
        <div style={{ display: "flex" }}>
            <Form layout={"inline"} component={false}>
                <FormItem
                    className="video-insert-form-item"
                    style={{ width: "80%" }}
                >
                    <TextArea
                        placeholder="type/paste video"
                        value={val}
                        onChange={(e) => updateVal(e.target.value)}
                        ref={inputRef}
                        autoSize
                    />
                </FormItem>

                {/* <FormItem className="video-insert-form-item">
          <Button
            type="primary"
            shape="circle"
            icon={<Save />}
            style={{ padding: "2px" }}
            onClick={() => {onChange(video);}}
          />
        </FormItem> */}

                <FormItem
                    className="video-insert-form-item"
                    style={{ marginLeft: "10px" }}
                >
                    <CancelIcon
                        isButton={true}
                        onClick={() => {
                            onDelete();
                        }}
                    />
                </FormItem>
            </Form>
        </div>
    );
};
