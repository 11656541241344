import React, { useState } from "react";
import { Button, Divider, Form, Input, Modal, Select, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { SlateEditor } from "src/components";
import { cloneDeep, pullAt, set } from "lodash";
import { renderer } from "src/modules/worksheet/components/blocks/helpers";

const DropdownCell = (props: any) => {
    const {
        isModalOpen,
        setModalOpen,
        inputCell,
        handleSubmit,
        currentEditor,
        setCurrentEditor,
        disableWrite,
        hasMentions,
        mentionsList,
    } = props;
    const [currentCell, setCurrentCell] = useState(inputCell);

    return (
        <Modal
            title={"Dropdown"}
            onCancel={() => setModalOpen(false)}
            open={isModalOpen}
            destroyOnClose
            width={600}
            okText="Submit"
            maskClosable={false}
            onOk={() => {
                handleSubmit(currentCell);
                setModalOpen(false);
            }}
        >
            <Form.Item label="Enabled?">
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={currentCell?.enabled}
                    onChange={(value) =>
                        setCurrentCell({
                            ...currentCell,
                            enabled: value,
                            type: "latex",
                        })
                    }
                />
            </Form.Item>
            {currentCell?.enabled && (
                <>
                    {/* <Form.Item label="Input Type">
                        <Select
                            value={currentCell?.type}
                            onChange={(value) =>
                                setCurrentCell({
                                    ...currentCell,
                                    type: value,
                                })
                            }
                        >
                            <Select.Option value="normal">Normal</Select.Option>
                            <Select.Option value="latex">Latex</Select.Option>
                        </Select>
                    </Form.Item> */}
                    {(currentCell?.options || []).map(
                        (
                            dropOp: {
                                label: any;
                                value: string;
                            },
                            idx: number,
                        ) => (
                            <Form.Item
                                label={`Option ${idx + 1}`}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "20px",
                                }}
                            >
                                <SlateEditor
                                    disabled={disableWrite}
                                    hasMentions={hasMentions}
                                    mentionsList={mentionsList}
                                    id={`${idx}_drop_label`}
                                    onChange={(value: any) => {
                                        let tmpBlock = cloneDeep(currentCell);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["options", idx, "label"],
                                            value,
                                        );
                                        setCurrentCell(tmpBlock);
                                    }}
                                    isFocused={
                                        currentEditor === `${idx}_drop_label`
                                    }
                                    setEditor={(id: string) =>
                                        setCurrentEditor(id)
                                    }
                                    value={dropOp?.label}
                                    placeholder={"Label"}
                                />
                                <Input
                                    placeholder="Value"
                                    value={dropOp?.value}
                                    disabled={disableWrite}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        let tmpBlock = cloneDeep(currentCell);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["options", idx, "value"],
                                            value,
                                        );
                                        setCurrentCell(tmpBlock);
                                    }}
                                />
                                <Button
                                    ghost
                                    danger
                                    type="primary"
                                    onClick={() => {
                                        let tmpBlock = cloneDeep(currentCell);
                                        const tmpOptions = [
                                            ...(currentCell?.options || []),
                                        ];
                                        pullAt(tmpOptions, idx);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["options"],
                                            tmpOptions,
                                        );

                                        setCurrentCell(tmpBlock);
                                    }}
                                >
                                    Delete
                                </Button>
                            </Form.Item>
                        ),
                    )}
                    <Button
                        disabled={disableWrite}
                        onClick={() => {
                            let tmpBlock = cloneDeep(currentCell);

                            tmpBlock = set(
                                tmpBlock,
                                ["options", currentCell.options?.length || 0],
                                {
                                    label: "",
                                    value: "",
                                },
                            );
                            setCurrentCell(tmpBlock);
                        }}
                    >
                        Add Option
                    </Button>
                    <Divider />
                    <Form.Item label="Default Value">
                        <Select
                            disabled={disableWrite}
                            value={currentCell?.default}
                            onChange={(value) => {
                                setCurrentCell({
                                    ...currentCell,
                                    default: value,
                                });
                            }}
                        >
                            {(currentCell?.options || [])?.map(
                                (option: any, idx: number) => (
                                    <Select.Option
                                        key={idx}
                                        value={option.value}
                                    >
                                        {renderer(option.label)}
                                    </Select.Option>
                                ),
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Placeholder">
                        <Input
                            value={currentCell.placeholder}
                            placeholder="Placeholder"
                            onChange={(e: any) => {
                                setCurrentCell({
                                    ...currentCell,
                                    placeholder: e.target.value,
                                });
                            }}
                        />
                    </Form.Item>
                </>
            )}
        </Modal>
    );
};

export default DropdownCell;
