import React, { useEffect, useMemo, useState } from "react";
import InputComp from "./mathInput";
// import { LatexInput as LatexInputComp } from "./latexInput";
// import MathQuillRenderer from "./mathQuillRenderer";
import { getBackgroundColor } from "./helpers";

const normalInputStyle = {
    maxWidth: "260px",
    height: "auto",
    borderRadius: "2px",
    border: "0.5px solid #4F4F4F",
    background: "#F9F8F8",
    width: "auto",
};

const normalInputFontStyle = {
    color: "#828282",
    fontFamily: "Epilogue",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "200",
    lineHeight: "150%", // This will effectively be 24px if the font size is 16px
    height: "56px",
    minHeight: "56px",
    flexGrow: 0,
    width: "auto",
    padding: "15px 8px",
};

const otherInputStyle = {
    maxWidth: "260px",
    height: "auto",
    borderRadius: "2px",
    border: "0.5px solid #4F4F4F",
    background: "#F9F8F8",
    width: "auto",
};

const otherInputFontStyle = {
    color: "#828282",
    fontFamily: "Epilogue",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "200",
    lineHeight: "150%", // This will effectively be 24px if the font size is 16px
    height: "56px",
    minHeight: "56px",
    flexGrow: 0,
    width: "auto",
    padding: "15px 8px",
};

const getNormalInputUpdatedStyles = (inputObj) => {
    const dimensionControl = {
        enabled: inputObj?.dimensionControl?.enabled,
        minWidth: Number(inputObj?.dimensionControl?.minWidth),
        minHeight: Number(inputObj?.dimensionControl?.minHeight),
        fontSize: 16,
    };
    if (dimensionControl?.enabled) {
        const newPaddingVert =
            (dimensionControl.minHeight - dimensionControl.fontSize) / 2;

        const newPadding = `${newPaddingVert}px 8px`;
        return {
            normalInputStyle: {
                ...normalInputStyle,
            },
            normalInputFontStyle: {
                ...normalInputFontStyle,
                padding: newPadding,
                minHeight: dimensionControl.minHeight,
                height: dimensionControl.minHeight,
                minWidth: dimensionControl.minWidth,
                width: dimensionControl.minWidth,
            },
        };
    } else {
        return {
            normalInputStyle,
            normalInputFontStyle,
        };
    }
};

const InputComponent = ({ inputObj }) => {
    const [inputValue, setInputValue] = useState("");
    const [defaultValueSet, setDefaultValueSet] = useState(false);

    useEffect(() => {
        if (defaultValueSet) return;
        setInputValue(inputObj?.value);
        setDefaultValueSet(true);
    }, [defaultValueSet]);

    const { type } = inputObj;

    const { normalInputStyle, normalInputFontStyle } = useMemo(() => {
        return getNormalInputUpdatedStyles(inputObj);
    }, []);

    return (
        <div className="renderer-table-input-wrapper">
            {(type === "text" || type === "number") && (
                <InputComp
                    style={{
                        ...normalInputStyle,
                        backgroundColor: getBackgroundColor(
                            inputObj?.fillIndex || 0,
                        ),
                    }}
                    fontStyle={{ ...normalInputFontStyle, fontWeight: 600 }}
                    initialValue={inputObj.value || inputObj.default || ""}
                    placeholder={inputObj.placeholder || "Type here.."}
                    isTextarea={!inputObj?.singleLine}
                    value={
                        inputValue !== "" && inputValue !== undefined
                            ? inputValue
                            : null
                    }
                />
            )}
            {type === "latex" && (
                <InputComp
                    style={{
                        ...normalInputStyle,
                        backgroundColor: getBackgroundColor(
                            inputObj?.fillIndex || 0,
                        ),
                    }}
                    fontStyle={{ ...normalInputFontStyle, fontWeight: 600 }}
                    initialValue={inputObj.value || inputObj.default || ""}
                    placeholder={inputObj.placeholder || "Type here.."}
                    isTextarea={!inputObj?.singleLine}
                    value={
                        inputValue !== "" && inputValue !== undefined
                            ? inputValue
                            : null
                    }
                />
            )}
            {/* {type === "latex-fill-in-blank" && (
                <MathQuillRenderer
                    inputData={{
                        customKeyboard: inputObj.keyboard,
                        fontData: {
                            fontStyleData: {
                                ...otherInputFontStyle,
                                height: "fit-content",
                                fontWeight: 600,
                                fontColor: "black",
                            },
                            ...otherInputStyle,
                            backgroundColor: getBackgroundColor(
                                inputObj?.fillIndex || 0,
                            ),
                            position: "relative",
                        },
                        clickedPosition: inputObj.clickedPosition,
                        input_props: {
                            is_editable: { value: true },
                            bg_index: { value: inputObj?.fillIndex || 0 },
                            feedback_data: {
                                value:
                                    inputObj.config.fillIndices?.map(
                                        (fillIndice) => {
                                            return {
                                                backgroundColor:
                                                    getBackgroundColor(
                                                        fillIndice,
                                                    ),
                                                // is_correct: fillIndice?.is_correct || false,
                                            };
                                        },
                                    ) || [],
                            },
                        },
                        value: inputObj?.config?.latex || "",
                    }}
                    updateDimensions={() => {}}
                />
            )} */}
        </div>
    );
};

export default InputComponent;
