import React, { useState } from "react";
import { Row, Col, Form, Button } from "antd";
import { withFormik } from "formik";
import FormikPersist from "src/components/FormikPersist";
import {
    Field,
    RenderCheckBox,
    RenderField,
    RenderSelect,
    RenderUpload,
} from "src/components";
import { Select } from "antd";
import { useUserData } from "src/helpers/supertokens";

import { useRouter } from "src/helpers";
import * as yup from "yup";

const Option = Select.Option;

const subjects = [
    "Maths",
    "Science",
    "Physics",
    "Biology",
    "Chemistry",
    "Environmental Studies",
    "English",
    "Social Studies",
    "Social Science",
    "Hindi",
];

const classNames = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
];

const ChapterForm = (props) => {
    const {
        onFinishFailed,
        values,
        handleSubmit,
        isSubmitting,
        disableHasTopics,
    } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);

    const [loading, setLoading] = useState(false);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={24} md={24}>
                    <Field
                        label="Chapter Title"
                        placeholder="Chapter Title"
                        name={"title"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.title}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Field
                        label="Chapter Banner"
                        placeholder="Chapter Banner"
                        name={"cover"}
                        icon="FontSizeOutlined"
                        component={RenderUpload}
                        type="textarea"
                        value={values.cover}
                        rules={[{ required: true }]}
                        path="worksheet/"
                        setLoad={setLoading}
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Field
                        label="Homepage Banner"
                        placeholder="Homepage Banner"
                        name={"other.homepage_banner"}
                        icon="FontSizeOutlined"
                        component={RenderUpload}
                        value={values.other.homepage_banner}
                        rules={[{ required: true }]}
                        path="worksheet/"
                        setLoad={setLoading}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Chapter Description"
                        placeholder="Chapter Description"
                        name={"description"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.description}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Chapter Number"
                        placeholder="Chapter Number"
                        name={"other.chapter_number"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.other.chapter_number}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Chapter Has Topics?"
                        placeholder="Chapter Has Topics?"
                        name={"other.has_topics"}
                        icon="FontSizeOutlined"
                        component={RenderCheckBox}
                        checked={values.other.has_topics}
                        rules={[{ required: true }]}
                        disabled={disableHasTopics}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Chapter is locked?"
                        placeholder="Chapter is locked?"
                        name={"other.is_locked"}
                        icon="FontSizeOutlined"
                        component={RenderCheckBox}
                        checked={values.other.is_locked}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Chapter Status"
                        placeholder="Chapter Status"
                        name={"status"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.status}
                        rules={[{ required: true }]}
                    >
                        <Option value="inactive">Inactive</Option>
                        <Option value="active">Active</Option>
                    </Field>
                </Col>

                <Col lg={12} md={24}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isSubmitting || loading}
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export const FormSchema = yup.object().shape({
    title: yup.string().required("This field is required"),
    description: yup.string().required("This field is required"),
    cover: yup.string().required("This field is required"),
    type: yup.string().required("This field is required"),
    status: yup.string().required("This field is required"),
    parent_id: yup.number().required("This field is required"),
    other: yup.object().shape({
        chapter_number: yup.number().required("This field is required"),
    }),
});

const ChapterFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { chapter, book_id } = props;

        return {
            ...(chapter?.id ? { id: chapter.id } : {}),
            title: chapter?.title || "",
            description: chapter?.description || "",
            cover: chapter?.cover || "",
            // other
            other: {
                set_count: chapter?.other?.set_count || 0,
                chapter_number: chapter?.other?.chapter_number,
                has_topics: chapter?.other?.has_topics || false,
                is_locked: chapter?.other?.is_locked || false,
                homepage_banner: chapter?.other?.homepage_banner || "",
            },

            status: chapter?.status || "inactive",
            type: "chapter",
            parent_id: parseInt(book_id),
            // created_by: props.user_id,
        };
    },
    validationSchema: FormSchema,

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish(values);
        // console.log(values)
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

const FormikForm = ChapterFormWithFormik(ChapterForm);
const FormikFormWithUser = (props) => {
    const { query } = useRouter();
    const { book_id } = query;

    const { user } = useUserData();

    const namespace = "https://hasura.io/jwt/claims/";
    const user_id = user?.user_id;
    return <FormikForm {...props} user_id={user_id} book_id={book_id} />;
};

export default FormikFormWithUser;
