import React, { useState } from "react";
import { Spin, Select, message } from "antd";

import { compose, config, resetFilterHook } from "src/helpers";
import { Button, Field, RenderSelect } from "src/components";

import {
    withCreateTag,
    withHookForTags,
    withTags,
} from "src/modules/book/operations";
import get from "lodash/get";

import { withStateAndActionsForTag } from "../resolvers/tag";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { Can } from "src/services/casl";
import { captureException } from "@sentry/react";

const Option = Select.Option;

const TagsField = (props) => {
    const {
        loading,
        tags,
        where,
        onNameChange,
        onChange,
        value,
        onStateReset,
        dropdownOpen,
        addTag,
    } = props;
    const [addLoading, setAddLoading] = useState(false);
    const [searchValue, setSearchValue] = useState(null);

    const handleAdd = async () => {
        setAddLoading(true);
        const { formik, name, tagGroup } = props;
        // const val = get(where, ['title', '_ilike'])?.replace(/%/g, '');
        try {
            if (!addLoading /* && val */ && searchValue) {
                const values = searchValue?.split(":");
                if (values.length == 2) {
                    const res = await addTag({
                        group: tagGroup,
                        name: values[0].trim(),
                        value: values[1].trim(),
                    });
                    res.id &&
                        formik.setFieldValue(name, {
                            data: [
                                ...value.map((v) => ({
                                    tag_id: v.value,
                                    label: v.label,
                                })),
                                {
                                    tag_id: res.id,
                                    label: `${res.value} (${res.name})` || "",
                                },
                            ],
                        });
                    res.id && setSearchValue("");
                    res.id && onNameChange("");
                } else alert("Format should be -> name:value");
                setAddLoading(false);
            }
        } catch (e) {
            captureException(e)
            setAddLoading(false);
        }
    };

    resetFilterHook(onStateReset);

    return (
        <Field
            labelInValue
            showSearch={true}
            filterOption={false}
            onSearch={(e) => {
                onNameChange(e);
                setSearchValue(e);
            }}
            notFoundContent={
                <div align="center">
                    {addLoading || loading ? (
                        <Spin size="small" />
                    ) : (
                        <Can I="create" a="tag" passThrough>
                            {(allowed) => {
                                return (
                                    <Button
                                        type="primary"
                                        onClick={handleAdd}
                                        disabled={addLoading || !allowed}
                                    >
                                        {"Create New"}
                                    </Button>
                                );
                            }}
                        </Can>
                    )}
                </div>
            }
            name={"tags"}
            label={"Tags"}
            component={RenderSelect}
            searchValue={searchValue}
            placeholder={"Tags"}
            value={value || "None"}
            loading={loading}
            onChange={onChange}
            // inFilter={true}
            noBotMarging={true}
            mode={"multiple"}
            open={dropdownOpen}
        >
            {[
                // config.EMPTY_OPTION,
                ...(tags?.map((tag) => ({
                    id: tag.id,
                    label: `${tag.value} (${tag.name})` || "",
                    value: tag.id,
                })) || []),
            ]?.map(
                ({
                    id,
                    value,
                    label = "",
                }: {
                    id: string;
                    value: string;
                    label: string;
                }) => (
                    <Option key={id} value={value}>
                        {label}
                    </Option>
                ),
            )}
        </Field>
    );
};

export default compose(
    withStateAndActionsForTag,
    withHookForTags,
    withCreateTag,
)(TagsField);
