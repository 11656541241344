import "./sentryConfig";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import LoadingPage from "src/components/LoadingPage";

SuperTokens.init({
    appInfo: {
        appName: process.env.REACT_APP_SUPERTOKEN_NAME || "",
        apiDomain: process.env.REACT_APP_SUPERTOKEN_API_BACKEND_DOMAIN || "",
        apiBasePath: process.env.REACT_APP_SUPERTOKEN_API_BASE_PATH || "",
        websiteDomain: process.env.REACT_APP_SUPERTOKEN_API_FRONTEND_DOMAIN || "",
        websiteBasePath: "/auth", // route where user should redirect if not logged in
    },
    recipeList: [EmailPassword.init({
        onHandleEvent: async (context) => {
            if (context.action === "PASSWORDLESS_RESTART_FLOW") {
                // TODO:
            } else if (context.action === "PASSWORDLESS_CODE_SENT") {
                // TODO:
            } else if (context.action === "SUCCESS") {
                if (context.isNewRecipeUser && context.user.loginMethods.length === 1) {

                    // TODO: Sign up
                } else {
                    window.location.reload();
                }
            }
        }
    }), Session.init()],
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <React.StrictMode>
        <SuperTokensWrapper>
            <App />
        </SuperTokensWrapper>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
