export const COMPONENT_RICH_TEXT_SCHEMA = {
    text: [],
    background: "#ffffff",
    align: "left",
};

export const COMPONENT_CONDITION_SCHEMA = {
    conditions: [
        {
            text: [], // RICH TEXT
        },
    ],
    compute_functions: [
        {
            isGlobal: true,
            params: [
                "variables",
                "isStart",
                "conditions",
                "currConditionIndex",
                "isConditionCorrect",
            ],
            name: "updateFeedbackAudio",
            output: `
                function updateFeedbackAudio(
                    variables,
                    isStart,
                    conditions,
                    currConditionIndex,
                    isConditionCorrect,
                ) {
                    if (isStart) {
                        return "question_audio_checking_condition";
                    }
                    if (currConditionIndex == 0) {
                        if (isConditionCorrect) return "question_audio_correct_c_1";
                        return "question_audio_incorrect_c_1";
                    }
                    if (currConditionIndex == 1) {
                        if (isConditionCorrect) return "question_audio_correct_c_2";
                        return "question_audio_incorrect_c_2";
                    }
                }
            `,
            type: "JAVASCRIPT",
        },
        {
            isGlobal: true,
            params: [
                "variables",
                "isStart",
                "conditions",
                "currConditionIndex",
                "isConditionCorrect",
            ],
            name: "updateFeedbackText",
            output: `
                function updateFeedbackText(
                    variables,
                    isStart,
                    conditions,
                    currConditionIndex,
                    isConditionCorrect,
                ) {
                    if (isStart) {
                        return "Checking condition";
                    }
                    if (currConditionIndex == 0) {
                        if (isConditionCorrect) return "Correct condition 1";
                        return "Incorrect condition 1";
                    }
                    if (currConditionIndex == 1) {
                        if (isConditionCorrect) return "Correct condition 2";
                        return "Incorrect condition 2";
                    }
                }
            `,
            type: "JAVASCRIPT",
        },
        {
            isGlobal: true,
            params: [
                "variables",
                "conditions",
                "currConditionIndex",
                "getValue",
            ],
            name: "getConditionCorrect",
            output: `
                function getConditionCorrect(
                    variables,
                    conditions,
                    currConditionIndex,
                    getValue,
                ) {
                    const sum = 5;
                    const diff = 1;
                    const data = getValue("Components");
                    function calculateDifference(arr) {
                        let result = arr[0];
                        for (let i = 1; i < arr.length; i++) {
                            result -= arr[i];
                        }
                        return result;
                    }
                    const values = data
                        .filter((i) => i.value.value)
                        .map((i) => parseInt(i.value.text_value.value));
                    if (currConditionIndex == 0) {
                        return values.reduce((sum, i) => sum + parseInt(i), 0) == sum;
                    }
                    if (currConditionIndex == 1) {
                        return Math.abs(calculateDifference(values)) == diff;
                    }
                    return false;
                }
            `,
            type: "JAVASCRIPT",
        },
        {
            name: "getCurrentConditionFeedback",
            output: "function getCurrentFeedback(feedback, feedbackList) {console.log(feedback, feedbackList);\n  const tempList = Array.isArray(feedbackList)\n    ? feedbackList\n    : JSON.parse(feedbackList);\n  return tempList.find((v) => v.name === feedback);\n}",
            params: ["feedback", "feedbackList"],
            type: "JAVASCRIPT",
            isGlobal: false,
            label: "",
            show: true,
        },
        {
            name: "updateFeedbackPool",
            output: 'function updateFeedbackText(feedbackPool, global_context_variables) {\n    function replaceValuesInTemplate(template, valueMap) {\n        function replace(obj) {\n            if (Array.isArray(obj)) {\n                return obj.map((item) => replace(item));\n            } else if (typeof obj === "object" && obj !== null) {\n                const replacedObj = {};\n                Object.keys(obj).forEach((key) => {\n                    if (typeof obj[key] === "string") {\n                        const replacedValue = replaceVariablesInString(\n                            obj[key],\n                            valueMap,\n                        );\n                        replacedObj[key] = replacedValue;\n                    } else {\n                        replacedObj[key] = replace(obj[key]);\n                    }\n                });\n                return replacedObj;\n            } else if (typeof obj === "string") {\n                return replaceVariablesInString(obj, valueMap);\n            } else {\n                return obj;\n            }\n        }\n        function replaceVariablesInString(str, valueMap) {\n            const regex = /@@@(.*?)@@@/g;\n            const parsedText = str.replace(regex, (match, variableName) => {\n                const val =\n                    valueMap[variableName] !== undefined\n                        ? valueMap[variableName]\n                        : match;\n                return typeof val == "object" ? JSON.stringify(val) : val;\n            });\n            try {\n                const text = JSON.parse(parsedText);\n                return typeof text === "number" ? `${text}` : text;\n            } catch {\n                return parsedText;\n            }\n        }\n        return [...replace(template)];\n    }\n    return replaceValuesInTemplate(feedbackPool, global_context_variables);\n}',
            params: ["feedbackPool"],
            type: "JAVASCRIPT",
            isGlobal: true,
            forceGlobal: true,
            show: true,
            label: "Update Feedback Pool",
        },
        {
            "forceGlobal": true,
            "isGlobal": true,
            "params": [],
            "name": "updateConditionStatus",
            "output": "function updateConditionStatus(defaults) { return defaults.condition_status; }",
            "type": "JAVASCRIPT",
            "show": true,
            "label": "Update Condition Status"
        }
    ],
    variables: [
        {
            name: "currentFeedbackData",
            default: ''
        },
        {
            name: "scrollId",
            default: "crossword_with_conditions"
        },
        {
            "objectType": "NUMBER",
            "value": [],
            "name": "condition_status"
        },
        {
            objectType: "FEEDBACK_POOL",
            value: [],
            schema: {
                text: [],
                name: "",
                duration: 5,
            },
            name: "feedback_pool",
            type: "GLOBAL",
            label: "Feedback Pool",
        },
    ],
};

export const COMPONENT_FIGMA_SCHEMA = {
    name: "",
    variables: [],
    compute_functions: [],
    figma_json: {},
    figma_data: {
        url: "",
        file_key: "",
        node_id: "",
    },
};
