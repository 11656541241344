import React from "react";
import { Row, Col, Form, Button } from "antd";
import { withFormik } from "formik";
import FormikPersist from "src/components/FormikPersist";
import { Field, RenderField, RenderSelect } from "src/components";
import { Select } from "antd";
import { getLastStringParamFromUrl } from "src/helpers/functions";
import { useUserData } from "src/helpers/supertokens";
const Option = Select.Option;

const ContentForm = (props) => {
    const { onFinishFailed, values, handleSubmit, isSubmitting } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={24} md={24}>
                    <Field
                        label="Content Type"
                        placeholder="Content Type"
                        name={"type"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.type}
                        rules={[{ required: true }]}
                    >
                        <Option value="concept">Concept</Option>
                        <Option value="question">Question</Option>
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Content Index"
                        placeholder="Content Index"
                        name={"index"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.index}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Content Duration"
                        placeholder="Content Duration"
                        name={"duration"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.duration}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

const ContentFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { content, user_id } = props;
        const collection_id = parseInt(getLastStringParamFromUrl());
        return {
            ...(content?.id ? { id: content.id } : {}),
            collection_id,
            type: content?.type || "",
            index: content?.index || 0,
            duration: content?.duration || 0,
            user_id,
        };
    },

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish(values);
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

const FormikForm = ContentFormWithFormik(ContentForm);

const FormikFormWithUser = (props) => {
    const { user } = useUserData();

    const namespace = "https://hasura.io/jwt/claims/";
    const user_id = user?.user_id;
    return <FormikForm {...props} user_id={user_id} />;
};

export default FormikFormWithUser;
