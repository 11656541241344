import React from "react";
import _ from "lodash";
import { MathRender, SlateEditor } from "src/components";

import { Form, Input, Select, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { CustomKeyboardEditor } from "src/components";
import CorrectValuesComponent from "../ComponentsListEditor/CorrectValuesComponent";
import { BlockPicker } from "react-color";

const TableInputEditor = (props: any) => {
    const {
        blockData,
        setBlock,
        variableNamePrefix,
        currentEditor,
        setCurrentEditor,
        disableWrite,
        hasMentions,
        mentionsList = [],
    } = props;
    return (
        <>
            <h2>Input</h2>
            <Form.Item label="Enabled?">
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={blockData?.input?.enabled}
                    onChange={(value) => {
                        let tmpCell = _.cloneDeep(blockData);
                        tmpCell = _.set(tmpCell, ["input", "enabled"], value);
                        setBlock(tmpCell);
                    }}
                />
            </Form.Item>
            {blockData?.input?.enabled && (
                <>
                    <Form.Item label="Fill">
                        <BlockPicker
                            colors={[]}
                            color={blockData?.input?.fill}
                            onChangeComplete={(color: any) => {
                                let tmpCell = _.cloneDeep(blockData);
                                tmpCell = _.set(
                                    tmpCell,
                                    ["input", "fill"],
                                    color.hex, //?.replace("#", "0x"),
                                );
                                setBlock(tmpCell);
                            }}
                            triangle="hide"
                        />
                    </Form.Item>
                    {/*Select option for if input is number or text or latex*/}
                    <Form.Item label="Input Type">
                        <Select
                            value={blockData?.input?.type}
                            onChange={(value) => {
                                let tmpCell = _.cloneDeep(blockData);
                                tmpCell = _.set(
                                    tmpCell,
                                    ["input", "type"],
                                    value,
                                );
                                setBlock(tmpCell);
                            }}
                        >
                            <Select.Option value="text">Text</Select.Option>
                            {/* <Select.Option value="number">Number</Select.Option> */}
                            <Select.Option value="latex">Latex</Select.Option>
                            <Select.Option value="latex-fill-in-blank">
                                Latex Fill In The Blank
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    {blockData?.input?.type === "text" && (
                        <>
                            {/**Default Value field and placeholder field */}
                            <Form.Item label="Default Value">
                                <Input
                                    value={blockData?.input?.default}
                                    placeholder="Default Value"
                                    onChange={(e: any) => {
                                        let tmpCell = _.cloneDeep(blockData);
                                        tmpCell = _.set(
                                            tmpCell,
                                            ["input", "default"],
                                            e.target.value,
                                        );
                                        setBlock(tmpCell);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Placeholder">
                                <Input
                                    value={blockData?.input?.placeholder}
                                    placeholder="Placeholder"
                                    onChange={(e: any) => {
                                        let tmpCell = _.cloneDeep(blockData);
                                        tmpCell = _.set(
                                            tmpCell,
                                            ["input", "placeholder"],
                                            e.target.value,
                                        );
                                        setBlock(tmpCell);
                                    }}
                                />
                            </Form.Item>
                        </>
                    )}

                    {blockData?.input?.type === "number" && (
                        <>
                            {/* Default and placeholder */}
                            <Form.Item label="Default Value">
                                <Input
                                    value={blockData?.input?.default}
                                    type="number"
                                    placeholder="Default Value"
                                    onChange={(e: any) => {
                                        let tmpCell = _.cloneDeep(blockData);
                                        tmpCell = _.set(
                                            tmpCell,
                                            ["input", "default"],
                                            e.target.value,
                                        );
                                        setBlock(tmpCell);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Placeholder">
                                <Input
                                    value={blockData?.input?.placeholder}
                                    placeholder="Placeholder"
                                    onChange={(e: any) => {
                                        let tmpCell = _.cloneDeep(blockData);
                                        tmpCell = _.set(
                                            tmpCell,
                                            ["input", "placeholder"],
                                            e.target.value,
                                        );
                                        setBlock(tmpCell);
                                    }}
                                />
                            </Form.Item>
                        </>
                    )}

                    {(blockData?.input?.type === "number" ||
                        blockData?.input?.type === "text") && (
                        <>
                            {/* Switch for single line input */}
                            <Form.Item label="Single Line">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={blockData?.input?.singleLine}
                                    onChange={(value) => {
                                        let tmpCell = _.cloneDeep(blockData);
                                        tmpCell = _.set(
                                            tmpCell,
                                            ["input", "singleLine"],
                                            value,
                                        );
                                        setBlock(tmpCell);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Dimension Control">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={
                                        blockData?.input?.dimensionControl
                                            ?.enabled
                                    }
                                    onChange={(value) => {
                                        let tmpCell = _.cloneDeep(blockData);
                                        if (!tmpCell?.input?.dimensionControl) {
                                            tmpCell = _.set(
                                                tmpCell,
                                                ["input", "dimensionControl"],
                                                {},
                                            );
                                        }
                                        tmpCell = _.set(
                                            tmpCell,
                                            [
                                                "input",
                                                "dimensionControl",
                                                "enabled",
                                            ],
                                            value,
                                        );
                                        setBlock(tmpCell);
                                    }}
                                />
                            </Form.Item>

                            {blockData?.input?.dimensionControl?.enabled && (
                                <>
                                    <Form.Item label="Min Width">
                                        <Input
                                            value={
                                                blockData?.input
                                                    ?.dimensionControl?.minWidth
                                            }
                                            type="number"
                                            placeholder="Min Width"
                                            onChange={(e: any) => {
                                                let tmpCell =
                                                    _.cloneDeep(blockData);
                                                if (
                                                    !tmpCell?.input
                                                        ?.dimensionControl
                                                ) {
                                                    tmpCell = _.set(
                                                        tmpCell,
                                                        [
                                                            "input",
                                                            "dimensionControl",
                                                        ],
                                                        {},
                                                    );
                                                }
                                                tmpCell = _.set(
                                                    tmpCell,
                                                    [
                                                        "input",
                                                        "dimensionControl",
                                                        "minWidth",
                                                    ],
                                                    e.target.value,
                                                );
                                                setBlock(tmpCell);
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Min Height">
                                        <Input
                                            value={
                                                blockData?.input
                                                    ?.dimensionControl
                                                    ?.minHeight
                                            }
                                            type="number"
                                            placeholder="Min Height"
                                            onChange={(e: any) => {
                                                let tmpCell =
                                                    _.cloneDeep(blockData);
                                                if (
                                                    !tmpCell?.input
                                                        ?.dimensionControl
                                                ) {
                                                    tmpCell = _.set(
                                                        tmpCell,
                                                        [
                                                            "input",
                                                            "dimensionControl",
                                                        ],
                                                        {},
                                                    );
                                                }
                                                tmpCell = _.set(
                                                    tmpCell,
                                                    [
                                                        "input",
                                                        "dimensionControl",
                                                        "minHeight",
                                                    ],
                                                    e.target.value,
                                                );
                                                setBlock(tmpCell);
                                            }}
                                        />
                                    </Form.Item>
                                </>
                            )}
                        </>
                    )}
                    {blockData?.input?.type === "latex" && (
                        <>
                            <Form.Item label="Default Value">
                                <SlateEditor
                                    disabled={disableWrite}
                                    hasMentions={hasMentions}
                                    mentionsList={mentionsList}
                                    id={`${variableNamePrefix}-input-default`}
                                    onChange={(value: any) => {
                                        let tmpCell = _.cloneDeep(blockData);
                                        tmpCell = _.set(
                                            tmpCell,
                                            ["input", "default"],
                                            value,
                                        );
                                        setBlock(tmpCell);
                                    }}
                                    isFocused={
                                        currentEditor ===
                                        `${variableNamePrefix}-input-default`
                                    }
                                    setEditor={(id: string) =>
                                        setCurrentEditor(id)
                                    }
                                    value={
                                        _.isString(
                                            blockData?.input?.default || "",
                                        )
                                            ? []
                                            : blockData?.input?.default ?? []
                                    }
                                    placeholder={"Type here..."}
                                />
                            </Form.Item>
                            <Form.Item label="Placeholder">
                                <SlateEditor
                                    disabled={disableWrite}
                                    hasMentions={hasMentions}
                                    mentionsList={mentionsList}
                                    id={`${variableNamePrefix}-input-placeholder`}
                                    onChange={(value: any) => {
                                        let tmpCell = _.cloneDeep(blockData);
                                        tmpCell = _.set(
                                            tmpCell,
                                            ["input", "placeholder"],
                                            value,
                                        );
                                        setBlock(tmpCell);
                                    }}
                                    isFocused={
                                        currentEditor ===
                                        `${variableNamePrefix}-input-placeholder`
                                    }
                                    setEditor={(id: string) =>
                                        setCurrentEditor(id)
                                    }
                                    value={
                                        _.isString(
                                            blockData?.input?.placeholder || "",
                                        )
                                            ? []
                                            : blockData?.input?.placeholder ??
                                              []
                                    }
                                    placeholder={"Type here..."}
                                />
                            </Form.Item>
                        </>
                    )}

                    {blockData?.input?.type === "latex-fill-in-blank" && (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    flexDirection: "column",
                                }}
                            >
                                <Input.TextArea
                                    style={{
                                        width: "100%",
                                        flexShrink: 0,
                                    }}
                                    rows={5}
                                    value={
                                        blockData?.input?.config?.latex ?? ""
                                    }
                                    disabled={disableWrite}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        let tmpBlock = _.cloneDeep(blockData);

                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["input", "config", "latex"],
                                            value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                                <div
                                    style={{
                                        marginTop: "15px",
                                        padding: "10px",
                                        width: "100%",
                                        border: "1px dashed #aaa",
                                    }}
                                >
                                    <MathRender
                                        src={
                                            blockData?.input?.config?.latex
                                                ? `$${blockData?.input?.config?.latex}$`
                                                : ""
                                        }
                                    />
                                </div>

                                <CorrectValuesComponent
                                    data={{
                                        props: {
                                            default_value: {
                                                value:
                                                    blockData?.input?.config
                                                        ?.latex ?? "",
                                            },
                                        },
                                    }}
                                    correct_values={{
                                        value:
                                            blockData?.input?.config
                                                ?.correct_values || [],
                                        type: "MATHQUILL_RICH_TEXT",
                                    }}
                                    disableWrite={false}
                                    setCurConfig={(values: any[]) => {
                                        let tmpBlock = _.cloneDeep(blockData);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "input",
                                                "config",
                                                "correct_values",
                                            ],
                                            values,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    hasMentions={hasMentions}
                                    mentionsList={mentionsList}
                                />
                            </div>
                        </>
                    )}

                    <br />

                    <h5>Custom Keyboard:</h5>
                    <CustomKeyboardEditor
                        disabled={false}
                        hasLatex={["latex-fill-in-blank", "latex"].includes(
                            blockData?.input?.type,
                        )}
                        value={blockData?.input?.keyboard}
                        onOk={(data: any) => {
                            let tmpCell = _.cloneDeep(blockData);
                            tmpCell = _.set(
                                tmpCell,
                                ["input", "keyboard"],
                                data,
                            );
                            setBlock(tmpCell);
                        }}
                    />
                    {!blockData?.input?.keyboard && (
                        <div
                            style={{
                                color: "red",
                            }}
                        >
                            Not added!
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default TableInputEditor;
