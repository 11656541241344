import React, { useMemo } from "react";
import { renderer } from "src/modules/worksheet/components/blocks/helpers";

const textStyle = {
    color: "#000",
    textAlign: "center",
    fontFamily: "Epilogue",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%", // This will compute to 24px if the font-size is 16px
};
const DroppedItem = ({ droppedSource }) => {
    const textValue = droppedSource.props?.text.value;

    const dragStyle = {
        // transform: CSS.Translate.toString(transform),
        // zIndex: isDragging ? 3 : 2,
    };

    const background = useMemo(() => {
        return droppedSource.props?.input_background?.value?.color;
    }, [droppedSource]);
    const imageUrl = useMemo(() => {
        if (droppedSource.props?.input_background?.value) {
            return droppedSource.props?.input_background?.value?.asset;
        }
        return null;
    }, [droppedSource]);

    return (
        <div
            id={droppedSource.genericId}
            className="renderer-table-dropped-item-wrapper"
            style={{
                backgroundColor: background,
                backgroundImage: imageUrl ? `url(${imageUrl})` : "none",
                backgroundSize: "56px",
                position: "relative",
                zIndex: 2,
                ...dragStyle,
            }}
        >
            {textValue !== "" && textValue && textValue?.length > 0 && (
                <p
                    style={{
                        marginBlockEnd: 0,
                    }}
                >
                    {renderer(textValue, {
                        textStyle: textStyle,
                    })}
                </p>
            )}
        </div>
    );
};

export default DroppedItem;
