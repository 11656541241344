import {
    Badge,
    Button,
    Card,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Typography,
    message,
} from "antd";
// import { AudioPicker, BLOCK_TYPES } from "../../common/index";
import { V2_STORY_DEFAULT_VALUE, renderEditorV2Story } from "../../V2Story";
import _, { cloneDeep, set } from "lodash";
import { v4 as uuid } from "uuid";
import React, { useEffect, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { Actions } from "../../../../WorksheetEditor/components/Editor/BlocksColumn/Actions";
import { updateKeysAndCopy } from "../../../../WorksheetEditor/helpers/getActionMenuItems";
import { BLOCK_TYPES } from "../../../common/index";
import { LogicModal } from "../../AllInOne/renderEditor/ComponentsEditor/common/LogicModal";
import { SlateEditor } from "src/components";
import StoriesUpload from "./StoriesUpload";
import isValidCallback from "../../V2Story/isValid";
import {
    convertStoriesToV3,
    convertStoriesToV4,
} from "../../ProgressLevel/renderEditor/helper";
import { useWorksheetState } from "src/modules/worksheet/components/contexts/WorksheetContext";

const { V2_STORIES_BLOCK, V2_STORY_BLOCK } = BLOCK_TYPES;

const EditorV2Story = renderEditorV2Story;

const { Title } = Typography;

const Editor: React.FC<any> = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    isNewBlock,
    setIsNewBlock,
    worksheet,
    currentBlock,
    showDifficultyLevel = false,
    disableWrite,
    isReadOnlyMode,
    renderContextVariables,
    hasMentions,
    mentionsList = [],
    renderLinkSelect,
    previewData,
    setNewJob,
    updateStoriesJob,
    storiesPath,
    isChild,
    chunkSlug,
    isChildStory,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            slug: dataSlug,
            [V2_STORIES_BLOCK]: { name = "", logic, dev_remarks = [] },
            children,
        },
        backend,
    } = block;
    let slug =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.slug || []
            : dataSlug;

    const { blockIndex, chunkIndex, publishedBlocksMap }: any =
        useWorksheetState();

    const [currentSubBlock, setCurrentSubBlock] = useState(
        children?.length > 0 ? 0 : -1,
    );
    const [isPlaying, setIsPLaying] = useState(false);
    const [tempChild, setTempChild] = useState("");
    const [tempChildren, setTempChildren] = useState("");
    const [currentEditor, setCurrentEditor] = useState(
        `${id || tmpId}_remarks`,
    );

    useEffect(() => {
        setCurrentEditor(`${id || tmpId}_remarks`);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const updateCurrentBlock = (val: number) => {
        setIsPLaying(false);
        setCurrentSubBlock(val);
    };

    useEffect(() => {
        updateCurrentBlock(children?.length > 0 ? 0 : -1);
    }, [currentBlock]);

    const editorProps = {
        block: children[currentSubBlock],
        setBlock: (subBlock: any) => {
            let tmpBlock = _.cloneDeep(block);
            tmpBlock = _.set(
                tmpBlock,
                ["data", "children", currentSubBlock],
                subBlock,
            );
            setBlock(tmpBlock);
        },
        isEditor,
        setIsEditor,
        currentBlock: currentSubBlock + 1,
        isNewBlock,
        setIsNewBlock,
        worksheet,
        childBlockCount: children?.length || 0,
        isPlaying,
        setIsPLaying,
        disableWrite,
        isReadOnlyMode,
        hasMentions,
        mentionsList,
        renderLinkSelect,
    };

    const DragHandle = SortableHandle(() => <MenuOutlined />);

    const SortableItem = SortableElement(({ value, child }) => {
        const actionsProps = {
            deleteBlock: () => {
                updateCurrentBlock(currentSubBlock - 1);
                let tmpBlock = _.cloneDeep(block);
                const tmpOptions = [...children];
                _.pullAt(tmpOptions, currentSubBlock);
                tmpBlock = _.set(tmpBlock, ["data", "children"], tmpOptions);

                setBlock(tmpBlock);
            },
            idx: currentSubBlock,
            addBlockCustom: (type: string, index: number, newBlock: any) => {
                let tmpBlock = _.cloneDeep(block);
                const tmpChildren = [...children];
                tmpChildren.splice(index, 0, newBlock);
                tmpBlock = _.set(tmpBlock, ["data", "children"], tmpChildren);
                setBlock(tmpBlock);
                setCurrentSubBlock(index);
            },
            block: child,
        };

        return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Badge.Ribbon
                    color={"cyan"}
                    text={value + 1}
                    placement={"start"}
                >
                    <Card
                        hoverable
                        style={{
                            borderRadius: "8px",
                            marginBottom: "4px",
                            background:
                                currentSubBlock == value ? "#F1F1F1" : "",
                            borderBottom: !isValidCallback({
                                block: child,
                            })?.isValid
                                ? "3px solid red"
                                : currentSubBlock === value
                                ? "3px solid indigo"
                                : "",
                            boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            minWidth: "90px",
                        }}
                        bodyStyle={{
                            background: "transparent",
                        }}
                        onClick={() => {
                            updateCurrentBlock(value);
                        }}
                    >
                        {"Story"}

                        {currentSubBlock === value && !disableWrite && (
                            <div
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    padding: "2px",
                                }}
                            >
                                <DragHandle />
                            </div>
                        )}
                    </Card>
                </Badge.Ribbon>

                {currentSubBlock === value && !disableWrite && (
                    <Actions {...actionsProps} />
                )}
            </div>
        );
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div
                style={{
                    display: "flex",
                    gap: "30px",
                    width: "100%",
                    flexWrap: "wrap",
                }}
            >
                {items.map((child, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={index}
                        child={child}
                        disabled={disableWrite}
                    />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let tmpBlock = _.cloneDeep(block);
        const tmpChildren = arrayMoveImmutable(
            children,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = _.set(tmpBlock, ["data", "children"], tmpChildren);
        setBlock(tmpBlock);
        updateCurrentBlock(newIndex);
    };

    return (
        <Card>
            <Row gutter={[20, 20]}>
                {previewData && block?.id && (
                    <Form.Item
                        label={"Open Preview"}
                        style={{
                            border: "1px solid black",
                            padding: "0 5px",
                            marginRight: "5px",
                        }}
                    >
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                const queryParams = new URLSearchParams();
                                const jsonData = {
                                    context: "preview",
                                    ...previewData,
                                    progression_activity_id: uuid(),
                                    path_id: uuid(),
                                    ...(!isChildStory
                                        ? {
                                              variant_id: block?.id,
                                              block_id: block?.id,
                                          }
                                        : {
                                              story_id: block?.id,
                                          }),
                                };

                                // Add parameters from JSON object to the query string
                                for (const key in jsonData) {
                                    if (jsonData.hasOwnProperty(key)) {
                                        queryParams.append(key, jsonData[key]);
                                    }
                                }

                                // Construct the URL with the parameters
                                const url = `${
                                    process.env.REACT_APP_CLIENT_PREVIEW
                                }/home/worksheet/${worksheet?.id}/${
                                    previewData["progression_id"]
                                }?${queryParams.toString()}&logs=true&showQuestion=true`;

                                // Open the URL in a new tab
                                window.open(url, "_blank");
                            }}
                            disabled={!previewData["progression_id"]}
                            style={{
                                marginRight: "10px",
                            }}
                        >
                            QA
                        </Button>
                        {/* <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                const queryParams = new URLSearchParams();
                                const jsonData = {
                                    context: "preview",
                                    ...previewData,
                                    progression_activity_id: uuid(),
                                    path_id: uuid(),
                                    ...(!isChildStory
                                        ? {
                                            variant_id: block?.id,
                                            block_id: block?.id,
                                        }
                                        : {
                                            story_id: block?.id,
                                        }),
                                };

                                // Add parameters from JSON object to the query string
                                for (const key in jsonData) {
                                    if (jsonData.hasOwnProperty(key)) {
                                        queryParams.append(key, jsonData[key]);
                                    }
                                }

                                // Construct the URL with the parameters
                                const url = `https://mathai.ai/home/worksheet/${worksheet?.id
                                    }/${previewData["progression_id"]
                                    }?${queryParams.toString()}&logs=true&showQuestion=true`;

                                // Open the URL in a new tab
                                window.open(url, "_blank");
                            }}
                            disabled={!previewData["progression_id"]}
                        >
                            MathAi
                        </Button> */}
                    </Form.Item>
                )}
                {!block?.id && (
                    <Button
                        danger
                        size="small"
                        style={{
                            margin: "0 16px",
                        }}
                        type="primary"
                        onClick={() => {
                            if (worksheet?.type === "personalized_learning_v4")
                                return;
                            let tmpBlock = cloneDeep(block);
                            tmpBlock.id = uuid();
                            tmpBlock.tmpId = uuid();
                            setBlock(tmpBlock);
                        }}
                    >
                        {worksheet?.type === "personalized_learning_v4"
                            ? "Save Worksheet to preview"
                            : "Set Block ID"}
                    </Button>
                )}
                {!disableWrite && (
                    <Button
                        disabled={disableWrite}
                        type="primary"
                        onClick={() => {
                            message.info(`Copied Stories to clipboard!`);

                            navigator.clipboard.writeText(
                                JSON.stringify(block),
                            );
                        }}
                        size="small"
                    >
                        Copy the stories block to Clipboard
                    </Button>
                )}
                {!isChildStory &&
                    worksheet?.type === "personalized_learning_v4" &&
                    block?.id && (
                        <div
                            style={{
                                display: "flex",
                                gap: "15px",
                            }}
                        >
                            <Form.Item
                                style={{
                                    marginLeft: "10px",
                                }}
                                label="Stories Id"
                            >
                                <Input.Search
                                    value={block.id}
                                    onChange={() => {}}
                                    enterButton="Copy"
                                    size="small"
                                    onSearch={() => {
                                        message.info(`Copied Id to clipboard!`);
                                        navigator.clipboard.writeText(block.id);
                                    }}
                                />
                            </Form.Item>
                            {worksheet?.type === "personalized_learning_v4" && (
                                <Form.Item label="Published Stories Id">
                                    {publishedBlocksMap[block.id] ? (
                                        <Input.Search
                                            value={publishedBlocksMap[block.id]}
                                            onChange={() => {}}
                                            enterButton="Copy"
                                            size="small"
                                            onSearch={() => {
                                                message.info(
                                                    `Copied Id to clipboard!`,
                                                );
                                                navigator.clipboard.writeText(
                                                    block.id,
                                                );
                                            }}
                                        />
                                    ) : (
                                        <span>Not published Yet</span>
                                    )}
                                </Form.Item>
                            )}
                        </div>
                    )}

                <Col span={16}>
                    <Row gutter={[20, 20]}>
                        <Col span={12}>
                            <Title
                                level={4}
                                editable={
                                    disableWrite
                                        ? false
                                        : {
                                              text: name,
                                              onChange: (val) => {
                                                  let tmpBlock =
                                                      cloneDeep(block);
                                                  tmpBlock = set(
                                                      tmpBlock,
                                                      [
                                                          "data",
                                                          V2_STORIES_BLOCK,
                                                          "name",
                                                      ],
                                                      val,
                                                  );
                                                  setBlock(tmpBlock);
                                              },
                                              triggerType: ["icon", "text"],
                                              enterIcon: null,
                                          }
                                }
                            >
                                {name}
                            </Title>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Slug">
                                <Input
                                    value={slug}
                                    disabled={disableWrite}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            worksheet?.type ===
                                                "personalized_learning_v4"
                                                ? ["backend", "slug"]
                                                : ["data", "slug"],
                                            value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            span={
                                (worksheet?.type === "personalized_learning_v4"
                                    ? backend
                                    : logic) && !isChild
                                    ? 16
                                    : 24
                            }
                        >
                            <h3
                                style={{
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                }}
                            >
                                Dev Remarks
                            </h3>
                            <SlateEditor
                                disabled={disableWrite}
                                hasMentions={hasMentions}
                                mentionsList={mentionsList}
                                id={`${id || tmpId}_remarks`}
                                onChange={(value: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            V2_STORIES_BLOCK,
                                            "dev_remarks",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                isFocused={
                                    !disableWrite &&
                                    currentEditor === `${id || tmpId}_remarks`
                                }
                                setEditor={(id: string) => setCurrentEditor(id)}
                                value={dev_remarks}
                                placeholder={"Type here..."}
                                required={false}
                            />
                        </Col>
                        <Col span={8}>
                            {(worksheet?.type === "personalized_learning_v4"
                                ? backend
                                : logic) &&
                                !isChild && (
                                    <Form.Item
                                        label="Update Logic"
                                        style={{
                                            marginTop: "20px",
                                        }}
                                    >
                                        <LogicModal
                                            blockIndex={blockIndex}
                                            chunkIndex={chunkIndex}
                                            blockSlug={slug}
                                            chunkSlug={chunkSlug}
                                            disableWrite={disableWrite}
                                            computeFUnctions={
                                                (worksheet?.type ===
                                                "personalized_learning_v4"
                                                    ? backend.compute_functions
                                                    : logic.compute_functions) ||
                                                []
                                            }
                                            isAllGlobal={true}
                                            onSave={(val) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    worksheet?.type ===
                                                        "personalized_learning_v4"
                                                        ? [
                                                              "backend",
                                                              "compute_functions",
                                                          ]
                                                        : [
                                                              "data",
                                                              V2_STORIES_BLOCK,
                                                              "logic",
                                                              "compute_functions",
                                                          ],
                                                    val,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </Form.Item>
                                )}
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    {!disableWrite && (
                        <Form.Item
                            label={"Update Stories"}
                            style={{ maxWidth: "600px" }}
                        >
                            <Input.TextArea
                                disabled={disableWrite}
                                value={tempChild}
                                placeholder="Paste Stories here"
                                onChange={(e) => setTempChild(e.target.value)}
                            />
                            <Button
                                disabled={disableWrite}
                                style={{ marginTop: "10px" }}
                                type="primary"
                                size="small"
                                onClick={() => {
                                    try {
                                        const parsedJson =
                                            JSON.parse(tempChild);
                                        if (
                                            parsedJson?.type !==
                                            V2_STORIES_BLOCK
                                        ) {
                                            message.warn("Invalid JSON");
                                            return;
                                        }
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock = updateKeysAndCopy(
                                            _.omit(parsedJson, [
                                                "id",
                                                "worksheet_block_map_id",
                                                "tmpId",
                                            ]),
                                            worksheet?.type ===
                                                "personalized_learning_v4",
                                        );

                                        if (
                                            worksheet?.type ===
                                            "personalized_learning_v4"
                                        ) {
                                            tmpBlock =
                                                convertStoriesToV4(tmpBlock);
                                        } else if (
                                            worksheet?.type ===
                                            "personalized_learning_v3"
                                        ) {
                                            tmpBlock =
                                                convertStoriesToV3(tmpBlock);
                                        }
                                        if (block.id) tmpBlock.id = block.id;
                                        setBlock(tmpBlock);
                                        message.success("Stories Added");
                                        setTempChild("");
                                    } catch (e) {
                                        // captureException(e);
                                        message.error("Error in JSON!");
                                    }
                                }}
                            >
                                Update Stories
                            </Button>
                        </Form.Item>
                    )}

                    {renderContextVariables && renderContextVariables()}

                    <Form.Item label="Upload Stories">
                        <StoriesUpload
                            block={block}
                            setBlock={(v: any, storiesId: any) => {
                                if (isChildStory) setBlock(v, storiesId);
                                else setBlock(v);
                                setCurrentSubBlock(-1);
                            }}
                            disabled={
                                disableWrite ||
                                (!block?.id &&
                                    worksheet?.type !==
                                        "personalized_learning_v4")
                            }
                            setNewJob={setNewJob}
                            updateStoriesJob={updateStoriesJob}
                            storiesPath={storiesPath}
                        />
                        {!block?.id &&
                            worksheet?.type !== "personalized_learning_v4" && (
                                <span style={{ color: "red" }}>
                                    Set id to upload stories
                                </span>
                            )}
                    </Form.Item>
                </Col>
                {showDifficultyLevel && (
                    <div
                        style={{
                            border: "1px dashed #808080",
                            margin: "10px auto",
                            padding: "10px 10px",
                        }}
                    >
                        <h4 style={{ fontWeight: "bold" }}>Difficulty Level</h4>

                        <Select
                            disabled={disableWrite}
                            value={block?.data?.other?.difficulty_level}
                            style={{ width: 120 }}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "other", "difficulty_level"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        >
                            <Select.Option value={`easy`} key={`easy`}>
                                Easy
                            </Select.Option>
                            <Select.Option value={`medium`} key={`medium`}>
                                Medium
                            </Select.Option>{" "}
                            <Select.Option value={`hard`} key={`hard`}>
                                Hard
                            </Select.Option>
                        </Select>
                    </div>
                )}
                <Title level={5}>Add Single Story</Title>
                <Col
                    span={24}
                    style={{
                        display: "flex",
                        gap: "20px",
                        width: "100%",
                        marginBottom: "20px",
                    }}
                >
                    {!disableWrite && (
                        <>
                            <Badge.Ribbon
                                color={"green"}
                                text={"+"}
                                placement={"start"}
                            >
                                <Card
                                    hoverable
                                    style={{
                                        borderRadius: "8px",
                                        height: "75px",
                                        marginBottom: "4px",
                                        borderBottom: "3px solid green",
                                        flexGrow: 0,
                                        width: "120px",
                                    }}
                                    onClick={() => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "data",
                                                "children",
                                                children.length,
                                            ],
                                            {
                                                ...V2_STORY_DEFAULT_VALUE,
                                                order: children.length + 1,
                                                tmpId: uuid(),
                                                id: uuid(),
                                            },
                                        );
                                        setBlock(tmpBlock);
                                        updateCurrentBlock(children.length);
                                    }}
                                >
                                    {"Add Story"}
                                </Card>
                            </Badge.Ribbon>
                            <div
                                style={{
                                    display: "flex",
                                    minWidth: "400px",
                                }}
                            >
                                <Input.TextArea
                                    disabled={disableWrite}
                                    value={tempChildren}
                                    placeholder="Paste Story here"
                                    onChange={(e) =>
                                        setTempChildren(e.target.value)
                                    }
                                />
                                <Button
                                    disabled={disableWrite}
                                    type="primary"
                                    onClick={() => {
                                        try {
                                            const parsedJson =
                                                JSON.parse(tempChildren);
                                            if (
                                                parsedJson?.type !==
                                                V2_STORY_BLOCK
                                            ) {
                                                message.warn("Invalid JSON");
                                                return;
                                            }
                                            let tmpBlock = _.cloneDeep(block);
                                            if (parsedJson) {
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "data",
                                                        "children",
                                                        children.length,
                                                    ],
                                                    updateKeysAndCopy(
                                                        _.omit(parsedJson, [
                                                            "id",
                                                            "worksheet_block_map_id",
                                                            "tmpId",
                                                        ]),
                                                        false,
                                                    ),
                                                );
                                                setBlock(tmpBlock);
                                                updateCurrentBlock(
                                                    children.length,
                                                );
                                                message.success("Story Added");
                                                setTempChildren("");
                                            }
                                        } catch (e) {
                                            // captureException(e);
                                            message.error("Error in JSON!");
                                        }
                                    }}
                                >
                                    Add Story
                                </Button>
                            </div>
                        </>
                    )}
                </Col>

                <Col span={24}>
                    {children?.length ? (
                        <SortableList
                            items={children}
                            onSortEnd={onSortEnd}
                            axis="xy"
                            useDragHandle
                        />
                    ) : (
                        <div
                            style={{
                                fontSize: "24px",
                                color: "red",
                            }}
                        >
                            No Stories Added
                        </div>
                    )}
                    <hr />
                </Col>
                <Col span={24}>
                    {currentSubBlock !== -1 && children[currentSubBlock] && (
                        <EditorV2Story {...editorProps} />
                    )}
                </Col>
            </Row>
        </Card>
    );
};

export default Editor;
