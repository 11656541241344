import { Card } from "antd";
import { cloneDeep, set } from "lodash";
import VariablesForm from "../common/VariablesForm";
import ComponentDetails from "../common/ComponentDetails";

const TimerEditor = (props: any) => {
    const { block, setBlock, disableWrite } = props;
    const {
        figma: { variables },
    } = block;

    return (
        <div style={{ paddingBottom: "10px", display: "flex" }}>
            <Card
                style={{
                    minWidth: "600px",
                    // maxWidth: "600px",
                    width: "100%",
                    marginLeft: "8px",
                    flexShrink: 0,
                    height: "85vh",
                    overflow: "auto",
                    scrollbarWidth: "none",
                }}
                title={<h3>Configurations</h3>}
            >
                <ComponentDetails
                    block={block}
                    setBlock={setBlock}
                    disableWrite={disableWrite}
                />
                <VariablesForm
                    {...props}
                    variables={variables}
                    setBlock={(value) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(tmpBlock, ["figma", "variables"], value);
                        setBlock(tmpBlock);
                    }}
                />
            </Card>
        </div>
    );
};

export default TimerEditor;
