import React from "react";
import { Form, Card, Switch, InputNumber } from "antd";
import _ from "lodash";

const MinHeight = ({ block, setBlock, disableWrite }: any) => {
    return (
        <Card
            title={<h4>Height</h4>}
            bodyStyle={{
                padding: block?.enabled ? "12px" : "0px",
            }}
            size="small"
            extra={
                <Switch
                    disabled={disableWrite}
                    size="small"
                    style={{ marginLeft: "12px" }}
                    checked={block?.enabled}
                    onChange={(val) => {
                        let tmpBlock = _.cloneDeep(block);
                        if (!tmpBlock) {
                            tmpBlock = {};
                        }
                        tmpBlock.enabled = val;
                        setBlock(tmpBlock);
                    }}
                />
            }
        >
            <>
                {block?.enabled && (
                    <Form.Item label="Min">
                        <InputNumber
                            type="number"
                            disabled={disableWrite}
                            size="small"
                            value={block?.minHeight ?? 64}
                            onChange={(e) => {
                                let tmpBlock = _.cloneDeep(block);
                                if (!tmpBlock) {
                                    tmpBlock = {};
                                }
                                tmpBlock.minHeight = e;
                                setBlock(tmpBlock);
                            }}
                            min={20}
                            max={1000}
                            step={1}
                        />
                    </Form.Item>
                )}
            </>
        </Card>
    );
};

export default MinHeight;
