import React from "react";
import { Row, Col, Form, Button, Divider } from "antd";
import { withFormik, FieldArray } from "formik";
import FormikPersist from "src/components/FormikPersist";
import {
    Field,
    RenderCheckBox,
    RenderField,
    RenderAutoComplete,
    RenderTags,
    RenderDynamicField,
    RenderSelect,
    RenderUpload,
} from "src/components";
import * as yup from "yup";
import { useUserData } from "src/helpers/supertokens";

import { Select } from "antd";
const Option = Select.Option;

const TabForm = (props) => {
    const {
        onFinishFailed,
        values,
        handleSubmit,
        isSubmitting,
        disableEditType = false,
    } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={24} md={24}>
                    <Field
                        label="Tab Title"
                        placeholder="Tab Title"
                        name={"title"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.title}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Tab slug"
                        placeholder="Tab slug"
                        name={"group"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.group}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Tab Description"
                        placeholder="Tab Description"
                        name={"description"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.description}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Tab Build Number"
                        placeholder="Tab Build Number"
                        name={"build_number"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.build_number}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Tab deeplink template"
                        placeholder="Tab deeplink template"
                        name={"deeplink_template"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.deeplink_template}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={8} md={8}>
                    <Field
                        label="Tab Last Child Banner"
                        placeholder="Tab Last Child Banner"
                        name={"last_child.banner"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.last_child.banner}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={8} md={8}>
                    <Field
                        label="Tab Last Child Title"
                        placeholder="Tab Last Child Title"
                        name={"last_child.title"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.last_child.title}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={8} md={8}>
                    <Field
                        label="Tab Last Child CTA"
                        placeholder="Tab Last Child CTA"
                        name={"last_child.cta"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.last_child.cta}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={8} md={8}>
                    <Field
                        label="Tab Header Link Title"
                        placeholder="Tab Header Link Title"
                        name={"header_link.title"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.header_link.title}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={8} md={8}>
                    <Field
                        label="Tab Header Link CTA"
                        placeholder="Tab Header Link CTA"
                        name={"header_link.cta"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.header_link.cta}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Tab Type"
                        placeholder="Tab Type"
                        name={"type"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.type}
                        rules={[{ required: true }]}
                        disabled={disableEditType}
                    >
                        <Option value="document_content">
                            Document Content
                        </Option>
                        <Option value="worksheet_content">
                            Worksheet Content
                        </Option>
                        <Option value="set_content">Set Content</Option>

                        <Option value="cta">CTA Only</Option>
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <FieldArray
                        name={"banners"}
                        render={(arrayHelpers) => (
                            <RenderDynamicField
                                buttonText="Add Banner"
                                keys={[
                                    {
                                        key: "cta_type",
                                        type: "select",
                                        label: "cta_type",
                                        options: ["external", "internal"],
                                    },
                                    {
                                        key: "cta_action",
                                        type: "text",
                                        label: "cta_action",
                                    },
                                    {
                                        key: "banner",
                                        type: "text",
                                        label: "banner",
                                    },
                                ]}
                                arrayHelpers={arrayHelpers}
                                values={values.banners}
                                name={"banners"}
                            />
                        )}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Tab Status"
                        placeholder="Tab Status"
                        name={"status"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.status}
                        rules={[{ required: true }]}
                    >
                        <Option value="inactive">Inactive</Option>
                        <Option value="active">Active</Option>
                    </Field>
                </Col>

                <Col lg={12} md={24}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isSubmitting}
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export const FormSchema = yup.object().shape({
    title: yup.string().required("This field is required"),
    description: yup.string().required("This field is required"),
    build_number: yup.number().required("This field is required"),
    type: yup.string().required("This field is required"),
    group: yup.string().required("This field is required"),
    status: yup.string().required("This field is required"),
    deeplink_template: yup.string().required("This field is required"),

    last_child: yup.object().shape({
        banner: yup.string().required("This field is required"),
        title: yup.string().required("This field is required"),
        cta: yup.string().required("This field is required"),
    }),
    header_link: yup.object().shape({
        title: yup.string().required("This field is required"),
        cta: yup.string().required("This field is required"),
    }),
    banners: yup
        .array()
        .of(
            yup.object().shape({
                cta_type: yup.string().required("This field is required"),
                cta_action: yup.string().required("This field is required"),
                banner: yup.string().required("This field is required"),
            }),
        )
        .required(),
});

const TabFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { tab, user_id, order } = props;

        return {
            ...(tab?.id ? { id: tab.id } : {}),
            title: tab?.title || "",
            description: tab?.description || "",

            build_number: tab?.build_number || 188,

            group: tab?.group || "",
            type: tab?.type || "",
            deeplink_template: tab?.deeplink_template || "",

            last_child: tab?.last_child || {
                banner: "",
                title: "see all",
                cta: "",
            },

            header_link: tab?.header_link || {
                title: "see all",
                cta: "",
            },
            banners: tab?.banners || [
                {
                    cta_type: "external",
                    cta_action: "",
                    banner: "",
                },
            ],

            status: tab?.status || "inactive",
            order: tab?.order || order,
            // user_id,
        };
    },
    validationSchema: FormSchema,

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish(values);
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

const FormikForm = TabFormWithFormik(TabForm);

const FormikFormWithUser = (props) => {
    const { user } = useUserData();

    const namespace = "https://hasura.io/jwt/claims/";
    const user_id = user?.user_id;
    return <FormikForm {...props} user_id={user_id} />;
};

export default FormikFormWithUser;
