import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { useState, useEffect, useMemo } from "react";

interface UserData {
    user_id: number;
    role: string;
    name: string;
    email: string;
}

interface UseUserDataResult {
    user: UserData | null;
    isLoading: boolean;
    doesSessionExist: boolean;
    error: string | null;
    getItem: (key: keyof UserData | "") => any;
}

export const useUserData = (): UseUserDataResult => {
    const session = useSessionContext();
    const [user, setUser] = useState<UserData | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (session.loading) {
            setUser(null);
            return;
        }

        const sessionExists = session.doesSessionExist;

        if (sessionExists) {
            try {
                const {
                    "dashboard-gql": {
                        role,
                        id: user_id,
                        email,
                        name,
                    }
                } = session.accessTokenPayload;

                if (!role || !user_id || !email || !name) {
                    throw new Error("Missing user data in session payload");
                }

                const updatedUserData: UserData = {
                    user_id: Number(user_id),
                    role,
                    name,
                    email,
                };

                setUser(updatedUserData);
                setError(null);
            } catch (e) {
                setUser(null);
                setError(e.message);
            }
        } else {
            setUser(null);
        }
    }, [session.loading, session.doesSessionExist, session.accessTokenPayload]);

    const getItem = useMemo(
        () => (key: keyof UserData | "") => {
            if (user === null) return null;
            if (key === "") {
                return user;
            }
            return user[key];
        },
        [user],
    );

    return {
        user,
        isLoading: session.loading,
        doesSessionExist: session.doesSessionExist,
        error: error || (session.doesSessionExist ? null : "No session exists"),
        getItem,
    };
};
