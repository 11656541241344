import React from "react";
import _, { cloneDeep } from "lodash";
import { Row, Col, Form, Select, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { renderer } from "../../../../helpers";

// TODO:
// Add fields for
// droppable area ids multi select from variables.
// category lists multi select with input field for adding new values.

export const TableDragDropCellForm = (props: any) => {
    const { blockData, setBlock, tagsList } = props;
    const modifiedTagsList = tagsList?.filter((tag: any) =>
        ["0", 0, false, "false"].includes(tag?.props?.textVisible?.value),
    );
    return (
        <>
            <h2>Drag & Drop Settings:</h2>
            <Row gutter={[12, 12]}>
                {/*Switch option to enable disable drag drop */}
                <Col span={12}>
                    <Form.Item label="Drag & Drop">
                        <Switch
                            // disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={blockData?.dragDrop?.enabled}
                            onChange={(value) => {
                                let tmpCell = cloneDeep(blockData);
                                tmpCell = _.set(
                                    tmpCell,
                                    ["dragDrop", "enabled"],
                                    value,
                                );
                                setBlock(tmpCell);
                            }}
                        />
                    </Form.Item>
                </Col>
                {/*Select option for drop tag prefilled */}
                {blockData?.dragDrop?.enabled && (
                    <Col span={12}>
                        <Form.Item label="Drop Tag">
                            <Select
                                // mode="multiple"
                                style={{
                                    height: "100px",
                                    width: "100%",
                                }}
                                value={
                                    blockData?.dragDrop?.droppedSource
                                        ?.genericId
                                }
                                onChange={(value) => {
                                    let droppedSource;
                                    if (
                                        value !== undefined &&
                                        value !== "none"
                                    ) {
                                        droppedSource = cloneDeep(
                                            modifiedTagsList.find(
                                                (tag) =>
                                                    tag.genericId === value,
                                            ) || {},
                                        );
                                        droppedSource.props.textVisible.value =
                                            true;
                                    } else {
                                        droppedSource = undefined;
                                    }
                                    let tmpCell = cloneDeep(blockData);
                                    tmpCell = _.set(
                                        tmpCell,
                                        ["dragDrop", "droppedSource"],
                                        droppedSource,
                                    );
                                    setBlock(tmpCell);
                                }}
                                placeholder="Select or add tags"
                                // options={tagsList}
                            >
                                <Select.Option value={undefined} key="none">
                                    None
                                </Select.Option>
                                {modifiedTagsList?.map((tagVal) => (
                                    <Select.Option
                                        key={tagVal.genericId}
                                        value={tagVal.genericId}
                                        style={{
                                            height: "100px",
                                        }}
                                    >
                                        {renderer(tagVal.props?.text?.value)}{" "}
                                        <div>
                                            {tagVal.props?.input_background
                                                ?.value?.type === "SOLID" ? (
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            tagVal.props
                                                                ?.input_background
                                                                ?.value?.color,
                                                        width: "20px",
                                                        height: "20px",
                                                        display: "inline-block",
                                                    }}
                                                ></div>
                                            ) : (
                                                <img
                                                    src={
                                                        tagVal.props
                                                            ?.input_background
                                                            ?.value?.asset
                                                    }
                                                    width="20px"
                                                    height="20px"
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                )}
            </Row>
        </>
    );
};
