import { compose, getLastStringFromUrl } from "src/helpers";
import { getIsValidFunction } from "src/modules/worksheet/components/blocks";
import {
    withAddBlockTagMap,
    withAddWorksheetTagMap,
    withDeleteBlocks,
    withDeleteBlockTagMap,
    withDeleteWorksheetBlockMap,
    withDeleteWorksheetTagMap,
    withHookForWorksheet,
    withHookForWorksheetsStats,
    withHookForWorksheetStories,
    withInsertVersion,
    withInsertWorksheetBlockMap,
    withUpdateWorksheet,
    withUpdateWorksheetUserStats,
    withVersionModelMap,
    withWorksheetBlocks,
    withHookForWorksheetAudios,
} from "src/modules/worksheet/operations";
import { message, Modal, Typography } from "antd";
import _, { get } from "lodash";

import { useRouter } from "src/helpers";
import { useEffect, useRef, useState } from "react";
import CreateView from "../../components/WorksheetEditor";
import { findBlockByFuzzySearch } from "./helpers";
import { useUserData } from "src/helpers/supertokens";
import {
    generateAudios,
    addGenerateLogicForVarAudio,
} from "../../components/WorksheetEditor/helpers/generateAudios";
import { captureException } from "@sentry/react";

const AUTO_SAVE_INTERVAL = 30;
const AUTO_SAVE_STATUS = false;
// process.env.REACT_APP_AUTO_SAVE_STATUS == "false" ? false : true;

const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getParsedBlocks = (blocksArray: any) => {
    return blocksArray
        ?.slice()
        .sort(function (a: any, b: any) {
            return a.order - b.order;
        })
        .map(({ id: worksheet_block_map_id, block }: any) => ({
            ...block,
            children: block.children.slice().sort(function (a: any, b: any) {
                return a.order - b.order;
            }),

            worksheet_block_map_id,
        }));
};

export const getWorksheetBlockMapObject = ({
    blockData,
    worksheetData,
    worksheetBlockMap = [],
    saveTags = () => { },
}: any) => {
    return blockData.map((block: any, idx: number) => {
        const { worksheet_block_map_id, type, id } = block;

        const TAG_SUPPORT_BLOCK_TYPES = ["mcq", "subjective", "case_study"];

        if (id && TAG_SUPPORT_BLOCK_TYPES.includes(type)) {
            // save tag for each block
            const oldBlock = worksheetBlockMap.find(
                ({ block: { id: tId } }: any) => tId == id,
            )?.block;

            saveTags(block, oldBlock);
        }

        // if (!id) console.log(block.tags);
        const children =
            block.children && block.children.length
                ? {
                    children: {
                        data: block.children?.map(
                            (child: any, index: number) => {
                                // save tag for each child of block

                                if (
                                    child.id &&
                                    TAG_SUPPORT_BLOCK_TYPES.includes(
                                        child.type,
                                    )
                                ) {
                                    const oldBlock = worksheetBlockMap.find(
                                        ({ block: { id: tId } }: any) =>
                                            tId == id,
                                    )?.block;
                                    saveTags(
                                        child,
                                        oldBlock?.children?.find(
                                            ({ id: oId }: any) =>
                                                oId == child.id,
                                        ),
                                    );
                                }

                                return {
                                    ..._.omit(child, [
                                        "tmpId",
                                        "__typename",
                                        "children",
                                        "tags",
                                    ]),
                                    ...(!child.id &&
                                        child.tags &&
                                        child.tags.length
                                        ? {
                                            tags: {
                                                data: child.tags.map(
                                                    ({
                                                        value,
                                                        tag_id,
                                                        tag,
                                                    }: any) => ({
                                                        tag_id: value
                                                            ? JSON.parse(
                                                                value,
                                                            ).id
                                                            : tag_id,
                                                    }),
                                                ),
                                            },
                                        }
                                        : {}),
                                    order: index + 1,
                                };
                            },
                        ),
                        on_conflict: {
                            constraint: "block_pkey",
                            update_columns: ["data", "type", "order"],
                        },
                    },
                }
                : {};

        return {
            ...(worksheet_block_map_id ? { id: worksheet_block_map_id } : {}),
            order: idx + 1,
            ...worksheetData,
            block: {
                data: {
                    ...children,
                    ..._.omit(block, [
                        "tmpId",
                        "worksheet_block_map_id",
                        "__typename",
                        "children",
                        "tags",
                    ]),

                    ...(!id && block.tags && block.tags.length
                        ? {
                            tags: {
                                data: block.tags.map(
                                    ({ value, tag_id }: any) => ({
                                        tag_id: value
                                            ? JSON.parse(value).id
                                            : tag_id,
                                    }),
                                ),
                            },
                        }
                        : {}),
                },
                on_conflict: {
                    constraint: "block_pkey",
                    update_columns: ["data", "type"],
                },
            },
        };
    });
};

const Edit = (props: any) => {
    const {
        updateWorksheet,
        worksheetBlockMap,
        refetchWorksheetBlockMap,
        loadingWorksheetMap,
        insertWorksheetBlockMap,
        deleteWorksheetBlockMap,
        deleteBlocks,
        addBlockTagMap,
        deleteBlockTagMap,
        addWorksheetTagMap,
        deleteWorksheetTagMap,
        worksheet,
        insertVersion,
        versionModelMap,
        loadingVersionModelMap,
        refetchVersionModelMap,
        updateWorksheetUserStats,
        worksheetStats,
        worksheetStatsLoading,
        storyJobs,
        storyJobsLoading,
        audioJobs,
        audioJobsLoading,
    } = props;

    const router = useRouter();
    const {
        query: { blockId, blockText, curSubBlock, redirect_to },
    } = router;
    let worksheet_id = worksheet?.id || getLastStringFromUrl();

    const [isSaving, toggleSaving] = useState(false);
    const [shouldBlockUI, toggleShouldBlockUI] = useState(false);

    const [saveCounter, setSaveCounter] = useState(0);
    const [lastSavedAt, setLastSavedAt] = useState<any>(null);
    const [currentDateTime, setCurrentDateTime] = useState<any>(null);

    const [currentBlock, setCurrentBlock] = useState<any>(null);
    const [currentSubBlock, setCurrentSubBlock] = useState<any>(null);
    const [currentChunkBlock, setCurrentChunkBlock] = useState<any>(null);

    const [hasChanged, toggleHasChanged] = useState(false);
    const [canPublish, toggleCanPublish] = useState(false);

    const [saveVal, setSaveVal] = useState(false);

    const { user } = useUserData();

    const worksheetStatsLoadingRef = useRef(worksheetStatsLoading);
    const worksheetStatsRef = useRef(worksheetStats);

    useEffect(() => {
        if (worksheet?.type === "personalized_learning_v4") {
            let query = router.query;
            delete query.worksheet_id;
            router.push({
                pathname: `/worksheet/update/${worksheet?.id}`,
                query,
            });
        }
    }, [worksheet, router]);

    useEffect(() => {
        worksheetStatsLoadingRef.current = worksheetStatsLoading;
        worksheetStatsRef.current = worksheetStats;
    }, [worksheetStatsLoading, worksheetStats]);

    useEffect(() => {
        if (!storyJobsLoading && storyJobs?.length) {
            message.info("Story fetched. Check them out!");
        }
    }, [storyJobs, storyJobsLoading]);

    useEffect(() => {
        if (!audioJobsLoading && audioJobs?.length) {
            message.info("Audio fetched. Check them out!");
        }
    }, [audioJobs, audioJobsLoading]);

    useEffect(() => {
        // Every 10s, run a mutation to tell the backend that you're online
        let onlineIndicator: string | number | NodeJS.Timeout | undefined;
        let v = setTimeout(() => {
            updateLastSeen(
                worksheetStatsLoadingRef.current,
                worksheetStatsRef.current,
            );
            onlineIndicator = setInterval(
                () =>
                    updateLastSeen(
                        worksheetStatsLoadingRef.current,
                        worksheetStatsRef.current,
                    ),
                10000,
            );
        }, 2000);

        return () => {
            // Clean up
            onlineIndicator && clearInterval(onlineIndicator);
            clearTimeout(v);
        };
    }, []);

    const updateLastSeen = async (
        worksheetStatsLoading: boolean,
        worksheetStats: { other: any },
    ) => {
        if (user && !worksheetStatsLoading) {
            const {
                email,
                user_id,
                name,
            } = user;

            let insertObject = _.cloneDeep(worksheetStats?.other || {});

            if (insertObject?.active_users) {
                const { active_users } = insertObject;

                let isEditorActive = true;
                let isUserPresent = false;

                active_users.forEach(
                    (element: {
                        user_id: any;
                        is_editor: boolean;
                        last_seen: string | number | Date;
                    }) => {
                        if (user_id === element.user_id) isUserPresent = true;
                        if (element.is_editor) {
                            const activeTime = new Date(
                                element.last_seen,
                            ).getTime();
                            const currentTime = new Date().getTime();
                            const timeDifferenceSecs = Math.floor(
                                (currentTime - activeTime) / 1000,
                            );

                            if (timeDifferenceSecs > 60) {
                                isEditorActive = false;
                            }
                        }
                    },
                );

                let tempActiveUsers = _.cloneDeep(active_users);

                if (!isUserPresent) {
                    tempActiveUsers.push({
                        user_id,
                        email,
                        name,
                        last_seen: new Date().toISOString(),
                        is_editor: false,
                    });
                } else {
                    tempActiveUsers = tempActiveUsers.map(
                        (item: { user_id: any; last_seen: any }) => {
                            return {
                                ...item,
                                last_seen:
                                    user_id === item.user_id
                                        ? new Date().toISOString()
                                        : item.last_seen,
                            };
                        },
                    );
                }

                tempActiveUsers = tempActiveUsers.sort(
                    (
                        a: {
                            last_seen: string | number | Date;
                            is_editor: boolean;
                        },
                        b: {
                            last_seen: string | number | Date;
                            is_editor: boolean;
                        },
                    ) => {
                        if (a.is_editor && !b.is_editor) {
                            return -1; // a is an editor, b is not an editor (a comes first)
                        } else if (!a.is_editor && b.is_editor) {
                            return 1; // a is not an editor, b is an editor (b comes first)
                        } else {
                            const timeA = new Date(a.last_seen).getTime();
                            const timeB = new Date(b.last_seen).getTime();
                            return timeB - timeA; // Sort based on last_seen in descending order (latest on top)
                        }
                    },
                );

                if (!isEditorActive) {
                    tempActiveUsers = tempActiveUsers.map(
                        (item: { user_id: any }) => {
                            return {
                                ...item,
                                is_editor: user_id === item.user_id,
                            };
                        },
                    );
                }

                insertObject.active_users = tempActiveUsers;
            } else {
                insertObject = {
                    active_users: [
                        {
                            user_id,
                            email,
                            name,
                            last_seen: new Date().toISOString(),
                            is_editor: true,
                        },
                    ],
                };
            }

            await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
        }
    };

    const setActiveEditor = async () => {
        const worksheetStatsLoading: boolean = worksheetStatsLoadingRef.current;
        const worksheetStats: { other: any } = worksheetStatsRef.current;

        if (user && !worksheetStatsLoading) {
            const {
                email,
                user_id,
                name,
            } = user;

            let insertObject = _.cloneDeep(worksheetStats?.other || {});

            if (insertObject?.active_users) {
                const { active_users } = insertObject;

                let isUserPresent = active_users.some(
                    (element: {
                        user_id: any;
                        is_editor: boolean;
                        last_seen: string | number | Date;
                    }) => {
                        return user_id === element.user_id;
                    },
                );

                let tempActiveUsers = _.cloneDeep(active_users);

                if (!isUserPresent) {
                    tempActiveUsers.push({
                        user_id,
                        email,
                        name,
                        last_seen: new Date().toISOString(),
                        is_editor: true,
                    });
                } else {
                    tempActiveUsers = tempActiveUsers.map(
                        (item: { user_id: any; last_seen: any }) => {
                            return {
                                ...item,
                                is_editor: user_id === item.user_id,
                            };
                        },
                    );
                }

                insertObject.active_users = tempActiveUsers;
            } else {
                insertObject = {
                    ...insertObject,
                    active_users: [
                        {
                            user_id,
                            email,
                            name,
                            last_seen: new Date().toISOString(),
                            is_editor: true,
                        },
                    ],
                };
            }

            await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
        }
    };

    const setNewJob = async ({ path, job_id, name }) => {
        const worksheetStatsLoading: boolean = worksheetStatsLoadingRef.current;
        const worksheetStats: { other: any } = worksheetStatsRef.current;
        if (!worksheetStatsLoading) {
            let insertObject = _.cloneDeep(worksheetStats?.other || {});
            if (insertObject?.story_jobs) {
                const { story_jobs } = insertObject;

                let tempJobs = _.cloneDeep(story_jobs);

                tempJobs.unshift({
                    name,
                    path,
                    job_id,
                    is_sync: false,
                });

                insertObject.story_jobs = tempJobs;
            } else {
                insertObject = {
                    ...insertObject,
                    story_jobs: [{ name, path, job_id, is_sync: false }],
                };
            }
            await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
        }
    };

    const setNewAudioJob = async ({ job_id }) => {
        const worksheetStatsLoading: boolean = worksheetStatsLoadingRef.current;
        const worksheetStats: { other: any } = worksheetStatsRef.current;
        if (!worksheetStatsLoading) {
            let insertObject = _.cloneDeep(worksheetStats?.other || {});
            if (insertObject?.audio_jobs) {
                const { audio_jobs } = insertObject;

                let tempJobs = _.cloneDeep(audio_jobs);

                tempJobs.unshift({
                    job_id,
                    is_sync: false,
                });

                insertObject.audio_jobs = tempJobs;
            } else {
                insertObject = {
                    ...insertObject,
                    audio_jobs: [
                        {
                            job_id,
                            is_sync: false,
                        },
                    ],
                };
            }
            await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
        }
    };

    const updateStoriesJob = async ({ job_id, is_delete }: any) => {
        const worksheetStatsLoading: boolean = worksheetStatsLoadingRef.current;
        const worksheetStats: { other: any } = worksheetStatsRef.current;
        if (!worksheetStatsLoading) {
            let insertObject = _.cloneDeep(worksheetStats?.other || {});
            if (insertObject?.story_jobs) {
                const { story_jobs } = insertObject;

                let tempJobs = _.cloneDeep(story_jobs);

                tempJobs = is_delete
                    ? tempJobs.filter((v) => v.job_id != job_id)
                    : tempJobs.map((v) => {
                        if (v.job_id == job_id) {
                            v.is_sync = true;
                        }
                        return v;
                    });
                insertObject.story_jobs = tempJobs;
            }
            if (!is_delete) setSaveVal(true);
            await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
        }
        return true;
    };

    const updateAudiosJob = async ({ job_id, is_delete }: any) => {
        const worksheetStatsLoading: boolean = worksheetStatsLoadingRef.current;
        const worksheetStats: { other: any } = worksheetStatsRef.current;
        if (!worksheetStatsLoading) {
            let insertObject = _.cloneDeep(worksheetStats?.other || {});
            if (insertObject?.audio_jobs) {
                const { audio_jobs } = insertObject;

                let tempJobs = _.cloneDeep(audio_jobs);

                tempJobs = is_delete
                    ? tempJobs.filter((v) => v.job_id != job_id)
                    : tempJobs.map((v) => {
                        if (v.job_id == job_id) {
                            v.is_sync = true;
                        }
                        return v;
                    });
                insertObject.audio_jobs = tempJobs;
            }
            // if (!is_delete)setSaveVal(true);
            await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
        }
        return true;
    };

    useEffect(() => {
        if (saveVal) {
            onSave({});
            setSaveVal(false);
        }
    }, [saveVal]);

    const version =
        versionModelMap &&
        versionModelMap[0]?.versions_aggregate?.aggregate?.max?.index + 1; // versionModelMap

    const setParsedBlocks = (blocksArray: any) => {
        if (blocksArray.length) {
            const parsedBlocks = getParsedBlocks(blocksArray);
            setBlocks(parsedBlocks || []);
            if (!currentBlock) {
                setCurrentBlock(0);
            }
            return parsedBlocks;
        }
        return [];
    };

    const [blocks, setBlocks] = useState<any>([]);

    const checkInputVarExist = (value: string) =>
        blocks?.some(
            ({
                type,
                children,
            }: {
                type: string;
                children: {
                    data: {
                        paths: {
                            children: {
                                variants: {
                                    block: {
                                        tmpId: any;
                                        id: any;
                                    };
                                }[];
                            }[];
                        }[];
                    };
                }[];
            }) =>
                type === "progression_stage" &&
                children?.some(({ data }) =>
                    data?.paths?.some(({ children }) =>
                        children?.some(({ variants }) =>
                            variants?.some(({ block }) => {
                                return (
                                    value &&
                                    (block?.tmpId === value ||
                                        block?.id === value)
                                );
                            }),
                        ),
                    ),
                ),
        );

    function checkSameKeyValues(array1: any[], array2: any[], key: string) {
        const values1 = array1.map((obj) => obj[key]);
        const values2 = array2.map((obj) => obj[key]);

        const commonValues = values1.filter((value) => values2.includes(value));

        return commonValues.length > 0;
    }

    const onInputVariableUpdate = async (data: {
        values: any[];
        block_id?: string;
        block_tmpId?: string;
        variable_name?: string;
        type?: string;
    }) => {
        const {
            values = [],
            block_id = "",
            type,
            variable_name,
            block_tmpId = "",
        } = data;

        const inferred_variables = worksheet?.other?.inferred_variables || [];
        const input_variables = worksheet?.other?.input_variables || [];

        if (type === "inferred") {
            const variables = [...inferred_variables].filter(
                (item) =>
                    item?.variable_name &&
                    item?.variable_name !== variable_name,
            );

            const checkValue = checkSameKeyValues(
                [...variables, ...input_variables],
                values,
                "variable_name",
            );

            if (checkValue) {
                message.error("Variables already exist!");
                return false;
            } else {
                await onFinish({
                    id: worksheet_id,
                    other: {
                        ...worksheet?.other,
                        inferred_variables: [...variables, ...values],
                    },
                });
                return true;
            }
        } else {
            const variables = [...input_variables].filter(
                (item) =>
                    item?.block_id &&
                    item?.block_id !== block_id &&
                    item?.block_id !== block_tmpId &&
                    item?.variable_name &&
                    item?.variable_name !== variable_name,
            );

            const checkValue = checkSameKeyValues(
                [...variables, ...inferred_variables],
                values,
                "variable_name",
            );

            if (checkValue) {
                message.error("Variables already exist!");
                return false;
            } else {
                await onFinish({
                    id: worksheet_id,
                    other: {
                        ...worksheet?.other,
                        input_variables: [...variables, ...values],
                    },
                });
                return true;
            }
        }
    };

    const onFinish = async (values: any) => {
        await updateWorksheet({
            object: _.omit(values, ["tags"]),
            id: values.id,
        });

        const tagValues = get(values, ["tags", "data"]);

        if (!tagValues) return;

        const newTags = tagValues.map((tag: any) => tag.tag_id);
        const oldTagIds = worksheet.tags.map((tag: any) => tag.tag.id);
        // console.log(newTags, oldTagIds, values);

        const tagsToAdd = newTags.filter(
            (newTag: any) => oldTagIds.indexOf(newTag) == -1,
        );
        const tagsToRemove = oldTagIds.filter(
            (newTag: any) => newTags.indexOf(newTag) == -1,
        );

        // console.log("newTags", newTags, oldTagIds, tagsToAdd, tagsToRemove);

        if (tagsToAdd.length)
            await addWorksheetTagMap({
                objects: tagsToAdd.map((tag: any) => ({
                    tag_id: tag,
                    worksheet_id: worksheet.id,
                })),
            });

        if (tagsToRemove.length)
            await deleteWorksheetTagMap({
                ids: worksheet.tags
                    .filter((tag: any) => tagsToRemove.includes(tag.tag.id))
                    .map((tag: any) => tag.id),
            });

        // router.push(redirect_to);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    const saveTags = async (newBlock: any, oldBlock: any) => {
        const newTags = get(newBlock, ["tags"])?.map((tag: any) =>
            tag.value ? JSON.parse(tag.value).id : tag.tag.id,
        );
        const oldTagIds = oldBlock?.tags.map((tag: any) => tag.tag.id) || [];
        // console.log(newTags, oldTagIds, newBlock);

        const tagsToAdd =
            newTags
                ?.filter((newTag: any) => oldTagIds.indexOf(newTag) == -1)
                ?.filter(function (element: any) {
                    return element !== undefined;
                }) || [];
        const tagsToRemove =
            oldTagIds
                ?.filter((newTag: any) => newTags.indexOf(newTag) == -1)
                ?.filter(function (element: any) {
                    return element !== undefined;
                }) || [];

        // console.log("newTags", newTags, oldTagIds, tagsToAdd, tagsToRemove);

        if (tagsToAdd.length)
            await addBlockTagMap({
                objects: tagsToAdd.map((tag: any) => ({
                    tag_id: tag,
                    block_id: newBlock.id,
                })),
            });
        if (tagsToRemove.length) {
            // console.log(newBlock.tags, newTags, oldTagIds, tagsToRemove);

            await deleteBlockTagMap({
                ids: oldBlock.tags
                    .filter((tag: any) => tagsToRemove.includes(tag.tag.id))
                    .map((tag: any) => tag.id),
            });
        }
    };

    const validateBlock = (blockObj: any) => {
        const { type } = blockObj;

        const isValidFunction =
            (type && getIsValidFunction(type)) ||
            (() => ({
                isValid: true,
            }));
        const { isValid }: any = isValidFunction({
            block: blockObj,
            worksheet,
        });
        return isValid;
    };

    const validateBlocks = () => {
        const arr = blocks.map((blockObj: any) => {
            const tmpArr = [];

            tmpArr.push(validateBlock(blockObj));

            if (blockObj.children && blockObj.children.length) {
                blockObj.children.map((child: any) => {
                    tmpArr.push(validateBlock(child));
                });
            }
            return tmpArr;
        });
        return arr.flat();
    };

    const saveVersion = async ({
        blockData,
        version = 0,
        versionModelMapOverride = {},
    }: any) => {
        let versionModelMapData = {};
        const versionModelMapCurrent = versionModelMapOverride.length
            ? versionModelMapOverride
            : versionModelMap;
        if (!versionModelMapCurrent.length) {
            versionModelMapData = {
                version_model_map: {
                    data: {
                        model_name: "worksheet",
                        model_id: worksheet.id,
                    },
                },
            };
        } else {
            versionModelMapData = {
                version_model_map_id: versionModelMapCurrent[0].id,
            };
        }

        await insertVersion({
            ...versionModelMapData,
            index: version,
            data: blockData,
            ...(!version && versionModelMapCurrent.length
                ? { id: versionModelMapCurrent[0].versions[0].id }
                : {}),
        });

        const { data } = await refetchVersionModelMap();
        return data.versionModelMap;
    };

    const saveBlocks = async ({
        currentBlocks,
        publish,
        worksheet_type,
    }: any) => {
        let blockData = currentBlocks || blocks;
        if (publish) {
            const objects: any = getWorksheetBlockMapObject({
                blockData,
                worksheetData: { worksheet_id },
                worksheetBlockMap,
                saveTags,
            });

            const existingWorksheetBlockMapIds = objects
                .map(({ id }: any) => id)
                .filter((n: any) => n);
            const allWorksheetBlockMapIds = worksheetBlockMap.map(
                ({ id }: any) => id,
            );
            const missingWorksheetBlockMapIds = allWorksheetBlockMapIds.filter(
                (x: any) => !existingWorksheetBlockMapIds.includes(x),
            );

            const allChildrenBlockIds = worksheetBlockMap
                .map(({ block: { children } }: any) =>
                    children.map(({ id }: any) => id),
                )
                .flat();

            const existingChildrenBlockIds = objects
                .map(({ block: { data } }: any) => {
                    const { children } = data;

                    if (children) {
                        const { data } = children;
                        return data?.map(({ id }: any) => id);
                    }
                    return null;
                })
                .flat()
                .filter((n: any) => n);

            const missingChildrenBlockIds = allChildrenBlockIds.filter(
                (x: any) => !existingChildrenBlockIds.includes(x),
            );

            if (missingChildrenBlockIds.length)
                await deleteBlocks({ ids: missingChildrenBlockIds });
            if (missingWorksheetBlockMapIds.length)
                await deleteWorksheetBlockMap({
                    ids: missingWorksheetBlockMapIds,
                });

            await insertWorksheetBlockMap({ objects });

            const {
                data: { worksheetBlockMap: newWorksheetBlockMap },
            } = await refetchWorksheetBlockMap();

            blockData = setParsedBlocks(newWorksheetBlockMap);

            // axios.post(process.env.REACT_APP_WORKSHEET_TO_SET_API || "", {
            //     data: {
            //         worksheet_id,
            //         version: version + 1,
            //         worksheet_type,
            //     },
            // });
        }

        // START: handle versioning

        // TODO: if publish save what publish returns, or make blockData, what publish returns
        const versionModelMapOverride = await saveVersion({ blockData });
        if (!publish) return;
        else {
            await saveVersion({
                blockData,
                version:
                    versionModelMapOverride &&
                    versionModelMapOverride[0]?.versions_aggregate?.aggregate
                        ?.max?.index + 1,
                versionModelMapOverride,
            });
        }
        // END: handle versioning
    };

    const onSave = async ({
        showMessage = true,
        currentBlocks = null,
        publish = false,
        isSaveButton = false,
    }: any) => {
        let retVal = true;
        if (!isSaving && blocks.length) {
            toggleSaving(true);
            try {
                if (showMessage) toggleShouldBlockUI(true);
                const newCurrentBlocks = [
                    "personalized_learning_v3",
                    "personalized_learning_v4",
                ].includes(worksheet.type)
                    ? await addGenerateLogicForVarAudio(blocks)
                    : blocks;
                if (
                    (publish || isSaveButton) &&
                    [
                        "personalized_learning_v3",
                        "personalized_learning_v4",
                    ].includes(worksheet.type)
                ) {
                    const { job_id, error } = await generateAudios(
                        newCurrentBlocks,
                    );

                    if (job_id) {
                        setNewAudioJob({ job_id });
                    }
                    if (error) {
                        message.error(
                            `Failed to generate audio for worksheet!`,
                        );

                        console.log("Error audio:", error);
                        return false;
                    }
                }
                await Promise.all([
                    saveBlocks({
                        currentBlocks: newCurrentBlocks,
                        publish,
                        worksheet_type: worksheet.type,
                    }),
                    delay(1000),
                ]);
                setLastSavedAt(new Date());

                if (showMessage) {
                    message.success(`Successfully updated worksheet!`);
                }
            } catch (e) {
                captureException(e);
                console.log("Error on save:", e);
                if (showMessage)
                    message.error(
                        `Something went wrong! try again in a couple of seconds`,
                    );
                retVal = false;
            }

            toggleHasChanged(false);
            toggleShouldBlockUI(false);
            toggleSaving(false);
        } else if (isSaving) {
            toggleHasChanged(false);
            toggleSaving(false);
            toggleShouldBlockUI(false);
        }
        return retVal;
    };

    useEffect(() => {
        setCurrentDateTime(new Date());

        if (AUTO_SAVE_STATUS) {
            const interval2 = setInterval(() => {
                setCurrentDateTime(new Date());
            }, 5000);

            const interval1 = setInterval(
                () => setSaveCounter((prevSaveCounter) => prevSaveCounter + 1),
                AUTO_SAVE_INTERVAL * 1000,
            );
            return () => {
                clearInterval(interval1);
                clearInterval(interval2);
            };
        }
    }, []);

    useEffect(() => {
        if (saveCounter && hasChanged) onSave({ showMessage: false });
    }, [saveCounter]);

    // useEffect(() => {
    //     if (worksheetBlockMap) {
    //         setParsedBlocks(worksheetBlockMap);
    //     }
    // }, [worksheetBlockMap]);

    useEffect(() => {
        if (!loadingVersionModelMap && !loadingWorksheetMap) {
            if (versionModelMap.length) {
                const { data = [] } = versionModelMap?.[0]?.versions?.[0] || {};
                setBlocks(data);

                if (!currentBlock && data.length) {
                    // console.log(
                    //     data.findIndex(({ id }) => id == parseInt(blockId)),
                    // );
                    if (blockId) {
                        const blockIndx = data.findIndex(
                            ({ id }) => id === parseInt(blockId),
                        );
                        if (blockIndx < 0) {
                            const fuzzySearchBlockIndex =
                                findBlockByFuzzySearch(data, blockText);
                            if (fuzzySearchBlockIndex < 0)
                                message.info(
                                    `Block doesnt exist in this worksheet anymore`,
                                );
                            else setCurrentBlock(fuzzySearchBlockIndex);
                        } else setCurrentBlock(blockIndx);
                    } else setCurrentBlock(0);
                }

                if (curSubBlock && data[parseInt(curSubBlock)]) {
                    setCurrentBlock(parseInt(curSubBlock));
                    setCurrentSubBlock(0);
                    setCurrentChunkBlock(null);
                }
            } else if (worksheetBlockMap.length) {
                setParsedBlocks(worksheetBlockMap);
            }
        }
    }, [loadingVersionModelMap, loadingWorksheetMap]);

    useEffect(() => {
        if (versionModelMap && worksheetBlockMap) {
            // should be able to publish
            if (versionModelMap.length) {
                const { data } = versionModelMap?.[0]?.versions?.[0] || {};

                const publishedBlocks = getParsedBlocks(worksheetBlockMap);

                if (!_.isEqual(publishedBlocks, data)) toggleCanPublish(true);
                else toggleCanPublish(false);
            }
        }
    }, [versionModelMap, worksheetBlockMap]);

    const createViewProps = {
        onFinish,
        onFinishFailed,
        redirect_to,
        isSaving,
        blocks,
        setBlocks: (blocksArray: any) => {
            setBlocks(blocksArray);
            toggleHasChanged(true);
        },
        onSave,
        currentBlock,
        setCurrentBlock: (blockIndex: number) => {
            setCurrentBlock(blockIndex);
            setCurrentSubBlock(null);
            setCurrentChunkBlock(null);
        },
        worksheet_id,
        currentSubBlock,
        setCurrentSubBlock,
        currentChunkBlock,
        setCurrentChunkBlock,
        lastSavedAt,
        currentDateTime,
        setSaveCounter: ({ currentBlocks }: any) => {
            onSave({ showMessage: true, currentBlocks });
        },
        shouldBlockUI,
        toggleShouldBlockUI,
        validateBlocks,
        version,
        hasChanged,
        toggleHasChanged,
        canPublish,

        onInputVariableUpdate,
        checkInputVarExist,

        activeUsers: worksheetStats?.other?.active_users,
        storyJobs,
        worksheetStoryJobs: worksheetStats?.other?.story_jobs || [],
        setActiveEditor,
        setNewJob,
        updateStoriesJob,
        audioJobs,
        worksheetAudioJobs: worksheetStats?.other?.audio_jobs || [],
        updateAudiosJob,
        setNewAudioJob,
    };

    const [isOpen, setIsOpen] = useState(false);
    if (window && history) {
        window.onpopstate = function () {
            setIsOpen(true);
        };
        history.pushState({}, "");
    }

    const handleClose = async () => setIsOpen(false);

    if (loadingVersionModelMap || loadingWorksheetMap) return <></>;
    return (
        <>
            <Modal
                open={isOpen}
                title="Are you sure you want to go back?"
                onOk={() => {
                    handleClose();
                    router.push(redirect_to);
                }}
                onCancel={handleClose}
            >
                <Typography.Text>
                    You'll lose any unsaved changes
                </Typography.Text>
            </Modal>
            <CreateView {...props} {...createViewProps} />
        </>
    );
};

export default compose(
    withHookForWorksheet,
    withUpdateWorksheet,
    withWorksheetBlocks,
    withInsertWorksheetBlockMap,
    withDeleteWorksheetBlockMap,
    withDeleteBlocks,
    withAddBlockTagMap,
    withDeleteBlockTagMap,
    withAddWorksheetTagMap,
    withDeleteWorksheetTagMap,
    withInsertVersion,
    withVersionModelMap,
    withUpdateWorksheetUserStats,
    withHookForWorksheetsStats,
    withHookForWorksheetStories,
    withHookForWorksheetAudios,
)(Edit);
