import React from "react";
import { getEditor, renderBlock } from "../../../../blocks/index";
import { Can } from "src/services/casl";

export const DataColumn = (props: any) => {
    const {
        // worksheet,
        block,
        // currentSubBlock,
        // currentBlock,
        setIsEditor,
        isReadOnlyMode,
    } = props;

    if (!block) return <></>;

    return (
        <div onClick={() => setIsEditor(false)}>
            <Can I="edit" a={"worksheet_block"} passThrough>
                {(allowed: boolean) => (
                    <Can I="read" a={"worksheet_block"} passThrough>
                        {(allowedRead: boolean) =>
                            allowed || allowedRead ? (
                                <div style={{ margin: 0, padding: 0 }}>
                                    {getEditor({
                                        ...props,
                                        allowedRead,
                                        disableWrite:
                                            !allowed || isReadOnlyMode,
                                    })}
                                </div>
                            ) : (
                                <div style={{ margin: "24px 30px" }}>
                                    {renderBlock(block)}
                                </div>
                            )
                        }
                    </Can>
                )}
            </Can>
        </div>
    );
};
