import React, { useMemo } from "react";
import TableRow, { TableRowLabels } from "./TableRow";
import { generateBorderConfig } from "./helpers";

function getAlignmentProperty(align: string) {
    switch (align) {
        case "LEFT":
            return "self-start";
        case "RIGHT":
            return "self-end";
        case "CENTER":
            return "center";
        default:
            return "self-start";
    }
}

const TablePreviewComponent = (props: any) => {
    const { tableData, showMeasures } = props;

    if (!tableData) {
        return null;
    }

    const customizations = {
        border: tableData?.border?.enabled && tableData?.border,
        gap: tableData?.gap?.enabled && tableData?.gap,
        reorder:
            tableData?.reorder?.type &&
            tableData?.reorder?.type !== "NONE" &&
            tableData?.reorder,
        swipable: tableData?.swipable?.enabled && tableData?.swipable,
        minMaxWidth: tableData?.minMaxWidth,
        minHeightCustomization: tableData?.minHeight,
        align: tableData?.align,
        padding: tableData?.padding?.enabled && tableData?.padding,
    };

    const tableChildProps = {
        borderCustomizations: customizations.border,
        minMaxWidth: customizations.minMaxWidth,
        minHeightCustomization: customizations.minHeightCustomization,
        paddingCustomization: customizations.padding,
    };

    const tableFeedbackBorder = tableData.highlightBorder
        ? generateBorderConfig(tableData.highlightBorder)
        : {};

    const tableAlignmentStyle = useMemo(() => {
        const alignmentProperty = getAlignmentProperty(customizations?.align);
        return {
            display: "grid",
            placeItems: alignmentProperty,
        };
    }, [customizations.align]);

    return (
        <div
            style={{
                height: "auto",
                width: "100%",
                overflowX: "auto",
                ...tableFeedbackBorder,
                ...tableAlignmentStyle,
                position: "relative",
            }}
        >
            {showMeasures && <TableRowLabels tableData={tableData} />}
            <table
                className="renderer-table"
                id="table-preview-renderer"
                style={{
                    borderSpacing: customizations.gap
                        ? `${customizations.gap.horizontal}px ${customizations.gap.vertical}px`
                        : "0px",
                }}
            >
                <tbody
                    id = "table-preview-renderer-body"
                >
                    
                    {tableData.cells?.map((row: any, rowIndex: number) => {
                        return (
                            <TableRow
                                rowProps={tableChildProps}
                                rowIndex={rowIndex}
                                row={row}
                                key={rowIndex}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TablePreviewComponent;
