import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";

Sentry.init({
    dsn: "https://10b516998e53f4c1d2093b5dae027e66@o503779.ingest.us.sentry.io/4507379020201984",
    integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    beforeSend: (event, hint) => {
        if (!process.env.REACT_APP_SENTRY_PROJECT) return null;
        let isRestrictedError = false;
        const restrictedErrors = [
            "Cannot read properties of null (reading 'removeChild')",
            "Error: Failed to fetch",
        ];

        const iterable = [
            "Network Error",
            "Failed to fetch",
            "Request failed with status code 400",
            "Cannot destructure property 'pathname' of 'href' as it is undefined.",
            // "Converting circular structure to JSON",
            'Cannot apply an incomplete "set_selection" operation properties',
            "Cannot find a descendant at path [",
        ];

        event?.exception?.values?.forEach((error) => {
            if (
                error?.value &&
                (restrictedErrors.includes(error?.value) ||
                    iterable.some((v) => error?.value?.includes(v)))
            )
                isRestrictedError = true;
        });

        if (isRestrictedError) return null;
        return event;
    },
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    //tracePropagationTargets: [/^\//, /^https: \/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});
