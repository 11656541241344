import React from "react";
import { Row, Col, Form, Button } from "antd";
import { withFormik } from "formik";
import FormikPersist from "src/components/FormikPersist";
import { Field, RenderField, RenderSelect } from "src/components";
import { Select } from "antd";
import { useUserData } from "src/helpers/supertokens";

import { useRouter } from "src/helpers";
const Option = Select.Option;

const ChapterForm = (props) => {
    const { onFinishFailed, values, handleSubmit, isSubmitting } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={24} md={24}>
                    <Field
                        label="Chapter Title"
                        placeholder="Chapter Title"
                        name={"title"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.title}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Chapter Description"
                        placeholder="Chapter Description"
                        name={"description"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.description}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Chapter Banner"
                        placeholder="Chapter Banner"
                        name={"cover"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.cover}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Chapter Set Count"
                        placeholder="Chapter Set Count"
                        name={"other.set_count"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.other.set_count}
                        rules={[{ required: true }]}
                        disabled={true}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Chapter Number"
                        placeholder="Chapter Number"
                        name={"other.chapter_number"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.other.chapter_number}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Chapter Status"
                        placeholder="Chapter Status"
                        name={"status"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.status}
                        rules={[{ required: true }]}
                    >
                        <Option value="inactive">Inactive</Option>
                        <Option value="active">Active</Option>
                    </Field>
                </Col>

                <Col lg={12} md={24}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

const ChapterFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { chapter, book_id } = props;

        return {
            ...(chapter?.id ? { id: chapter.id } : {}),
            title: chapter?.title || "",
            description: chapter?.description || "",
            cover: chapter?.cover || "",
            // other
            other: {
                set_count: chapter?.other?.set_count || 0,
                chapter_number: chapter?.other?.chapter_number || 0,
            },

            status: chapter?.status || "inactive",
            type: "chapter",
            parent_id: parseInt(book_id),
            // created_by: props.user_id,
        };
    },

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish(values);
        // console.log(values)
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

const FormikForm = ChapterFormWithFormik(ChapterForm);
const FormikFormWithUser = (props) => {
    const { query } = useRouter();
    const { book_id } = query;

    const { user } = useUserData();

    const user_id = user?.user_id;
    return <FormikForm {...props} user_id={user_id} book_id={book_id} />;
};

export default FormikFormWithUser;
