import {
    AppstoreAddOutlined,
    CompressOutlined,
    FolderAddOutlined,
    MenuOutlined,
    PlayCircleOutlined,
} from "@ant-design/icons";
import { ApolloConsumer, gql, useLazyQuery } from "@apollo/client";
import { useUserData } from "src/helpers/supertokens";

import {
    DeleteIcon,
    EditIcon,
    EllipseIcon,
    Layout,
    MetaTag,
    RenderTable,
    ViewIcon,
} from "src/components";
import { getLastStringParamFromUrl } from "src/helpers/functions";
import { FilterComponent } from "src/modules/collection/components";
import DeleteView from "src/modules/collection/components/DeleteView";
import { SetsAssignComponent } from "src/modules/set/components";
import { USER_QUERY } from "src/modules/user/graphql";
import { Can } from "src/services/casl";
import {
    Button,
    Col,
    Divider,
    Dropdown,
    Menu,
    message,
    Modal,
    Row,
    Space,
    Spin,
    Table,
    Tag,
} from "antd";
import { arrayMoveImmutable } from "array-move";
import _ from "lodash";
import { Link } from "src/components";
import { useRouter } from "src/helpers";
import Papa from "papaparse";
import React, { useEffect, useRef, useState } from "react";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import * as XLSX from "xlsx";

const AssignSetModal = ({
    collection,
    createSetCollection,
    deleteSetCollection,
    incrementSetDuration,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const initialValue = collection?.sets?.map(
        ({ set: { id, title } }, index) => ({
            value: id,
            key: index,
            label: title,
        }),
    );

    const [selected, setSelected] = React.useState(initialValue);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleChange = (value) => {
        setSelected(value);
    };

    const showModal = () => {
        setIsModalVisible(true);
        setTimeout(() => {
            setDropdownOpen(true);
        }, 300);
    };

    const handleOk = async () => {
        const setIds = collection.sets.map(({ set }) => set.id);

        function randomIntFromInterval(min, max) {
            // min and max included
            return Math.floor(Math.random() * (max - min + 1) + min);
        }

        let removedSetIds = [...setIds];
        await Promise.all(
            selected.map(async (set) => {
                if (setIds.includes(set.value)) {
                    console.log(`set ${set.value}, already assigned`);
                    removedSetIds = removedSetIds.filter(
                        (id) => id !== set.value,
                    );
                } else {
                    console.log(`set ${set.value}, is being assigned`);
                    await createSetCollection({
                        collection_id: collection.id,
                        set_id: set.value,
                        order: randomIntFromInterval(1, 1000),
                    });
                    await incrementSetDuration({
                        duration: collection.duration,
                        id: set.value,
                    });
                }
            }),
        );

        console.log(`sets ${JSON.stringify(removedSetIds)}, is being removed`);
        const removedSetCollectionIds: number[] = [];
        collection.sets.map(({ id, set: { id: set_id } }, index) => {
            if (removedSetIds.includes(set_id))
                removedSetCollectionIds.push(id);
        });
        if (removedSetCollectionIds.length > 0) {
            await deleteSetCollection({ ids: removedSetCollectionIds });
            await Promise.all(
                removedSetIds.map(async (set_id) => {
                    await incrementSetDuration({
                        duration: -1 * collection.duration,
                        id: set_id,
                    });
                }),
            );
        }
        message.success("Sets assigned successfully");
        handleCancel();
    };

    const handleCancel = () => {
        setDropdownOpen(false);
        setTimeout(() => {
            setIsModalVisible(false);
            setSelected(initialValue);
        }, 200);
    };

    return (
        <>
            <Button
                icon={<FolderAddOutlined />}
                shape="circle"
                onClick={showModal}
            />
            <Modal
                bodyStyle={{ height: 400 }}
                title="Assign Collection to Sets"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <SetsAssignComponent
                    isSet={true}
                    handleChange={handleChange}
                    selected={selected}
                    dropdownOpen={dropdownOpen}
                />
            </Modal>
        </>
    );
};

const ListView: React.FC = (props: any) => {
    const router = useRouter();
    const {
        loading,
        collections,
        collectionAggregate,
        loadCollectionsData,
        // deleteCollectionContent,
        createSetCollection,
        updateSetCollection,
        updateGroupCollection,

        deleteSetCollection,
        incrementSetDuration,
        incrementCollectionDuration,
        insertBatchCollection,
        deleteGroupCollection,
        updateChapterOther,
        updateUserOther,
    } = props;
    const [dataSource, setDataSource] = useState([]);
    const set_id = parseInt(getLastStringParamFromUrl());

    const inputFile = useRef(null);
    const [file, setFile] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const { user, isLoading } = useUserData();
    const namespace = "https://hasura.io/jwt/claims/";

    const [
        getUser,
        {
            loading: loadingUser,
            error: errorUser,
            data: { user: userdData } = { user: {} },
        },
    ] = useLazyQuery(USER_QUERY);

    useEffect(() => {
        if (!isLoading && user) {
            const user_id = user?.user_id;
            getUser({ variables: { id: user_id } });
        }
    }, [isLoading]);

    useEffect(() => {
        if (file) {
            message.loading({ content: "Processing...", key: "import" });
            let f = file;
            const reader = new FileReader();
            reader.onload = (evt: any) => {
                // evt = on_file_select event
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
                /* Update state */
                setData(Papa.parse(data, { header: true }).data); // shows data in json format
            };
            reader.readAsBinaryString(f);
        }
    }, [file]);

    useEffect(() => {
        async function createContentBatchFunc() {
            const user_id = user?.user_id;
            const collectionArray: any = [];
            const dataMod = data.filter((item: any) => {
                return item.type;
            });
            const dataGrouped = dataMod.reduce(function (r, a) {
                r[a.level_number] = r[a.level_number] || [];
                r[a.level_number].push(a);
                return r;
            }, Object.create(null));
            // console.log(dataGrouped);

            let durationIncrement = 0;
            Object.keys(dataGrouped).map((level) => {
                const contentArray: any = [];
                dataGrouped[level].map((item: any, index: number) => {
                    const { type, duration } = item;
                    durationIncrement += parseInt(duration);
                    const content: any = {
                        type,
                        duration: duration || 0,
                        index: item.question_number || index,
                        // collection_id,
                        user_id,
                    };

                    if (type.includes("question")) {
                        const t_options = [
                            item["1_option1"],
                            item["1_option2"],
                            item["1_option3"],
                            item["1_option4"],
                        ].filter((element) => {
                            return element !== "";
                        });

                        const blanks = [];
                        if (item["question_format"] == "fill_blanks") {
                            var index = 1;
                            while (index > 0) {
                                const f_correct_option =
                                    item[`${index}_correct_option`] || 0;
                                const f_options = [
                                    item[`${index}_option1`],
                                    item[`${index}_option2`],
                                    item[`${index}_option3`],
                                    item[`${index}_option4`],
                                ].filter((element) => {
                                    return element !== "";
                                });

                                if (!item[`${index + 1}_correct_option`])
                                    index = -1;
                                else index = index + 1;

                                blanks.push({
                                    correct_option: f_correct_option,
                                    options: f_options,
                                });
                            }
                            console.log("import, dumping to json"), blanks;
                        }

                        content["question"] = {
                            data: {
                                text: item["question_text"],
                                options: JSON.stringify(t_options)
                                    .replace("[", "{")
                                    .replace("]", "}"),
                                correct_option:
                                    parseInt(item["1_correct_option"]) || -1,
                                format: item["question_format"] || "",
                                other: blanks.length
                                    ? {
                                        blanks,
                                    }
                                    : {},
                            },
                        };
                    } else
                        console.log(
                            "🚀 ~ file: component.tsx ~ line 54 ~ data.map ~ item: Invalid type",
                            item,
                        );
                    contentArray.push(content);
                });
                collectionArray.push({
                    content: { data: contentArray },
                    title: dataGrouped[level][0].level_title,
                    other: { level },
                    cover: "",
                    type: "practise_learn_practise",
                    status: "active",
                    sets: { data: { set_id } },
                });
            });

            // console.log({objects: collectionArray})
            const response = await insertBatchCollection({
                objects: collectionArray,
            });

            // update collection duration
            // incrementCollectionDuration({
            //     duration: durationIncrement,
            //     id: collection_id,
            // });

            // // update set duration
            // response.returning[0].collection.sets.map(({ set_id }) => {
            //     incrementSetDuration({
            //         duration: durationIncrement,
            //         id: set_id,
            //     });
            // });

            // console.log({ response });
            message.success({
                content: "Successfully Imported!",
                key: "import",
            });

            setFile(null);
            setData(null);
        }
        if (data && data.length) {
            createContentBatchFunc();
        }
    }, [data]);

    useEffect(() => {
        if (set_id && collections.length) {
            // for topic set_id will actually be topic_id
            const url_split =
                typeof window !== "undefined" &&
                window.location.pathname.split("/");
            const filter_type = url_split && url_split[url_split.length - 2];
            if (filter_type == "topic")
                setDataSource(
                    collections
                        .map((c, index) => {
                            const sc = c.groups.filter(
                                (s) => s.group_id === set_id,
                            )[0];

                            return {
                                ..._.omit(c, "groups"),
                                index,
                                order: sc.order,
                                sc: sc.id,
                                groups_data: c.groups,
                            };
                        })
                        .sort((a, b) => {
                            if (a.status === b.status) return a.order - b.order;
                            else return a.status > b.status ? 1 : -1;
                        }),
                );
            else
                setDataSource(
                    collections
                        .map((c, index) => {
                            const sc = c.sets.filter(
                                (s) => s.set_id === set_id,
                            )[0];

                            return {
                                ..._.omit(c, "sets"),
                                index,
                                order: sc?.order || 0,
                                sc: sc?.id || set_id,
                                sets_data: c.sets,
                            };
                        })
                        .sort((a, b) => {
                            if (a.status === b.status) return a.order - b.order;
                            else return a.status > b.status ? 1 : -1;
                        }),
                );
        }
    }, [collections]);

    const sortColumn = set_id
        ? [
            {
                title: "Sort",
                dataIndex: "sort",
                width: 30,
                className: "drag-visible",
                render: () => <DragHandle />,
            },
        ]
        : [];

    const otherColumns = [
        {
            title: "Id",
            key: "id",
            className: "drag-visible",

            render: (text, record) => record?.id,
            // fixed: "left",
        },

        {
            title: "Remove from Topic",
            key: "remove",

            render: (text, record) => {
                const url_split =
                    typeof window !== "undefined" &&
                    window.location.pathname.split("/");

                const filter_type =
                    url_split && url_split[url_split.length - 2];

                const SET_COUNT_QUERY = gql`
                    query SetCountQuery($id: Int!) {
                        group_by_pk(id: $id) {
                            children {
                                collections_aggregate {
                                    aggregate {
                                        count
                                    }
                                }
                            }
                        }
                    }
                `;
                return (
                    <ApolloConsumer>
                        {(client) => (
                            <DeleteIcon
                                disabled={filter_type != "topic"}
                                onConfirm={async () => {
                                    const groupCollection =
                                        record.groups_data.filter(
                                            (g: any) => g.group_id == set_id,
                                        )[0];

                                    await deleteGroupCollection({
                                        ids: [groupCollection.id],
                                    });
                                    const chapter_id =
                                        groupCollection.group.parent_id;
                                    const response = await client.query({
                                        query: SET_COUNT_QUERY,
                                        variables: {
                                            id: chapter_id,
                                        },
                                        fetchPolicy: "network-only",
                                    });
                                    const set_count = response["data"][
                                        "group_by_pk"
                                    ]["children"].reduce(
                                        (partialSum: number, item: any) =>
                                            partialSum +
                                            item["collections_aggregate"][
                                            "aggregate"
                                            ]["count"],
                                        0,
                                    );
                                    // console.log({ set_count });
                                    await updateChapterOther({
                                        other: {
                                            set_count,
                                        },
                                        id: chapter_id,
                                    });
                                    message.success("successfully removed");
                                }}
                            />
                        )}
                    </ApolloConsumer>
                );
            },
            // fixed: "left",
        },
        {
            title: "Title",
            key: "title",
            render: (text, record) => record?.title,
            // fixed: "left",
        },

        {
            title: "Tags",
            key: "tags",
            render: (text, record) => {
                // console.log(record?.tags);
                return record?.tags?.map(({ tag: { name, value } }: any) => (
                    <Tag>{`${value} (${name})`}</Tag>
                ));
            },
        },

        {
            title: "Sets",
            key: "sets",
            render: (text, record) => {
                // console.log(record?.sets);
                return record?.sets?.map(({ set: { id } }: any) => (
                    <Tag>{id}</Tag>
                ));
            },
        },
        {
            title: "Class",
            key: "class",
            render: (text, record) => record?.class,
        },
        {
            title: "Subject",
            key: "subject",
            render: (text, record) => record?.subject,
        },
        {
            title: "Chapter",
            key: "chapter",
            render: (text, record) => record?.chapter,
        },

        {
            title: "Topic",
            key: "topic",
            render: (text, record) => record?.topic,
        },
        {
            title: "Type",
            key: "type",
            render: (text, record) => record?.type,
        },
        {
            title: "Plays",
            key: "plays",
            render: (text, record) => record?.attempts,
        },
        {
            title: "Duration",
            key: "duration",
            render: (text, record) =>
                `${(record?.duration / 60).toFixed(1)} mins`,
        },

        {
            title: "Format",
            key: "format",
            render: (text, record) => record?.format,
        },

        {
            title: "Status",
            key: "status",
            render: (text, record) => record?.status,
        },
        {
            title: "Created By",
            key: "created_by",
            render: (text, record) => record?.creator?.email,
        },
        {
            title: "Status",
            key: "status",
            render: (text, record) => record?.status,
        },
        {
            title: "Action",
            key: "action",
            align: "right",
            // fixed: "right",
            render: (text, record) => {
                const url_split =
                    typeof window !== "undefined" &&
                    window.location.pathname.split("/");
                const filter_type =
                    url_split && url_split[url_split.length - 2];
                let redirect_id = set_id;
                if (filter_type == "topic")
                    redirect_id = record?.sets[0]?.set_id || 1;

                // const menu = (
                //     <Menu
                //         items={[

                //         ]}
                //     />
                // );

                return (
                    <Space size="middle">
                        {set_id ? (
                            <>
                                <Button
                                    icon={<PlayCircleOutlined />}
                                    shape={"circle"}
                                    onClick={() => {
                                        const newWindow = window.open(
                                            `${process.env.REACT_APP_CLIENT_BASE}/gameplay?set_id=${redirect_id}&collection_id=${record.id}&play_index=0&show_memes=true&pauseTimer=true`,
                                            "_blank",
                                            "noopener,noreferrer",
                                        );
                                        if (newWindow) newWindow.opener = null;
                                    }}
                                />
                                <Divider />
                            </>
                        ) : null}

                        <Link
                            href={
                                set_id
                                    ? `/collection/content/${record?.id}?set_id=${redirect_id}`
                                    : `/collection/content/${record?.id}`
                            }
                        >
                            <ViewIcon isButton={true} />
                        </Link>
                        <Divider />
                        {!loading && !set_id && (
                            <>
                                <Can I="assign" a="collection" passThrough>
                                    <AssignSetModal
                                        collection={record}
                                        createSetCollection={
                                            createSetCollection
                                        }
                                        deleteSetCollection={
                                            deleteSetCollection
                                        }
                                        incrementSetDuration={
                                            incrementSetDuration
                                        }
                                    />
                                </Can>
                                <Divider />
                            </>
                        )}

                        <Can I="edit" a="collection" passThrough>
                            {(allowed) => (
                                <Link
                                    href={`/collection/update/${record?.id}?returnTo=${router.asPath}`}
                                >
                                    <EditIcon
                                        isButton={true}
                                        disabled={!allowed}
                                    />
                                </Link>
                            )}
                        </Can>

                        <Divider />
                        <Can I="delete" a={"collection"} passThrough>
                            {(allowed) =>
                                allowed && (
                                    <DeleteView
                                        id={record?.id}
                                        isButton={true}
                                        disabled={!allowed}
                                        // deleteCollectionContent={
                                        //     deleteCollectionContent
                                        // }
                                        incrementSetDuration={
                                            incrementSetDuration
                                        }
                                        incrementCollectionDuration={
                                            incrementCollectionDuration
                                        }
                                        record={record}
                                    />
                                )
                            }
                        </Can>

                        <Button
                            icon={<CompressOutlined />}
                            shape="circle"
                            type="dashed"
                            onClick={async () => {
                                const { other, id } = userdData;
                                const payload = {
                                    content_testing_mobile_data: {
                                        testing_type: "collection",
                                        collection_id: record?.id,
                                    },
                                };
                                await updateUserOther({
                                    id,
                                    other: other
                                        ? { ...other, ...payload }
                                        : payload,
                                });

                                message.success("successfully mirrored!");
                            }}
                        />

                        {/* <Divider />

                        <Dropdown overlay={menu} trigger={["click"]}>
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <EllipseIcon
                                        isButton={true}
                                        type={"dashed"}
                                    />
                                </Space>
                            </a>
                        </Dropdown> */}
                    </Space>
                );
            },
        },
    ];
    const columns = [...sortColumn, ...otherColumns];

    const DragHandle = SortableHandle(() => (
        <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
    ));

    const SortableItem = SortableElement((props) => <tr {...props} />);
    const SortableBody = SortableContainer((props) => <tbody {...props} />);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(
                [].concat(dataSource),
                oldIndex,
                newIndex,
            ).filter((el) => !!el);

            newData.map((d, index) => {
                if (d.order !== index + 1) {
                    console.log("Updating order: ", d.id, index + 1);
                    // for topic set_id will actually be topic_id
                    const url_split =
                        typeof window !== "undefined" &&
                        window.location.pathname.split("/");
                    const filter_type =
                        url_split && url_split[url_split.length - 2];
                    if (filter_type == "topic")
                        updateGroupCollection({ id: d.sc, order: index + 1 });
                    else updateSetCollection({ id: d.sc, order: index + 1 });

                    // newData[index].order = index;
                }
            });

            setDataSource(newData);
        }
    };

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helper="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex(
            (x) => x.index === restProps["data-row-key"],
        );
        return <SortableItem index={index} {...restProps} />;
    };
    return (
        <Layout titles={[{ name: "Collections", link: "/collection" }]}>
            <MetaTag title="Collections" />
            <Row justify="space-between" align="middle">
                <Col>
                    <h1>Collections List</h1>
                </Col>
                <Col>
                    <Can I="create" a={"collection_content"} passThrough>
                        {(allowed) => (
                            <Button
                                type="primary"
                                icon={<AppstoreAddOutlined />}
                                onClick={() => inputFile.current.click()}
                                disabled={true}
                            >
                                Import Practise Sets
                            </Button>
                        )}
                    </Can>

                    <input
                        type="file"
                        id="file"
                        ref={inputFile}
                        style={{ display: "none" }}
                        onChange={(e) => {
                            setFile(e.target.files[0]);
                        }}
                    />
                </Col>

                <Col>
                    <Can I="create" a="collection" passThrough>
                        {(allowed) => (
                            <Link
                                href={
                                    set_id
                                        ? `/collection/set/create/${set_id}`
                                        : "/collection/create"
                                }
                            >
                                <Button
                                    type="primary"
                                    icon={<AppstoreAddOutlined />}
                                    disabled={!allowed}
                                >
                                    Add
                                </Button>
                            </Link>
                        )}
                    </Can>
                </Col>
            </Row>
            <hr />

            <FilterComponent isCollection={false} {...props} />
            <hr />

            <div
                style={{
                    overflow: "scroll",
                    width: "100%",
                    background: "#FFF",
                }}
            >
                <Spin spinning={loading}>
                    {Boolean(collections?.length) &&
                        (set_id ? (
                            <Table
                                pagination={false}
                                dataSource={dataSource}
                                columns={columns}
                                rowKey="index"
                                components={{
                                    body: {
                                        wrapper: DraggableContainer,
                                        row: DraggableBodyRow,
                                    },
                                }}
                            />
                        ) : (
                            <RenderTable
                                loading={loading}
                                columns={columns}
                                data={collections || []}
                                loadData={loadCollectionsData}
                                aggregate={
                                    collectionAggregate?.aggregate?.count
                                }
                            />
                        ))}
                </Spin>
            </div>
        </Layout>
    );
};

export default ListView;
