import { useRouter } from "src/helpers"; // import lodash from 'lodash';
// import deepdash from 'deepdash-es';

import {
    withAddGroupWorksheetMap,
    withCreateWorksheet,
} from "src/modules/worksheet/operations";
import { compose } from "src/helpers";

import CreateView from "../../components/WorksheetEditor";

const Create = (props) => {
    const { createWorksheet, addGroupTabMap } = props;
    const router = useRouter();
    const { group_id, redirect_to, order, tabType } = router.query;

    const onFinish = async (values: any) => {
        const data = await createWorksheet(values);
        await addGroupTabMap({
            objects: [{ worksheet_id: data.id, group_id, order }],
        });

        const { id, type } = data;
        router.push({
            pathname:
                type === "personalized_learning_v4"
                    ? `/worksheet/update/${id}`
                    : `/worksheet/update_enhanced/${id}`,
            query: {
                redirect_to,
                group_id,
                order,
                tabType,
            },
        });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <CreateView
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            showWorksheetPopup={true}
            disableStatus={true}
        />
    );
};

export default compose(withCreateWorksheet, withAddGroupWorksheetMap)(Create);
