import React, { useMemo } from "react";
import Text from "./Text";
import InputComponent from "./Input";
import DropdownComponent from "./Dropdown";
import ImageComponent from "./Image";
import DropAreaComponent from "./DropArea";
import { generateBorderConfig } from "./helpers";

const CellComponent = ({
    cell,
    rowIndex,
    cellIndex,
    minMaxWidth,
    minHeightCustomization,
    borderCustomizations,
    paddingCustomization,
}: any) => {
    const borderStyle = useMemo(() => {
        return cell.border?.enabled
            ? generateBorderConfig(cell.border)
            : borderCustomizations
            ? generateBorderConfig(borderCustomizations)
            : {
                  border: "1px solid #828282",
              };
    }, [cell.border]);

    const currentMinMaxWidthStyle = useMemo(() => {
        if (minMaxWidth) {
            if (minMaxWidth[cellIndex]?.enabled) {
                return {
                    minWidth: minMaxWidth[cellIndex]?.minWidth,
                    maxWidth: minMaxWidth[cellIndex]?.maxWidth,
                };
            }
        }
        return {};
    }, [minMaxWidth]);

    const currentMinHeightStyle = useMemo(() => {
        if (minHeightCustomization) {
            if (minHeightCustomization[rowIndex]?.enabled) {
                return {
                    minHeight: minHeightCustomization[rowIndex]?.minHeight,
                };
            }
        }
        return {};
    }, [minHeightCustomization]);

    const { cellAlignContentStyle, cellWrapperAlignContentStyle } =
        useMemo(() => {
            if (cell.align && cell.align !== "LEFT") {
                return {
                    cellAlignContentStyle: {
                        justifyContent: cell.align.toLowerCase(),
                    },
                    cellWrapperAlignContentStyle: {
                        textAlign: cell.align.toLowerCase(),
                    },
                };
            }
            return {
                cellAlignContentStyle: {},
                cellWrapperAlignContentStyle: {},
            };
        }, [cell.align]);

    const {
        cellAlignVerticalContentStyle,
        cellWrapperAlignVerticalContentStyle,
    } = useMemo(() => {
        if (cell.alignVertical && cell.alignVertical !== "CENTER") {
            return {
                cellAlignVerticalContentStyle: {
                    alignItems: cell.alignVertical.toLowerCase(),
                },
                cellWrapperAlignVerticalContentStyle: {
                    verticalAlign: cell.alignVertical.toLowerCase(),
                },
            };
        }
        return {
            cellAlignVerticalContentStyle: {},
            cellWrapperAlignVerticalContentStyle: {},
        };
    }, [cell.alignVertical]);

    const fillColorStyle = useMemo(() => {
        if (cell.fillColor?.enabled) {
            return {
                backgroundColor:
                    cell.fillColor.value ?? cell.fillColor.default ?? "#56ccf2",
            };
        }
    }, [cell.fillColor]);

    const backgroundImageStyles = useMemo(() => {
        if (cell.imageBackground?.enabled) {
            return {
                backgroundImage: `url(${
                    cell.imageBackground.src?.value ??
                    cell.imageBackground.src?.default
                })`,
                backgroundSize: "auto 100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            };
        }
        return {};
    }, [cell.imageBackground]);
    const paddingStyles = useMemo(() => {
        if (cell.padding?.enabled) {
            if(cell.padding?.custom?.enabled){
                return {
                    paddingTop: cell.padding?.custom?.top ?? 4,
                    paddingRight: cell.padding?.custom?.right ?? 4,
                    paddingBottom: cell.padding?.custom?.bottom ?? 4,
                    paddingLeft: cell.padding?.custom?.left ?? 4,
                }
            }
            return {
                paddingTop: cell.padding?.vertical ?? 4,
                paddingRight: cell.padding?.horizontal ?? 4,
                paddingBottom: cell.padding?.vertical ?? 4,
                paddingLeft: cell.padding?.horizontal ?? 4,
            };
        }else if(paddingCustomization?.enabled){
            if(paddingCustomization?.custom?.enabled){
                return {
                    paddingTop: paddingCustomization?.custom?.top ?? 4,
                    paddingRight: paddingCustomization?.custom?.right ?? 4,
                    paddingBottom: paddingCustomization?.custom?.bottom ?? 4,
                    paddingLeft: paddingCustomization?.custom?.left ?? 4,
                }
            }
            return {
                paddingTop: paddingCustomization?.vertical ?? 4,
                paddingRight: paddingCustomization?.horizontal ?? 4,
                paddingBottom: paddingCustomization?.vertical ?? 4,
                paddingLeft: paddingCustomization?.horizontal ?? 4,
            };  
        }else{
            return {
                paddingTop: 4,
                paddingRight: 4,
                paddingBottom: 4,
                paddingLeft: 4,
            }
        }
    }, [cell.padding, paddingCustomization]);

    return (
        <td
            key={cellIndex}
            className="renderer-table-cell-wrapper px-0 py-0"
            style={{
                ...borderStyle,
                ...fillColorStyle,
                ...backgroundImageStyles,
                visibility: cell?.hidden ? "hidden" : "visible",
                ...cellWrapperAlignContentStyle,
                ...cellWrapperAlignVerticalContentStyle,
            }}
            id={
                `table-cell-${rowIndex}-${cellIndex}`
            }
        >
            <div
                className="renderer-table-cell"
                style={{
                    ...currentMinMaxWidthStyle,
                    ...cellAlignContentStyle,
                    ...cellAlignVerticalContentStyle,
                    ...currentMinHeightStyle,
                    ...paddingStyles,
                }}
            >
                {cell.text?.enabled && <Text textObj={cell.text} />}
                {cell.image?.enabled && (
                    <ImageComponent imageObj={cell.image} />
                )}
                {cell.input?.enabled && (
                    <InputComponent inputObj={cell.input} />
                )}
                {cell.dropdown?.enabled && (
                    <DropdownComponent dropdownObj={cell.dropdown} />
                )}
                {cell.dragDrop?.enabled && (
                    <DropAreaComponent
                        dragDropObj={cell.dragDrop}
                        rowIndex={rowIndex}
                        cellIndex={cellIndex}
                    />
                )}
            </div>
        </td>
    );
};

export default CellComponent;
