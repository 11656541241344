import { Button, Card, Col, Form, Input, Row, Select, Switch } from "antd";
import { cloneDeep, pullAt, set } from "lodash";
import ComponentDetails from "../common/ComponentDetails";
import { useEffect, useRef, useState } from "react";
import { AudioPoolModal } from "../../../../Figma/renderEditor/AudioPoolModal";
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { KEYMAP } from "./mapping";
import { LogicModal } from "../common/LogicModal";
import { FeedbackPool } from "../common/FeedbackPool";
import OptionsTable from "./OptionsTable";

const TapSelectEditor = (props: any) => {
    const {
        block,
        setBlock,
        disableWrite,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
    } = props;
    const {
        figma: { variables, compute_functions },
    } = block;
    const blockVarsMap: any = useRef({});

    const [load, setLoad] = useState(true);
    const [openAudioModal, setOpenAudioModal] = useState("");
    const [openFeedbackModal, setOpenFeedbackModal] = useState("");

    useEffect(() => {
        setLoad(true);
        variables?.forEach((element: { name: string | number }, i: any) => {
            blockVarsMap.current[element.name] = i;
        });
        let v = setTimeout(() => {
            setLoad(false);
        }, 50);
        return () => {
            clearTimeout(v);
        };
    }, []);

    return load ? (
        <></>
    ) : (
        <div style={{ paddingBottom: "10px", display: "flex" }}>
            {openAudioModal && openAudioModal !== "" && (
                <AudioPoolModal
                    isModalOpen={true}
                    setIsModalOpen={(value: boolean) => {
                        setOpenAudioModal(value === true ? openAudioModal : "");
                    }}
                    name={openAudioModal}
                    audioPool={
                        blockVarsMap.current[openAudioModal] >= 0 &&
                        variables[blockVarsMap.current[openAudioModal]].value
                    }
                    disableWrite={disableWrite}
                    schema={{
                        x: "",
                        audio: "",
                    }}
                    onSave={(value: any) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            [
                                "figma",
                                "variables",
                                blockVarsMap.current[openAudioModal],
                                "value",
                            ],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                />
            )}

            {openFeedbackModal && openFeedbackModal !== "" && (
                <FeedbackPool
                    isModalOpen={true}
                    setIsModalOpen={(value: boolean) => {
                        setOpenFeedbackModal(
                            value === true ? openFeedbackModal : "",
                        );
                    }}
                    name={openFeedbackModal}
                    feedbackPool={
                        blockVarsMap.current[openFeedbackModal] >= 0 &&
                        variables[blockVarsMap.current[openFeedbackModal]].value
                    }
                    disableWrite={disableWrite}
                    onSave={(value: any) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            [
                                "figma",
                                "variables",
                                blockVarsMap.current[openFeedbackModal],
                                "value",
                            ],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                    currentEditor={currentEditor}
                    setCurrentEditor={setCurrentEditor}
                />
            )}
            <Card
                style={{
                    minWidth: "600px",
                    // maxWidth: "600px",
                    width: "100%",
                    marginLeft: "8px",
                    flexShrink: 0,
                    height: "85vh",
                    overflow: "auto",
                    scrollbarWidth: "none",
                }}
            >
                <Row>
                    <Col span={12}>
                        <ComponentDetails
                            block={block}
                            setBlock={setBlock}
                            disableWrite={disableWrite}
                        />
                    </Col>
                    <Col span={12}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                gap: "4px",
                            }}
                        >
                            <LogicModal
                                disableWrite={disableWrite}
                                computeFUnctions={compute_functions}
                                onSave={(val) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["figma", "compute_functions"],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                            <Select
                                value={
                                    blockVarsMap.current[KEYMAP.check_type] >=
                                    0 &&
                                    variables[
                                        blockVarsMap.current[KEYMAP.check_type]
                                    ]?.value
                                }
                                placeholder="Select type"
                                options={[
                                    {
                                        label: "One by one",
                                        value: "ONE_BY_ONE",
                                    },
                                    {
                                        label: "One by one till first incorrect",
                                        value: "ONE_BY_ONE_TILL_FIRST_INCORRECT",
                                    },
                                    {
                                        label: "All in one go",
                                        value: "ALL_IN_ONE_GO",
                                    },
                                ]}
                                style={{
                                    width: "150px",
                                    flexGrow: 0,
                                }}
                                onChange={(val) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                            KEYMAP.check_type
                                            ],
                                            "value",
                                        ],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                disabled={disableWrite}
                            />
                        </div>
                        <Form.Item
                            label="Feedback Type"
                            style={{
                                marginTop: "20px",
                            }}
                        >
                            <Select
                                value={
                                    blockVarsMap.current[
                                    KEYMAP.feedback_type
                                    ] >= 0 &&
                                    variables[
                                        blockVarsMap.current[
                                        KEYMAP.feedback_type
                                        ]
                                    ].value
                                }
                                placeholder="Select type"
                                options={[
                                    {
                                        label: "No Feedback",
                                        value: "NO_FEEDBACK",
                                    },
                                    {
                                        label: "Manual Audio",
                                        value: "MANUAL_AUDIO",
                                    },
                                    {
                                        label: "Manual Story",
                                        value: "MANUAL_STORY",
                                    },
                                    {
                                        label: "Generated Audio",
                                        value: "GENERATED_AUDIO",
                                    },
                                    {
                                        label: "Generated Story",
                                        value: "GENERATED_STORY",
                                    },
                                ]}
                                style={{
                                    flexGrow: 0,
                                }}
                                onChange={(val) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                            KEYMAP.feedback_type
                                            ],
                                            "value",
                                        ],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                disabled={disableWrite}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Evaluation Type"
                            style={{
                                marginTop: "20px",
                            }}
                        >
                            <Select
                                value={
                                    variables[
                                        blockVarsMap.current[
                                        KEYMAP.evaluation_type
                                        ]
                                    ]?.value
                                }
                                placeholder="Select type"
                                options={[
                                    {
                                        label: "Function",
                                        value: "FUNCTION",
                                    },
                                    {
                                        label: "Groq",
                                        value: "GROQ",
                                    },
                                ]}
                                style={{
                                    flexGrow: 0,
                                }}
                                onChange={(val) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                            KEYMAP.evaluation_type
                                            ],
                                            "value",
                                        ],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                disabled={disableWrite}
                            />
                        </Form.Item>
                        {variables[blockVarsMap.current[KEYMAP.evaluation_type]]
                            ?.value === "GROQ" && (
                                <>
                                    <Form.Item
                                        label="System Prompt"
                                        style={{
                                            marginTop: "20px",
                                        }}
                                        validateStatus={
                                            !variables[
                                                blockVarsMap.current[
                                                KEYMAP.system_prompt
                                                ]
                                            ]?.value
                                                ? "error"
                                                : ""
                                        }
                                        help={
                                            !variables[
                                                blockVarsMap.current[
                                                KEYMAP.system_prompt
                                                ]
                                            ]?.value
                                                ? "Field cannot be empty"
                                                : ""
                                        }
                                    >
                                        <Input.TextArea
                                            disabled={disableWrite}
                                            // style={{ width: 78 }}
                                            value={
                                                variables[
                                                    blockVarsMap.current[
                                                    KEYMAP.system_prompt
                                                    ]
                                                ]?.value
                                            }
                                            onChange={(val) => {
                                                console.log(
                                                    "sys onchange val",
                                                    val.target.value,
                                                );
                                                let tmpBlock = cloneDeep(block);
                                                tmpBlock = set(
                                                    tmpBlock,
                                                    [
                                                        "figma",
                                                        "variables",
                                                        blockVarsMap.current[
                                                        KEYMAP.system_prompt
                                                        ],
                                                        "value",
                                                    ],
                                                    val.target.value,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Question text"
                                        style={{
                                            marginTop: "20px",
                                        }}
                                    >
                                        <Input.TextArea
                                            disabled={disableWrite}
                                            // style={{ width: 78 }}
                                            value={
                                                variables[
                                                    blockVarsMap.current[
                                                    KEYMAP.question_text
                                                    ]
                                                ]?.value
                                            }
                                            onChange={(val) => {
                                                let tmpBlock = cloneDeep(block);
                                                tmpBlock = set(
                                                    tmpBlock,
                                                    [
                                                        "figma",
                                                        "variables",
                                                        blockVarsMap.current[
                                                        KEYMAP.question_text
                                                        ],
                                                        "value",
                                                    ],
                                                    val.target.value,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="get Prompt"
                                        style={{
                                            marginTop: "20px",
                                        }}
                                    >
                                        <Input.TextArea
                                            disabled={disableWrite}
                                            // style={{ width: 78 }}
                                            value={
                                                variables[
                                                    blockVarsMap.current[
                                                    KEYMAP.get_prompt
                                                    ]
                                                ]?.value
                                            }
                                            onChange={(val) => {
                                                let tmpBlock = cloneDeep(block);
                                                tmpBlock = set(
                                                    tmpBlock,
                                                    [
                                                        "figma",
                                                        "variables",
                                                        blockVarsMap.current[
                                                        KEYMAP.get_prompt
                                                        ],
                                                        "value",
                                                    ],
                                                    val.target.value,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="update correct"
                                        style={{
                                            marginTop: "20px",
                                        }}
                                    >
                                        <Input.TextArea
                                            disabled={disableWrite}
                                            // style={{ width: 78 }}
                                            value={
                                                variables[
                                                    blockVarsMap.current[
                                                    KEYMAP.update_correct
                                                    ]
                                                ]?.value
                                            }
                                            onChange={(val) => {
                                                let tmpBlock = cloneDeep(block);
                                                tmpBlock = set(
                                                    tmpBlock,
                                                    [
                                                        "figma",
                                                        "variables",
                                                        blockVarsMap.current[
                                                        KEYMAP.update_correct
                                                        ],
                                                        "value",
                                                    ],
                                                    val.target.value,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </Form.Item>
                                </>
                            )}
                        <div
                            style={{
                                display: "flex",
                                gap: "4px",
                                flexWrap: "wrap",
                            }}
                        >
                            <Form.Item label="Has Highlight?">
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={
                                        blockVarsMap.current[
                                        KEYMAP.has_highlight
                                        ] >= 0 &&
                                        ["true", "1", true, 1].includes(
                                            variables[
                                                blockVarsMap.current[
                                                KEYMAP.has_highlight
                                                ]
                                            ].value,
                                        )
                                    }
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "figma",
                                                "variables",
                                                blockVarsMap.current[
                                                KEYMAP.has_highlight
                                                ],
                                                "value",
                                            ],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Has Feedback Stroke?">
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={
                                        blockVarsMap.current[
                                        KEYMAP.has_correctness_stroke
                                        ] >= 0 &&
                                        ["true", "1", true, 1].includes(
                                            variables[
                                                blockVarsMap.current[
                                                KEYMAP
                                                    .has_correctness_stroke
                                                ]
                                            ].value,
                                        )
                                    }
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "figma",
                                                "variables",
                                                blockVarsMap.current[
                                                KEYMAP
                                                    .has_correctness_stroke
                                                ],
                                                "value",
                                            ],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                gap: 20,
                            }}
                        >
                            <Form.Item label="Audio Pool">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setOpenAudioModal(KEYMAP.start_audio);
                                    }}
                                    size="small"
                                >
                                    Add
                                </Button>
                            </Form.Item>

                            {blockVarsMap.current[KEYMAP.start_feedback] >=
                                0 && (
                                    <Form.Item label="Feedback Pool">
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                setOpenFeedbackModal(
                                                    KEYMAP.start_feedback,
                                                );
                                            }}
                                            size="small"
                                        >
                                            Add
                                        </Button>
                                    </Form.Item>
                                )}
                        </div>
                    </Col>
                    <Col span={18}>
                        {blockVarsMap.current[KEYMAP.tappable_function] >=
                            0 && (
                                <Form.Item
                                    label="Tappable Function"
                                    style={{
                                        padding: "20px",
                                    }}
                                >
                                    <Input.TextArea
                                        disabled={disableWrite}
                                        value={
                                            variables[
                                                blockVarsMap.current[
                                                KEYMAP.tappable_function
                                                ]
                                            ]?.value
                                        }
                                        onChange={(e) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "figma",
                                                    "variables",
                                                    blockVarsMap.current[
                                                    KEYMAP.tappable_function
                                                    ],
                                                    "value",
                                                ],
                                                e.target.value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                            )}
                        {!load && (
                            <OptionsTable
                                setBlock={(v) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                            KEYMAP.options
                                            ],
                                            "value",
                                        ],
                                        v,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                disableWrite={disableWrite}
                                currentEditor={currentEditor}
                                setCurrentEditor={setCurrentEditor}
                                hasMentions={hasMentions}
                                mentionsList={mentionsList}
                                renderLinkSelect={props.renderLinkSelect}
                                {...{
                                    ...variables[
                                    blockVarsMap.current[KEYMAP.options]
                                    ],
                                }}
                            />
                        )}
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Layout"
                            style={{
                                marginTop: "20px",
                            }}
                            validateStatus={
                                !variables[blockVarsMap.current[KEYMAP.layout]]
                                    ?.value
                                    ? "error"
                                    : ""
                            }
                            help={
                                !variables[blockVarsMap.current[KEYMAP.layout]]
                                    ?.value
                                    ? "Field cannot be empty or zero"
                                    : ""
                            }
                        >
                            <Select
                                value={
                                    blockVarsMap.current[KEYMAP.layout] >= 0 &&
                                    variables[
                                        blockVarsMap.current[KEYMAP.layout]
                                    ].value
                                }
                                placeholder="Select type"
                                options={
                                    typeof variables[
                                        blockVarsMap.current[KEYMAP.layout]
                                    ]?.options === "string"
                                        ? JSON.parse(
                                            variables[
                                                blockVarsMap.current[
                                                KEYMAP.layout
                                                ]
                                            ]?.options,
                                        )
                                        : variables[
                                            blockVarsMap.current[
                                            KEYMAP.layout
                                            ]
                                        ]?.options
                                }
                                style={{
                                    flexGrow: 0,
                                }}
                                onChange={(val) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[KEYMAP.layout],
                                            "value",
                                        ],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                disabled={disableWrite}
                            />
                        </Form.Item>
                        <Form.Item label="Multi select?">
                            <Switch
                                disabled={disableWrite}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={
                                    blockVarsMap.current[
                                    KEYMAP.is_multiple_select
                                    ] >= 0 &&
                                    ["true", "1", true, 1].includes(
                                        variables[
                                            blockVarsMap.current[
                                            KEYMAP.is_multiple_select
                                            ]
                                        ].value,
                                    )
                                }
                                onChange={(val) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                            KEYMAP.is_multiple_select
                                            ],
                                            "value",
                                        ],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label={"Min Select"}>
                            <Input
                                disabled={disableWrite}
                                type="number"
                                placeholder="Min select"
                                value={
                                    blockVarsMap.current[KEYMAP.min_select] >=
                                    0 &&
                                    variables[
                                        blockVarsMap.current[KEYMAP.min_select]
                                    ].value
                                }
                                onChange={(e) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                            KEYMAP.min_select
                                            ],
                                            "value",
                                        ],
                                        e.target.value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label={"Max Select"}>
                            <Input
                                disabled={disableWrite}
                                type="number"
                                placeholder="Max select"
                                value={
                                    blockVarsMap.current[KEYMAP.max_select] >=
                                    0 &&
                                    variables[
                                        blockVarsMap.current[KEYMAP.max_select]
                                    ].value
                                }
                                onChange={(e) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                            KEYMAP.max_select
                                            ],
                                            "value",
                                        ],
                                        e.target.value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                        {blockVarsMap.current[KEYMAP.correct_on_any_attempt] >=
                            0 && (
                                <Form.Item label="All correct?">
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={
                                            blockVarsMap.current[
                                            KEYMAP.correct_on_any_attempt
                                            ] >= 0 &&
                                            ["true", "1", true, 1].includes(
                                                variables[
                                                    blockVarsMap.current[
                                                    KEYMAP
                                                        .correct_on_any_attempt
                                                    ]
                                                ].value,
                                            )
                                        }
                                        onChange={(val) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "figma",
                                                    "variables",
                                                    blockVarsMap.current[
                                                    KEYMAP
                                                        .correct_on_any_attempt
                                                    ],
                                                    "value",
                                                ],
                                                val,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                            )}
                        <Form.Item label={"Correct Answers"}>
                            <Button
                                ghost
                                type="primary"
                                size="small"
                                style={{
                                    marginBottom: "10px",
                                }}
                                disabled={disableWrite}
                                onClick={() => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                            KEYMAP.correct_answer
                                            ],
                                            "value",
                                            variables[
                                                blockVarsMap.current[
                                                KEYMAP.correct_answer
                                                ]
                                            ]?.value?.length,
                                        ],
                                        "",
                                    );
                                    setBlock(tmpBlock);
                                }}
                            >
                                Add New Correct Answer
                            </Button>
                            {variables[
                                blockVarsMap.current[KEYMAP.correct_answer]
                            ]?.value?.map((v: any, key: number) => (
                                <Input
                                    key={key}
                                    addonAfter={
                                        <Button
                                            disabled={disableWrite}
                                            icon={<DeleteOutlined />}
                                            type="primary"
                                            shape="circle"
                                            danger
                                            size="small"
                                            onClick={() => {
                                                let tempVal = cloneDeep(
                                                    variables[
                                                        blockVarsMap.current[
                                                        KEYMAP
                                                            .correct_answer
                                                        ]
                                                    ]?.value,
                                                );
                                                pullAt(tempVal, key);
                                                let tmpBlock = cloneDeep(block);
                                                tmpBlock = set(
                                                    tmpBlock,
                                                    [
                                                        "figma",
                                                        "variables",
                                                        blockVarsMap.current[
                                                        KEYMAP
                                                            .correct_answer
                                                        ],
                                                        "value",
                                                    ],
                                                    tempVal,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    }
                                    disabled={disableWrite}
                                    value={v}
                                    placeholder="Correct Answer"
                                    onChange={(e) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "figma",
                                                "variables",
                                                blockVarsMap.current[
                                                KEYMAP.correct_answer
                                                ],
                                                "value",
                                                key,
                                            ],
                                            e.target.value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            ))}
                        </Form.Item>
                        <Form.Item
                            label={"Toast Message"}
                            validateStatus={
                                !variables[
                                    blockVarsMap.current[KEYMAP.toast_message]
                                ]?.value
                                    ? "error"
                                    : ""
                            }
                            help={
                                !variables[
                                    blockVarsMap.current[KEYMAP.toast_message]
                                ]?.value
                                    ? "Field cannot be empty or zero"
                                    : ""
                            }
                        >
                            <Input
                                disabled={disableWrite}
                                placeholder="Max select"
                                value={
                                    variables[
                                        blockVarsMap.current[
                                        KEYMAP.toast_message
                                        ]
                                    ]?.value
                                }
                                onChange={(e) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                            KEYMAP.toast_message
                                            ],
                                            "value",
                                        ],
                                        e.target.value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default TapSelectEditor;
