import { useUserData } from "src/helpers/supertokens";

import {
    Field,
    RenderDynamicField,
    RenderField,
    RenderSelect,
} from "src/components";
import FormikPersist from "src/components/FormikPersist";
import { TagsAssignComponent } from "src/modules/book/components";
import { Button, Col, Form, Row, Select } from "antd";
import { FieldArray, withFormik } from "formik";
import { useRouter } from "src/helpers";
import React from "react";
import * as yup from "yup";

const Option = Select.Option;

const subjects = [
    "Maths",
    "Science",
    "Physics",
    "Biology",
    "Chemistry",
    "Environmental Studies",
    "English",
    "Social Studies",
    "Social Science",
    "Hindi",
];

const classNames = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
];

const medium = ["english", "hindi"];

const board = ["cbse", "state"];

const BookForm = (props) => {
    const {
        onFinishFailed,
        values,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        disableSubjectClass = false,
    } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values.tags);
    const router = useRouter();
    const {
        query: { tabType },
    } = router;

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={24} md={24}>
                    <Field
                        label="Book Title"
                        placeholder="Book Title"
                        name={"title"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.title}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Book Description"
                        placeholder="Book Description"
                        name={"description"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.description}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Book Cover"
                        placeholder="Book Cover"
                        name={"cover"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.cover}
                        rules={[{ required: true }]}
                        path="book/"
                    />
                </Col>

                {/* <Col lg={24} md={24}>
                    <Field
                        label="Book Banner"
                        placeholder="Book Banner"
                        name={"other.banner"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.other.banner}
                        rules={[{ required: true }]}
                        path="book/"
                    />
                </Col> */}

                <Col lg={24} md={24}>
                    <Field
                        label="Book Subject"
                        placeholder="Book Subject"
                        name={"other.subject"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.other.subject}
                        rules={[{ required: true }]}
                        disabled={disableSubjectClass}
                    >
                        {subjects.map((subject) => (
                            <Option value={subject}>{subject}</Option>
                        ))}
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Book Class"
                        placeholder="Book Class"
                        name={"other.class"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.other.class}
                        rules={[{ required: true }]}
                        disabled={disableSubjectClass}
                    >
                        {classNames.map((className) => (
                            <Option value={className}>{className}</Option>
                        ))}
                    </Field>
                </Col>

                {tabType == "cta" && (
                    <Col lg={24} md={24}>
                        <Field
                            label="Book Remote Id"
                            placeholder="Book Remote Id"
                            name={"other.remote_id"}
                            icon="FontSizeOutlined"
                            component={RenderField}
                            type="textarea"
                            value={values.other.remote_id}
                            rules={[{ required: true }]}
                        />
                    </Col>
                )}

                {tabType == "cta" && (
                    <Col lg={24} md={24}>
                        <Field
                            label="Book View Link Base URL"
                            placeholder="Book View Link Base URL"
                            name={"other.view_link_base"}
                            icon="FontSizeOutlined"
                            component={RenderField}
                            type="textarea"
                            value={values.other.view_link_base}
                            rules={[{ required: true }]}
                        />
                    </Col>
                )}

                {/* <Col lg={24} md={24}>
                    <Field
                        label="Book Board"
                        placeholder="Book Board"
                        name={"other.board"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.other.board}
                        rules={[{ required: true }]}
                    >
                        {board.map((className) => (
                            <Option value={className}>{className}</Option>
                        ))}
                    </Field>
                </Col> */}
                {/* 
                <Col lg={24} md={24}>
                    <Field
                        label="Book Medium"
                        placeholder="Book Medium"
                        name={"other.medium"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.other.medium}
                        rules={[{ required: true }]}
                    >
                        {medium.map((className) => (
                            <Option value={className}>{className}</Option>
                        ))}
                    </Field>
                </Col> */}

                {/* <Col lg={24} md={24}>
                    <Field
                        label="Book Rating"
                        placeholder="Book Rating"
                        name={"other.rating"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.other.rating}
                        rules={[{ required: true }]}
                    />
                </Col> */}

                {/* <Col lg={24} md={24}>
                    <Field
                        label="Book Attempts"
                        placeholder="Book Attempts"
                        name={"other.attempts"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.other.attempts}
                        rules={[{ required: true }]}
                        disabled={true}
                    />
                </Col> */}

                {/* <Col lg={24} md={24}>
                    <FieldArray
                        name={"other.details"}
                        render={(arrayHelpers) => (
                            <RenderDynamicField
                                buttonText="Add Details"
                                keys={[
                                    {
                                        key: "title",
                                        type: "text",
                                        label: "Title",
                                    },
                                    {
                                        key: "description",
                                        type: "textarea",
                                        label: "Description",
                                    },
                                ]}
                                arrayHelpers={arrayHelpers}
                                values={values.other.details}
                                name={"other.details"}
                            />
                        )}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <FieldArray
                        name={"other.ratings"}
                        render={(arrayHelpers) => (
                            <RenderDynamicField
                                buttonText="Add Ratings"
                                keys={[
                                    {
                                        key: "user_name",
                                        type: "text",
                                        label: "User Name",
                                    },
                                    {
                                        key: "user_description",
                                        type: "text",
                                        label: "User Description",
                                    },
                                    {
                                        key: "rating",
                                        type: "number",
                                        label: "Rating",
                                    },
                                    {
                                        key: "comment",
                                        type: "textarea",
                                        label: "Comment",
                                    },
                                    {
                                        key: "avatar",
                                        type: "text",
                                        label: "Avatar",
                                    },
                                ]}
                                arrayHelpers={arrayHelpers}
                                values={values.other.ratings}
                                name={"other.ratings"}
                            />
                        )}
                    />
                </Col> */}

                <Col lg={24} md={24}>
                    <Field
                        label="Book Pitch"
                        placeholder="Book Pitch"
                        name={"other.pitch"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.other.pitch}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <FieldArray
                        name={"other.banners"}
                        render={(arrayHelpers) => (
                            <RenderDynamicField
                                buttonText="Add Banner"
                                keys={[
                                    {
                                        key: "cta_type",
                                        type: "select",
                                        label: "cta_type",
                                        options: ["external", "internal"],
                                    },
                                    {
                                        key: "cta_action",
                                        type: "text",
                                        label: "cta_action",
                                    },
                                    {
                                        key: "banner",
                                        type: "text",
                                        label: "banner",
                                    },
                                ]}
                                arrayHelpers={arrayHelpers}
                                values={values.other.banners}
                                name={"other.banners"}
                            />
                        )}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Book Status"
                        placeholder="Book Status"
                        name={"status"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.status}
                        rules={[{ required: true }]}
                    >
                        <Option value="inactive">Inactive</Option>
                        <Option value="active_1">Active</Option>
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <TagsAssignComponent
                        name="tags"
                        formik={props}
                        isSet={true}
                        handleChange={(data) => {
                            setFieldValue(
                                ["tags", "data"],
                                data.map((d) => ({
                                    tag_id: d.value,
                                    label: d.label,
                                })),
                            );
                        }}
                        selected={values?.tags?.data?.map((tag, index) => ({
                            value: tag.tag_id,
                            key: index,
                            label: tag.label,
                        }))}
                    />
                    <br />
                </Col>

                <Col lg={12} md={24}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isSubmitting}
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export const FormSchema = yup.object().shape({
    title: yup.string().required("This field is required"),
    description: yup.string().required("This field is required"),
    cover: yup.string().required("This field is required"),
    type: yup.string().required("This field is required"),
    status: yup.string().required("This field is required"),
    other: yup.object().shape({
        subject: yup.string().required("This field is required"),
        class: yup.string().required("This field is required"),
        pitch: yup.string().optional(),
        banners: yup
            .array()
            .of(
                yup.object().shape({
                    cta_type: yup.string().required("This field is required"),
                    cta_action: yup.string().required("This field is required"),
                    banner: yup.string().required("This field is required"),
                }),
            )
            .required(),
    }),
});

const BookFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { book } = props;
        return {
            ...(book?.id ? { id: book.id } : {}),
            title: book?.title || "",
            description: book?.description || "",
            cover: book?.cover || "",

            // other
            other: {
                subject: book?.other?.subject || "",
                class: book?.other?.class || "",
                board: book?.other?.board || "",
                medium: book?.other?.medium || "",

                banner: book?.other?.banner || "",

                rating: book?.other?.rating || 0,
                attempts: book?.other?.attempts || 0,

                details: book?.other?.details || [
                    {
                        title: "About this Book",
                        description: "Example Description",
                    },
                ],
                ratings: book?.other?.ratings || [
                    {
                        rating: 4,
                        comment: "Very good book.",
                        user_name: "John Cena",
                        user_description: "Maths Teacher, DPS",
                        avatar: "paste avatar link here",
                    },
                ],
                remote_id: book?.other?.remote_id || "",
                view_link_base: book?.other?.view_link_base || "",

                pitch: book?.other?.pitch || "",
                banners: book?.other?.banners || [],
            },

            status: book?.status || "inactive",
            type: "book",
            tags: {
                data:
                    book?.tags?.map((tag) => ({
                        tag_id: tag.tag.id,
                        // value: tag.tag.value, name: tag.tag.name
                        label: `${tag.tag.value} (${tag.tag.name})` || "",
                    })) || [],
            },
            // created_by: props.user_id,
        };
    },
    validationSchema: FormSchema,

    async handleSubmit(values, { props: { onFinish, book } }: any) {
        const isStudentPractise = values?.tags?.data?.filter(
            ({ label }) => label == "daily_practise (stream)",
        );

        await onFinish({
            ...values,
            tags: {
                data: values.tags.data.map(({ tag_id }: any) => ({ tag_id })),
            },
            // status:
            //     (values.status || book.status) == "active" ||
            //     (values.status || book.status) == "active_1"
            //         ? isStudentPractise && isStudentPractise.length > 0
            //             ? "active_1"
            //             : "active"
            //         : values.status,
        });
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

const FormikForm = BookFormWithFormik(BookForm);
const FormikFormWithUser = (props) => {
    const { user } = useUserData();

    const namespace = "https://hasura.io/jwt/claims/";
    const user_id = user?.user_id;
    return <FormikForm {...props} user_id={user_id} />;
};

export default FormikFormWithUser;
